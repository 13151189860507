import { TIME_UNIT_CONVERT_FACTOR } from 'constants/general';
import { ActivityType } from 'interfaces/commonInterface';
import moment from 'moment';

const RoutineSuggestion = ({
  suggestion
}: {
  readonly suggestion: ActivityType;
}) => (
  <div className='w-full flex items-center gap-1.5 px-2 py-1 rounded-md shadow-md relative mb-6'>
    <p className='w-fit max-w-[70%] sm:max-w-[80%] text-xs sm:text-sm md:text-base truncate capitalize'>
      {suggestion.name}
    </p>
    <p className='text-[0.60rem] sm:text-xs bg-gray-600 text-white py-0 sm:py-0.5 px-1.5 rounded-md'>
      {moment()
        .startOf('date')
        .add(suggestion.duration_seconds, 'seconds')
        .format(
          suggestion.duration_seconds >=
            TIME_UNIT_CONVERT_FACTOR.ONE_HOUR_AS_SECONDS
            ? 'h[h]:mm[m]:ss[s]'
            : 'mm[m]:ss[s]'
        )}
    </p>
  </div>
);

export default RoutineSuggestion;
