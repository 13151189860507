import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function ContentWrapper({ children }: Props) {
  return (
    <div className='w-full h-fit flex flex-col justify-around items-center gap-2 sm:gap-4 md:gap-6 lg:gap-14 text-center text-xl border-[3px] border-orange-300 bg-white rounded-lg py-4 sm:py-7 md:py-10 px-2 sm:px-4 md:px-8 shadow-xl'>
      {children}
    </div>
  );
}
