import { useState, Dispatch, SetStateAction } from 'react';
import { DropDownSelectedValue } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ROUTES } from 'constants/routes';
import { updateSelectedTaskIDs } from 'store/reducer/to-do/slice';
import {
  EISENHOWER_QUADRANT,
  EMPTY_STRING,
  TAB_INDEX
} from 'constants/general';
import Dropdown from 'assets/icons/Dropdown';
import DropDown from 'components/common/DropDown';
import { updateToDoStatus } from 'store/reducer/to-do/extra';
import moment from 'moment';
import { getToDoDefaultStatusLabel } from 'utils/support';
import { ToDoTask } from 'interfaces/commonInterface';
import { DEFAULT_TO_DO_TASK_STATUS } from 'assets/data';
import { taskItemHeaderSelector } from 'store/reducer/to-do/selectors';
import classNames from 'classnames';
import TaskProjects from './TaskProjects';
import { TO_DO_STATUS } from 'constants/enum';

interface Props {
  task: ToDoTask;
  toggleDropdown: boolean;
  setToggleDropdown: Dispatch<SetStateAction<boolean>>;
}

const TaskItemHeader = ({ task, toggleDropdown, setToggleDropdown }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isUpdatingStatus, selectedTaskIDs } = useAppSelector(
    taskItemHeaderSelector
  );
  const [status, setStatus] = useState({
    label: getToDoDefaultStatusLabel(task.status),
    value: task.status
  });

  const toDoStatusOptions = task.external_statuses?.length
    ? task.external_statuses?.map((status) => ({
        label: status.label,
        value: status.status_id
      }))
    : DEFAULT_TO_DO_TASK_STATUS;

  const isLoading = selectedTaskIDs.includes(task.id) && isUpdatingStatus;
  const shouldEmbedTodoPlayer = window.location.pathname.includes(
    ROUTES.WEBVIEW_TO_DO_PLAYER
  );

  return (
    <div
      tabIndex={TAB_INDEX.TASK_COLLAPSE}
      className={classNames(
        'w-full cursor-pointer text-sm md:text-base flex items-center justify-between relative pl-7 gap-4 rounded-md',
        {
          'bg-red-200':
            String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DO,
          'bg-orange-200':
            String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DECIDE,
          'bg-green-200':
            String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELEGATE,
          'bg-blue-200':
            String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELETE
        }
      )}
    >
      <button onClick={() => setToggleDropdown((prev) => !prev)}>
        <Dropdown
          styles={`w-5 h-5 absolute bottom-[20%] -translate-y-[-20%] left-1 ${
            toggleDropdown ? 'rotate-0' : '-rotate-90'
          } transition-[height] ease-in duration-200`}
        />
      </button>
      <div className='w-full flex flex-col gap-2 overflow-hidden'>
        <div className='w-full flex items-start justify-between flex-wrap p-2 rounded-md shadow text-[0.6rem]'>
          {task.due_date && (
            <p className='pr-2 text-xs'>
              {`${t('to_do_procrastinate.due_date')} ${moment(
                task.due_date
              ).format('MMM DD, YYYY')}`}
            </p>
          )}
          <p className='bg-gray-600 text-white rounded-md shadow-md px-2 text-center leading-5 tracking-wider'>
            {`${t('priority')} ${task.eisenhower_quadrant}`}
          </p>
          {task.tags?.length ? <TaskProjects task={task} /> : null}
        </div>
        <div className='w-full flex items-center justify-between gap-1'>
          <p className='line-clamp-1'>{task.title}</p>
          {!shouldEmbedTodoPlayer &&
            window.location.pathname !== ROUTES.WEBVIEW_TODO_LIST && (
              <DropDown
                title={EMPTY_STRING}
                value={status ?? undefined}
                options={toDoStatusOptions}
                handleChange={(data) => {
                  const result = data as DropDownSelectedValue;
                  dispatch(
                    updateSelectedTaskIDs([...selectedTaskIDs, task.id])
                  );
                  data &&
                    setStatus(() => {
                      dispatch(
                        updateToDoStatus({
                          todoTask: {
                            ...task,
                            status: result.value
                          }
                        })
                      );
                      return result;
                    });
                }}
                containerStyles='min-w-max flex flex-col text-xs self-start gap-1.5'
                isClearable={false}
                isDisabled={task.status === TO_DO_STATUS.COMPLETED || isLoading}
                isLoading={isLoading}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default TaskItemHeader;
