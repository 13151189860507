import Cover from 'assets/images/bear.png';
import classNames from 'classnames';
import { CourseLesson } from 'interfaces';
import { useContext } from 'react';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { createHtml, getYoutubeURLId } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import { CourseLessonsModalContext } from '.';

const CourseLessonItem = ({ lesson }: { lesson: CourseLesson }) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  const {
    lessonInfo: { currentLesson }
  } = useContext(CourseLessonsModalContext);
  const getTransformValues = `translateX(-${currentLesson * 100}%)`;

  return (
    <div
      key={lesson.id}
      style={{ transform: getTransformValues }}
      className={classNames(
        'min-w-full w-full max-h-[94%] flex flex-col lg:flex-row transition ease-out duration-300',
        {
          'bg-gray-100': !shouldActivateDarkMode,
          'bg-gray-600': shouldActivateDarkMode
        }
      )}
    >
      {lesson?.url ? (
        <div className='w-full lg:w-1/2 min-h-[50vh] lg:min-h-[35vh] flex flex-col'>
          <iframe
            width='100%'
            height='100%'
            src={`https://www.youtube.com/embed/${getYoutubeURLId(lesson.url)}?rel=0`}
            allow='autoplay; encrypted-media;'
            allowFullScreen
            title={lesson.title}
          ></iframe>
        </div>
      ) : (
        <img
          src={Cover}
          className='w-full lg:w-1/2 object-cover bg-orange-200 rounded-l-md px-4'
          alt={lesson.title}
        />
      )}
      <div className='w-full lg:w-1/2 h-full flex flex-col items-center justify-center px-4 pt-4 lg:pt-0 gap-0 xs:gap-1 sm:gap-2'>
        <p className='w-full h-fit flex items-center justify-center font-semibold text-sm sm:text-base md:text-xl lg:text-2xl px-0 md:px-2 text-center'>
          {lesson.title}
        </p>
        <div className='w-full h-1/2 lg:h-fit lg:max-h-full overflow-y-auto bg-transparent scrollbar-thin scrollbar-thumb-gray-500 px-4 py-2 text-xs sm:text-sm md:text-base'>
          {createHtml(lesson.content)}
        </div>
      </div>
    </div>
  );
};

export default CourseLessonItem;
