import React from 'react';

interface Props {
  children: React.ReactNode;
}

const DetailsPageActionsWrapper = ({ children }: Props) => (
  <div className='w-full md:w-[20%] lg:w-[25%] flex flex-col justify-center items-center h-fit py-6 md:py-14 gap-y-4'>
    {children}
  </div>
);

export default DetailsPageActionsWrapper;
