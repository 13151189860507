import { ColorHex } from 'react-countdown-circle-timer';
import {
  CalendarTypeLabels,
  CalendarTypes,
  DaysOfWeek,
  LanguageCodes,
  Languages
} from 'constants/enum';
import i18n from 'services/i18n';
import COLOR from './color';
import { DropDownSelectedValue } from 'interfaces';
import { t } from 'i18next';

export const KEY_BOARD = {
  ENTER: {
    CODE: 13,
    KEY: 'Enter'
  }
};

export const SUBSCRIPTION_PLAN = {
  PERSONAL_MONTHLY: 'prod_MMKwNLdDS8sQzQ',
  TEAM: 'prod_MML2RSdXeHcZWh',
  ORGANIZATION: 'prod_PoVHmwhBleUISJ',
  PERSONAL_ANNUAL: 'prod_Q4CMIjrpNLnP2c',
  TRAIL: 'trial',
  RC_PROMO_TEAM_ADMIN_LIFE_TIME: 'rc_promo_team_admin_lifetime',
  RC_PROMO_TEAM_OWNER_LIFE_TIME: 'rc_promo_team_owner_lifetime',
  RC_PROMO_TEAM_MEMBER_MONTHLY: 'rc_promo_team_member_monthly',
  TEAM_SIZE_5: 'prod_MML2RSdXeHcZWh'
};
export const MAXIMUM_ALLOWED_MINUTES = 999;
export const MAXIMUM_ALLOWED_SECONDS = 59940;
export const PLATFORMS = {
  WEB: 'web',
  MAC: 'mac',
  WIN: 'win',
  MOB: 'mob',
  IOS: 'ios',
  ANDROID: 'android'
};
export const TAB = {
  TIMING: 'tab_timing',
  MORNING: 'tab_morning',
  MICRO_BREAKS: 'tab_micro_breaks',
  EVENING: 'tab_evening',
  HABIT_PACK: 'tab_habit_packs',
  PACK_INFO: 'tab_pack_info',
  ACTIVITY: 'tab_activity',
  SETTINGS: 'tab_settings',
  FOCUS_MODE_INFO: 'tab_focus_mode_info',
  PROCRASTINATE: 'procrastinate'
};

export const TABS = {
  DEFAULT: [
    TAB.TIMING,
    TAB.MORNING,
    TAB.MICRO_BREAKS,
    TAB.EVENING,
    TAB.HABIT_PACK
  ],
  FREESTYLE: [TAB.TIMING, TAB.MICRO_BREAKS],
  STANDALONE: [TAB.ACTIVITY, TAB.PACK_INFO],
  ROUTINE: [TAB.MORNING, TAB.MICRO_BREAKS, TAB.EVENING, TAB.PACK_INFO],
  FOCUS_MODE: [TAB.SETTINGS, TAB.FOCUS_MODE_INFO],
  CUSTOM_ROUTINE: [TAB.TIMING, TAB.MORNING, TAB.EVENING, TAB.HABIT_PACK],
  MORNING: [TAB.MORNING],
  BREAKING: [TAB.MICRO_BREAKS],
  EVENING: [TAB.EVENING]
};

export const ACTIVITY_TYPES = {
  MORNING: 'morning_activities',
  BREAKING: 'break_activities',
  EVENING: 'evening_activities',
  STANDALONE: 'standalone_activities',
  LIBRARY: 'library',
  CHOICE: 'choice'
};

export const MODAL_TYPES = {
  YOUTUBE_URLS: 'youtube_urls',
  ALLOWED_URLS: 'allowed_urls',
  IMAGE_URLS: 'image_urls',
  ROUTINE: 'routine',
  INQUIRY: 'inquiry',
  CHOICES: 'choices',
  CHOICE_YOUTUBE: 'choice_youtube_urls',
  PDF: 'pdf',
  UNLOCK_HABIT_PACK: 'unlock_habit_pack',
  LOG_QUESTION_DETAILS: 'log_question_details',
  LOG_QUANTITY: 'log_quantity',
  MORE_OPTION: 'more-option',
  NOTE: 'note',
  INVITE: 'invite',
  CHECK_LIST: 'check-list',
  REQUEST_USER_DETAILS: 'request-user-details',
  SKIP_ACTIVITY_MODAL: 'skip-activity-modal',
  SET_REMINDER: 'set-reminder',
  REQUEST_DATA_CONFIRMATION_MODAL: 'request-data-confirmation-modal',
  ACCOUNT_DELETION_MODAL: 'account-deletion-modal',
  FOCUS_SUMMARY_GRAPH: 'focus-summary-graph',
  EISENHOWER_MATRIX_MODAL: 'eisenhower-matrix-modal',
  ROUTINE_ACTIVITIES_STATS_MODAL: 'routine-activities-stats-modal',
  EMBEDDED_CREATE_TO_DO_MODAL: 'embedded-create-to-do-modal',
  TO_DO_SUB_TASK_MODAL: 'task-to-do-task-modal',
  CONFIRM_ACTION_DELETE_FOCUS_TEMPLATE: 'confirm-action-delete-focus-template',
  CONFIRM_ACTION_DELETE_HABIT_PACK: 'confirm-action-delete-habit-pack',
  CONFIRM_ACTION_REMOVE_HABIT_PACK_FROM_MARKET_PLACE:
    'confirm-action-remove-habit-pack-from-market-place',
  CONFIRM_ACTION_REMOVE_FOCUS_TEMPLATE_FROM_MARKET_PLACE:
    'confirm-action-remove-focus-template-from-market-place',
  CONFIRM_ACTION_LOGOUT: 'confirm-action-logout',
  FOCUS_SUMMARY_MORE: 'focus-summary-more',
  DELETE_KEYWORD_MODAL: 'delete-keyword-modal',
  UPDATE_KEYWORD_MODAL: 'update-keyword-modal',
  PROJECT_TASKS_MODAL: 'project-tasks-modal',
  LONG_TERM_GOALS_MODAL: 'long-term-goals-modal',
  COURSE_LESSONS_MODAL: 'course-lessons-modal',
  COURSE_DETAILS_MODAL: 'course-details-modal',
  SURVEY_DETAIL_MODAL: 'survey-detail-mode',
  CONFIRM_ACTION_DELETE_PLAYER_TASK: 'confirm-action-delete-player-task'
};

export const HABIT_SETTING_FEATURES = {
  NAME: 'name',
  DURATION: 'duration',
  COMPLETION_REQUIREMENTS: 'completion_requirements',
  LOG_QUANTITY: 'log_quantity',
  VIDEO_URLS: 'video_urls',
  LOG_QUANTITY_QUESTION: 'log_quantity_question',
  ALLOWED_APPS: 'allowed_apps',
  ALLOWED_URLS: 'allowed_urls',
  FOCUS_MODE_ID: 'allowed_focus_mode_id',
  LOG_SUMMARY_TYPE: 'log_summary_type',
  RUN_MICRO_BREAKS: 'run_micro_breaks',
  TAKE_NOTES: 'take_notes',
  PRIORITY: 'priority',
  CATEGORY: 'category',
  IMAGE_URLS: 'image_urls',
  TEXT_INSTRUCTIONS: 'text_instructions',
  DAYS_OF_WEEK: 'days_of_week',
  CURRENT_COMPETENCY_LEVEL: 'current_competency_level'
};

export const SUBSCRIPTION_INFO_STORE = {
  APP_STORE: 'app_store',
  PLAY_STORE: 'play_store',
  NO_PLAN: 'no_plan'
};

export const CALENDAR_RECURRENCE_RULE_FOR_DAILY_RECURRENCE =
  'FREQ=DAILY;INTERVAL=1';

export const UTC_TIMEZONE = 'UTC';

export const CALENDAR_OPTIONS = [
  { label: CalendarTypeLabels.GOOGLE, value: CalendarTypes.GOOGLE },
  { label: CalendarTypeLabels.OUTLOOK, value: CalendarTypes.OUTLOOK },
  { label: CalendarTypeLabels.YAHOO, value: CalendarTypes.YAHOO },
  { label: CalendarTypeLabels.OFFICE_365, value: CalendarTypes.OFFICE_365 },
  { label: CalendarTypeLabels.ICS, value: CalendarTypes.ICS }
];

export const DEFAULT_MAC_APPS_IF_ALLOWED_URLS_SET = ['Google Chrome'];
export const DEFAULT_WINDOWS_APPS_IF_ALLOWED_URLS_SET = ['chrome.exe'];
export const NUMBER_OF_DAYS = 7;
export const HABIT_CHOICE_TYPE = {
  RANDOM: 'random',
  COMPETENCY_BASED: 'competency',
  NONE: 'none',
  CHECK_LIST: 'check_list'
};

export const LANGUAGE = {
  GB: 'GB',
  ES: 'ES'
};

export const ACTIVITIES_TABLE_COLUMNS = [
  'admin.no',
  'admin.id',
  'admin.name',
  'admin.type',
  'admin.duration_sec',
  'admin.log_summary_type',
  'admin.log_quantity_q',
  'admin.has_choices',
  'admin.log_quantity',
  'admin.is_default',
  'admin.allowed_apps',
  'admin.allowed_urls',
  'admin.video_urls',
  'admin.user_id',
  'admin.sequence_id',
  'admin.template_id',
  'admin.parent_id',
  'admin.created_at'
];

export const FOCUS_MODES_TABLE_COLUMNS = [
  'admin.no',
  'admin.id',
  'admin.name',
  'admin.user_id',
  'admin.allowed_apps',
  'admin.allowed_urls',
  'admin.created_at',
  'admin.deleted_at'
];

export const USERS_TABLE_COLUMNS = {
  PUBLIC: [
    'admin.no',
    'admin.id',
    'admin.operating_system',
    'admin.stripe_id',
    'admin.auth0_id',
    'admin.user_type',
    'admin.sign_up_pack',
    'admin.timezone',
    'admin.current_sequence',
    'admin.current_activity',
    'admin.current_focus_mode',
    'admin.last_completed_sequence',
    'admin.last_focus_mode',
    'admin.member_of_team_id',
    'admin.owner_of_team_id',
    'admin.created_at'
  ],
  PRIVATE: [
    'admin.email',
    'admin.startup',
    'admin.shutdown',
    'admin.break',
    'admin.morning_streak',
    'admin.evening_streak',
    'admin.focus_mode_streak',
    'admin.last_focus_mode',
    'admin.settings_password'
  ]
};

export const ROUTINE_SOURCE = {
  morning_activities: [
    { label: 'move_habit.break', value: ACTIVITY_TYPES.BREAKING },
    { label: 'move_habit.evening', value: ACTIVITY_TYPES.EVENING }
  ],
  break_activities: [
    { label: 'move_habit.morning', value: ACTIVITY_TYPES.MORNING },
    { label: 'move_habit.evening', value: ACTIVITY_TYPES.EVENING }
  ],
  evening_activities: [
    { label: 'move_habit.morning', value: ACTIVITY_TYPES.MORNING },
    { label: 'move_habit.break', value: ACTIVITY_TYPES.BREAKING }
  ]
};

export const ROUTINE_DESTINATION_POSITION = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end'
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER'
};

export const TAKE_NOTE_OPTION = {
  NO_NOTE: 'NO_NOTE',
  DURING_ACTIVITY: 'DURING_ACTIVITY',
  END_OF_ACTIVITY: 'END_OF_ACTIVITY'
};

export const LOG_SUMMARY_OPTION = {
  SUMMATION: 'SUM',
  AVERAGE: 'AVG'
};

export const PRIORITY_OPTION = {
  STANDARD: 'STANDARD',
  HIGH: 'HIGH'
};

export const uploaderOptions = {
  multi: false,
  styles: {
    colors: {
      primary: COLOR.BLUE_600
    }
  },
  mimeTypes: ['image/jpeg', 'image/png', 'image/jpg']
};

export const COUNT_DOWN_TIMER: {
  COLORS: { 0: ColorHex } & { 1: ColorHex } & ColorHex[];
  TIME: { 0: number } & { 1: number } & number[];
} = {
  COLORS: [
    `#${COLOR.DARK_CERULEAN}`,
    `#${COLOR.YELLOW}`,
    `#${COLOR.YELLOW}`,
    `#${COLOR.DARK_RED}`
  ],
  TIME: [24, 20, 10, 0]
};

export const ROUTINE_PLAYING_TYPE = {
  CURRENT: 'current',
  NEXT: 'next'
};

export const NORMAL_CHOICE_SELECTED_INDEX = 0;
export const MORE_OPTIONS_SETTINGS_TAB_INDEX = 2;
export const NOTIFICATION_MESSAGE_TO_DESKTOP_APP = {
  MAC: 'user-edited-settings',
  WIN: 'user-edited-settings'
};
export const HABIT_QUESTIONS = {
  MORNING_ROUTINE: 'what_habits_do_you_want_to_do_in_your_morning_routine',
  HABIT_PACK_EVENING_ROUTINE:
    'what_habits_do_you_want_to_do_in_your_evening_routine',
  BREAKING_ROUTINE: 'what_activities_do_you_want_to_do_in_your_breaks',
  STANDALONE_ROUTINE: 'what_activities_do_you_want_to_do',
  EVENING_ROUTINE: 'your_evening_routine_will_begin_at'
};

export const LOCAL_STORAGE = {
  REDIRECT_PATH: 'redirect-path',
  FOCUS_TEMPLATE_ID: 'focus-template-id',
  PACK_ID: 'pack-id',
  BEARER_TOKEN: 'focusbear',
  PACK_TO_INSTALL: 'pack-to-install',
  FOCUS_TEMPLATE_TO_INSTALL: 'focus-template-to-install',
  SIGN_UP_REFERRAL: 'sign-up-referral',
  AVAILABLE_PLANS: 'available-plans',
  FEATURED_HABIT_PACKS: 'featured-habit-packs',
  INVITATION_TOKEN: 'invitation-token',
  ERROR_PAGE_RELOADED: 'error-page-reloaded',
  REDIRECTION_COMPLETED: 'redirection-completed',
  TO_DO_PLAYER_TASKS: 'to-do-player-tasks',
  TO_DO_RECENT_TASKS: 'to-do-recent-tasks',
  REDIRECT_TO_MANAGE_SUBSCRIPTION_VIA_WEBVIEW:
    'redirect-to-manage-subscription-via-webview',
  BRIAN_DUMP_CONTENTS: 'brian-dump-contents'
};

export const SCREEN = {
  WIDTH: {
    SMALL: 640,
    TABLET: 768,
    LARGE: 1024,
    EXTRA_LARGE: 1280,
    DOUBLE_EXTRA_LARGE: 1536
  },
  HEIGHT: { PORTRAIT: 768, LANDSCAPE: 400 }
};
export const TOAST_AUTO_CLOSE_TIMEOUT = 1000;
export const MORNING_ROUTINE_ADVANCED_ACTION_TYPE = {
  CREATE: 'create',
  DUPLICATE: 'duplicate'
};
export const SPLICE_DELETE_COUNT = 1;
export const SPLICE_CLONE_COUNT = 1;
export const SPLICE_INSERT_COUNT = 0;
export const SPLICE_COPY_COUNT = 0;
export const DEFAULT_DURATION = {
  MORNING: 60,
  BREAKING: 60,
  EVENING: 600,
  LIBARARY: 600,
  STANDALONE: 60,
  DEFAULT: 60,
  MORE_OPTION: 60
};
export const COUNT_DOWN_TIMER_SIZE = {
  DEFAULT: 180,
  LANDSCAPE: 115
};
export const TOAST_MESSAGE_TIME_OUT = 2000;
export const INQUIRY_NOTICE_MODAL_SWITCH_SIZE = {
  WIDTH: 50,
  HEIGHT: 20
};
export const INQUIRY_TYPE = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  CALENDAR: 'calendar',
  DESKTOP_APP: 'desktop_app'
};
export const DAY = {
  ALL: 'days.all',
  SUNDAY: 'days.sunday',
  MONDAY: 'days.monday',
  TUESDAY: 'days.tuesday',
  WEDNESDAY: 'days.wednesday',
  THURSDAY: 'days.thursday',
  FRIDAY: 'days.friday',
  SATURDAY: 'days.saturday',
  WEEKEND: 'days.weekend',
  WEEKDAYS: 'days.weekdays'
};
export const DAYS_OF_WEEK = [
  {
    label: DAY.ALL,
    value: DaysOfWeek.ALL
  },
  {
    label: DAY.SUNDAY,
    value: DaysOfWeek.SUN
  },
  {
    label: DAY.MONDAY,
    value: DaysOfWeek.MON
  },
  {
    label: DAY.TUESDAY,
    value: DaysOfWeek.TUE
  },
  {
    label: DAY.WEDNESDAY,
    value: DaysOfWeek.WED
  },
  {
    label: DAY.THURSDAY,
    value: DaysOfWeek.THU
  },
  {
    label: DAY.FRIDAY,
    value: DaysOfWeek.FRI
  },
  {
    label: DAY.SATURDAY,
    value: DaysOfWeek.SAT
  },
  {
    label: DAY.WEEKEND,
    value: DaysOfWeek.WEEKEND
  },
  {
    label: DAY.WEEKDAYS,
    value: DaysOfWeek.WEEKDAYS
  }
];
export const ALLOWED_APPS_MIN_QUERY_LENGTH = 1;
export const TEMPLATES_TEXT_MAX_CHARACTER_LENGTH = 2500;
export const OPEN_STRIPE_CHECKOUT = 'open-stripe-checkout';
export const ROUTINE_SUMMARY_STAT = {
  MIN: 0,
  MAX: 30
};
export const REACT_SELECT_DROPDOWN = {
  Z_INDEX: {
    DEFAULT: 60,
    MOVE_ACTIVITY: 60,
    NEW_TASK: 71,
    SUB_TASKS: 99,
    CHOICE: 999
  },
  FONT_SIZE: 12
};
export const NOTE_TEXTAREA_MAX_ROWS = 4;

export const DEFAULT_CHOICE_OPTIONS = [
  {
    value: HABIT_CHOICE_TYPE.NONE,
    label: String(i18n.t(HABIT_CHOICE_TYPE.NONE)).toUpperCase()
  },
  {
    value: HABIT_CHOICE_TYPE.RANDOM,
    label: String(i18n.t(HABIT_CHOICE_TYPE.RANDOM)).toUpperCase()
  },
  // DESCRIPTION: Deon informed that it needs to be disable for a while
  // {
  //   value: HABIT_CHOICE_TYPE.COMPETENCY_BASED,
  //   label: String(
  //     i18n.t('competency_based')
  //   ).toUpperCase(),
  // },
  {
    value: HABIT_CHOICE_TYPE.CHECK_LIST,
    label: String(i18n.t(HABIT_CHOICE_TYPE.CHECK_LIST)).toUpperCase()
  }
];

export const ROUTINE_CHOICE_OPTIONS = [
  {
    value: HABIT_CHOICE_TYPE.NONE,
    label: String(i18n.t(HABIT_CHOICE_TYPE.NONE)).toUpperCase()
  },
  // DESCRIPTION: Deon informed that it needs to be disable for a while
  // {
  //   value: HABIT_CHOICE_TYPE.COMPETENCY_BASED,
  //   label: String(
  //     i18n.t('competency_based')
  //   ).toUpperCase(),
  // },
  {
    value: HABIT_CHOICE_TYPE.CHECK_LIST,
    label: String(i18n.t(HABIT_CHOICE_TYPE.CHECK_LIST)).toUpperCase()
  }
];

export const RICH_TEXT_EDITOR_HEIGHT = {
  DEFAULT: 90,
  NEW_TASK: 100,
  ROUTINE: 350
};
export const StatOptions = {
  MORNING: 'morning',
  EVENING: 'evening',
  FOCUS_MODE: 'focus_mode'
};
export const SPINNER_TITLE = {
  INSTALL: 'installing',
  UPDATE: 'updating'
};
export const CLONE_ICON = {
  HABIT_PACKS: {
    WIDTH: 12,
    HEIGHT: 12
  }
};
export const ROUTINE_LEVEl_ICON_DIMENSION = {
  DESKTOP: {
    WIDTH: 50,
    HEIGHT: 50
  },
  MOBILE: {
    WIDTH: 40,
    HEIGHT: 40
  }
};
export const DEFAULT_ROUTINE_LEVEL = {
  AVERAGE_COMPLETION_PERCENTAGE: 0
};
export const EMPTY_STRING = '';
export const HABIT_PACK_FOCUS_TEMPLATES_TAB_INDEX = {
  ACTIVITY_LIBRARY: 0,
  HABIT_PACKS: 1,
  FOCUS_TEMPLATES: 2
};
export const MARKET_TAB_INDEX = {
  HABIT_PACKS: 0,
  FOCUS_TEMPLATES: 1
};
export const AXIOS_TIMEOUT = 10000;
export const HTTP_STATS_CODE = {
  NO_CONNECTION: 0,
  SUCCESS: 200,
  NO_CONTENT: 204,
  CREATED: 201,
  NOT_FOUND: 404,
  INTERNAL_SEVER_ERROR: 500,
  BAD_REQUEST: 400,
  TOO_MANY_REQUEST: 429,
  REST_CONTENT: 205
};

export const COMPETENCY_CHOICES_LEVEL_THRESHOLDS = {
  LEVEL_DOWN: 4,
  LEVEL_UP: 9
};
export const NUMBER_OF_ACTIVITIES_SHOULD_SHOW_ADD_NEW_ACTIVITY_TO_EACH_ACTIVITY = 10;
export const FOCUS_MODE_ALLOWED_TYPE = {
  APPS: 'apps',
  URLS: 'urls'
};

export const LANGUAGE_OPTIONS = [
  { value: LanguageCodes.EN, label: Languages.ENGLISH },
  { value: LanguageCodes.ES, label: Languages.SPANISH }
];
export const NUMBERS = {
  MINUS: {
    ONE: -1,
    TWO: -2,
    THREE: -3
  },
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  SIXTY: 60
};
export const INDEX = NUMBERS;
export const PDF_FILENAME = {
  SETTINGS: 'settings.pdf'
};
export const PACK_OPTIONS = {
  ROUTINE: 'routine',
  STANDALONE: 'standalone'
};
export const COOKIES = {
  LANGUAGE: 'i18next'
};
export const SHORT_DAY_NAME = 'ddd';
export const PDF_PAPER_TYPE = 'A4';
export const FOCUS_BEAR = 'Focus Bear';
export const ITEM_INDEX_NOT_FOUND = -1;
export const INITIAL_COMPETENCY_LEVEL = 1;
export const MAXIMUM_IMAGE_URLS = 4;
export const ACTIVITY_TEMPLATE_ID = 'activity_template_id';
export const QUERIES = {
  EMBEDDED: 'embedded'
};
export const SENTRY = {
  DNS: 'https://10feadec83b909ed85197bab5ac9c0b0@sentry.focusbear.io/4',
  TRACE_SAMPLE_RATE: 0.2,
  REPLAYS_SESSION_SAMPLE_RATE: 0.1,
  REPLAYS_ON_ERROR_SAMPLE_RATE: 1.0
};
export const LOG_QUANTITY_QUESTION_FEATURES = {
  QUESTION: 'question',
  QUESTION_DETAILS: 'question_details'
};
export const OK = 'ok';
export const ACTIVITY_SEQUENCE_TYPE = {
  MORNING: 'morning',
  EVENING: 'evening',
  BREAKING: 'breaking',
  STANDALONE: 'standalone'
};
export const AXE_CORE = {
  TIMEOUT: 1000
};
export const NODE_ENV = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test'
};
export const DASHBOARD = 'dashboard';
export const TOAST = {
  AUTO_CLOSE: {
    COPIED: 1000
  }
};
export const QUOTE = '"';
export const FORWARD_SLASH = '/';
export const FONTS = {
  SANS: 'font-sans',
  SEGOE_UI: 'font-segoeUI',
  SAN_FRANCISCO: 'font-sanFrancisco',
  OPEN_DYSLEXIC: 'font-openDyslexic',
  ARIAL: 'font-arial',
  COMIC_SANS: 'font-comicSans',
  VERDANA: 'font-verdana',
  TAHOMA: 'font-tahoma',
  HELVETICA: 'font-helvetica',
  INTEL_ONE_MONO: 'font-intelOneMono',
  CENTURY_GOTHIC: 'font-centuryGothic',
  ROBOTO: 'font-roboto',
  TREBUCHET_MS: 'font-trebuchetMS',
  POPPINS: 'font-poppins'
};
export const CONSENT_OPTION = {
  EVENT_TRACKING: 'event_tracking',
  TERMS_OF_SERVICE: 'terms_of_service'
};
export const SUBSCRIPTION_PRICE = {
  TEAM: 4.99,
  PERSONAL_MONTHLY: 4.99,
  PERSONAL_ANNUAL: 2.99,
  ORGANIZATION: 19.99
};
export const SUBSCRIPTION_PRICE_ID = {
  TEAM: 'price_1NvYTNAbucJVfCqsAJMDsrlF',
  PERSONAL_MONTHLY: 'price_1LdcGhAbucJVfCqsbZ86R8v2',
  ORGANIZATION: 'price_1OysHCAbucJVfCqsxSDxQ9hy',
  PERSONAL_ANNUAL: 'price_1PE3xSAbucJVfCqsdUrzH4cs'
};
export const ACTIVITY_FILTER_OPTIONS = [
  'MORNING',
  'BREAKING',
  'EVENING',
  'STANDALONE',
  'LIBRARY'
];
export const PAGINATE_ITEMS = 20;
export const SEARCH_RESULT = {
  ACTIVITIES: 'activities',
  FOCUS_MODES: 'focus-modes',
  NONE: EMPTY_STRING
};
export const SEPARATOR = {
  COMMA: ', '
};
export const PARAMS = {
  LANGUAGE: 'lang',
  EDIT_SPECIFIC_ROUTINE: 'edit_specific_routine',
  TAB: 'tab',
  URL: 'url'
};
export const QUESTION_MARK = '?';
export const LEVEL_KEYS = [
  'novice',
  'apprentice',
  'explorer',
  'disciplined',
  'diligent',
  'strategist',
  'mentor',
  'scholar',
  'guardian',
  'achiever'
];
export const AI_EVENT = {
  FATAL_ERROR: 'FatalError'
};

export const PRIVATE_USER_DETAILS_KEYS = [
  'email',
  'startup_time',
  'shutdown_time',
  'break_after_minutes',
  'morning_routines_streak',
  'evening_routines_streak',
  'focus_modes_streak',
  'is_office_mode_activated',
  'has_edited_settings'
];

export const PUBLIC_USER_DETAILS_KEYS = [
  'id',
  'local_device_settings',
  'stripe_customer_id',
  'auth0_id',
  'user_type',
  'signed_up_via_habit_pack',
  'timezone',
  'current_activity_sequence_id',
  'current_activity_id',
  'current_focus_mode_id',
  'last_completed_sequence_at',
  'last_completed_focus_mode_at',
  'member_of_team_id',
  'owner_of_team_id',
  'created_at'
];

export const USER_FOCUS_MODE_KEYS = [
  'id',
  'name',
  'user_id',
  'allowed_apps',
  'allowed_urls',
  'created_at',
  'deleted_at'
];

export const USER_ACTIVITIES_KEY = [
  'id',
  'type',
  'duration_seconds',
  'log_summary_type',
  'has_choices',
  'log_quantity',
  'is_default',
  'user_id',
  'activity_sequence_id',
  'activity_template_id',
  'parent_id',
  'created_at',
  'log_quantity_question',
  'name',
  'video_urls',
  'allowed_apps',
  'allowed_urls'
];

export const HABIT_SKIP_REASON = {
  ALREADY_DID_IT: 'already-did-it',
  CAN_NOT_DO_IT: 'can-not-dot-it'
};

export const TIMEZONE = {
  UTC: 'utc'
};

export const FILENAME = {
  FOCUS_SUMMARY: 'focus_summary.csv',
  EISENHOWER_MATRIX_SUMMARY: 'eisenhower_matrix_summary.csv',
  TO_DO_TASKS_SUMMARY: 'to_do_tasks_summary.csv'
};

export const HABIT_PACK_OPTION = {
  INSTALLED: 'installed',
  FEATURED: 'featured'
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const POST_HOG_EVENT = {
  SIGN_UP_DIRECT: 'sign-up-direct',
  SIGN_UP_VIA_HABIT_PACK: 'sign-up-via-habit-pack',
  SIGN_UP_VIA_FOCUS_TEMPLATE: 'sign-up-via-focus-template',
  TODO_PLAYER_ADD_TASK_BUTTON_CLICKED: 'todo-player-add-task-button-clicked',
  TODO_PLAYER_EXISTING_TASK_ADDED_FROM_ADD_TASK_UI:
    'todo-player-existing-task-added-from-add-task-ui',
  TODO_PLAYER_BRAINDUMP_AI_BUTTON_CLICKED:
    'todo-player-braindump-ai-button-clicked',
  TODO_PLAYER_TASK_PLAY_BUTTON_CLICKED: 'todo-player-task-play-button-clicked',
  FOCUS_SUMMARY: 'focus_summary',
  ROUTINE_SUGGESTIONS_GOALS_SELECTION_CONTINUE_BUTTON_CLICKED:
    'routine-suggestions-goals-selection-continue-button-clicked',
  ROUTINE_SUGGESTIONS_ROUTINE_SELECTION_CONTINUE_BUTTON_CLICKED:
    'routine-suggestions-routine-selection-continue-button-clicked',
  ROUTINE_SUGGESTIONS_ROUTINE_DURATION_CONTINUE_BUTTON_CLICKED:
    'routine-suggestions-routine-duration-continue-button-clicked',
  ROUTINE_SUGGESTIONS_SUGGEST_HABITS_FOR_ME_BUTTON_CLICKED:
    'routine-suggestions-suggest_habits_for_me_button-clicked',
  ROUTINE_SUGGESTIONS_EDIT_HABITS_BUTTON_CLICKED:
    'routine-suggestions-edit-habits-button-clicked',
  ROUTINE_SUGGESTIONS_USE_GENERATED_BUTTON_CLICKED:
    'routine-suggestions-use-generated-button-clicked',
  ROUTINE_SUGGESTIONS_EXPLORE_HABIT_PACKS_BUTTON_CLICKED:
    'routine-suggestions-explore-habit-packs-button-clicked',
  ROUTINE_SUGGESTIONS_INSTALL_HABIT_PACK_BUTTON_CLICKED:
    'routine-suggestions-install-habit-pack-button-clicked'
};

export const REFERRAL = {
  HABIT_PACK: 'habit-pack',
  FOCUS_TEMPLATE: 'focus-template',
  NONE: 'none'
};

export const LAZY_LOAD_TIME = 1000;

export const USER_SETTINGS_KEYS = {
  HAS_EDITED_SETTINGS: 'has_edited_settings',
  CUTOFF_TIME_FOR_NON_HIGH_PRIORITY_ACTIVITIES:
    'cutoff_time_for_non_high_priority_activities'
};

export const FOCUS_SUMMARY = {
  GRAPH_TENSION: 0.25,
  TIME_FORMAT: {
    YEAR: 'YYYY',
    MONTH_AND_YEAR: 'MMM YYYY',
    DAY_MONTH_AND_YEAR: 'MMM DD, YYYY'
  },
  TIME_UNIT: {
    SECOND: 'second',
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year'
  },
  COLUMN: {
    FOCUS_BLOCK: NUMBERS.ZERO,
    DURATION: NUMBERS.ONE,
    START_TIME: NUMBERS.TWO,
    FINISH_TIME: NUMBERS.THREE,
    INTENTION: NUMBERS.FOUR,
    ACHIEVEMENT: NUMBERS.FIVE,
    DISTRACTION: NUMBERS.SIX
  },
  SUMMARY_OPTION: {
    DAILY: 'daily',
    MONTHLY: 'monthly',
    YEARLY: 'yearly'
  }
};

export const DYNAMIC_PATH_ID = ':id';
export const SKIP_ACTIVITY_COUNT_DOWN_OFFSET = 15;
export const STRIPE_CUSTOMER_ID_CHARACTER = 18;
export const FOCUS_SUMMARY_DATA_LIMIT = 30;
export const SKIP_ACTIVITY_COUNT_DOWN_INTERVAL = 1000;

export const EISENHOWER_PRIORITY_TITLE_ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
};

export const EISENHOWER_QUADRANT = {
  DO: '1',
  DECIDE: '2',
  DELEGATE: '3',
  DELETE: '4'
};

export const OPEN_AI_RESPONSE_DATA = {
  DONE: '[DONE]',
  PROMPT: 'PROMPT:'
};

export const PROFIT_WELL_USER_ID = 'user_id';

export const ROUTINE_ACTIVITIES_STATS_OPTIONS = [
  'routine_player.daily',
  'routine_player.time_range'
];

export const ROUTINE_ACTIVITIES_STATS_KEY = {
  DAILY: 0,
  TIME_RANGE: 1
};

export const DEFAULT_ACTIVITY_STATS_DAYS = 30;
export const INITIAL_RATING = 0;

export const EISENHOWER_QUADRANT_OPTIONS: DropDownSelectedValue[] = [
  {
    label: i18n.t('to_do_procrastinate.urgent_and_important'),
    value: EISENHOWER_QUADRANT.DO
  },
  {
    label: i18n.t('to_do_procrastinate.important_but_not_urgent'),
    value: EISENHOWER_QUADRANT.DECIDE
  },
  {
    label: i18n.t('to_do_procrastinate.not_important_and_urgent'),
    value: EISENHOWER_QUADRANT.DELEGATE
  },
  {
    label: i18n.t('to_do_procrastinate.not_important_and_not_urgent'),
    value: EISENHOWER_QUADRANT.DELETE
  }
];

export const GENERAL_ACTIVITY_TYPES = [
  ACTIVITY_TYPES.MORNING,
  ACTIVITY_TYPES.BREAKING,
  ACTIVITY_TYPES.EVENING,
  EMPTY_STRING
];

export const IMPACT_MEASUREMENT_OPTIONS = [
  {
    label: i18n.t('impact_measurement.hours_of_sleep'),
    value: 'hours_of_sleep'
  },
  {
    label: i18n.t('impact_measurement.energy_level_upon_awakening'),
    value: 'energy_level_upon_awakening'
  },
  { label: i18n.t('impact_measurement.mood'), value: 'mood' },
  {
    label: i18n.t('impact_measurement.perception_of_productivity'),
    value: 'perception_of_productivity'
  }
];

export const TIME_UNIT_CONVERT_FACTOR = {
  ONE_MINUTE_AS_SECONDS: 60,
  ONE_HOUR_AS_SECONDS: 3600
};
export const DESKTOP_APP_CLOSE = {
  SURVEY_MESSAGE: 'close-survey-ui',
  TO_DO_TASK_UI: 'close-to-do-task-ui'
};

export const MAC_APP_DATA_LOCATION = {
  START_FOCUS_MODE: 'start-focus-mode',
  END_FOCUS_MODE: 'end-focus-mode',
  CLOSE_SURVEY_UI: 'close-survey-ui',
  CLOSE_TODO_TASK_UI: 'close_todo_task_ui',
  SUBMIT_USER_SETTINGS: 'submit-user-settings',
  USER_SETTINGS_UPDATED: 'user-settings-updated',
  SEND_NEW_TASK_FROM_TO_DO_PLAYER: 'send-new-task-from-todo-player',
  SEND_SUGGESTED_ACTIVITIES: 'send-suggested-activities',
  SEND_SELECTED_HABIT_PACK_ID: 'send-selected-habit-pack-id',
  SEND_SELECTED_HABIT_PACK: 'send-selected-habit-pack',
  SEND_BRAIN_DUMP_CONTENT: 'send-brain-dump-content',
  SEND_USER_SUBSCRIPTION_STATUS: 'send_user_subscription_status',
  EDIT_HABITS_ENABLED: 'edit-habits-enabled',
  ROUTINE_SUGGESTIONS_STEP: 'routine-suggestions-step',
  SEND_INTENTION: 'send-intention'
};

export const SETTING_TAB_INDEX = {
  TIMING: 0,
  MORNING_ROUTINE: 1,
  MICRO_BREAKS: 2,
  EVENING_ROUTINE: 3,
  HABIT_PACKS: 4
};

export const NUMBER_OF_MONTHS = 1;
export const FIRST_ACTIVITY_INDEX = 0;

export const INTEGRATION_PLATFORM = {
  ZOHO: 'Zoho',
  JIRA: 'Jira',
  CLICK_UP: 'ClickUp',
  TRELLO: 'Trello',
  ASANA: 'Asana',
  MONDAY: 'Monday',
  GOOGLE: 'Google',
  MICROSOFT: 'Microsoft'
};

export const TO_DO_TAB = {
  TO_DO: 0,
  PROCRASTINATE: 1,
  INTEGRATIONS: 2
};

export const INITIAL_TEXT_EDITOR_VALUE = '<p></p>\n';
export const NUMBER_OF_DIGITS = 1;

export const EMBEDDED_EXPLAINER_VIDEOS = {
  SETUP_ALWAYS_BLOCKED_URLS: 'https://youtu.be/3U3ktaodAzk',
  INSTALL_MOBILE_APPLICATION: 'https://youtube.com/shorts/zv6be2sNOsk',
  SET_UP_A_FOCUS_MODE: 'https://youtu.be/L0ksKXqOXpk',
  EDIT_YOUR_HABITS: 'https://youtu.be/BCVdt210PGA',
  CHAT_WITH_FOCUS_BEAR: 'https://youtube.com/shorts/zfhQOxIFOZg'
};

export const TEAM_NAME_PLACEHOLDER = 'Team 1';
export const INVITATION_TOKEN_QUERY = 'token';

export const LOAD_PROJECT_OPTIONS_DELAY = 700;
export const USERNAME_PLACEHOLDER = '-----';
export const ACTIVITY_LIBRARY_CATEGORY = {
  GENERAL: 0
};
export const NUMBER_OF_DECIMAL_PLACES = 2;
export const PAGE = {
  INIT_TOTAL: 1,
  FIRST: 1
};
export const DEFAULT_ITEMS_PER_PAGE = 50;
export const TAB_INDEX = {
  TASK_COLLAPSE: 1,
  CHECK_LIST_INPUT: 1,
  PROJECTS_COLLAPSE: 1,
  DRAGGABLE_HANDLE: -1,
  TO_DO_SEARCH_RESULT: 0
};
export const DEFAULT_TEAM = 0;
export const FIRST_QUESTION_INDEX = 0;
export const ACTIVITY_STATS_GRAPH_TENSION = 0.25;
export const DEFAULT_TAB_LAYOUT_TAB = 0;
export const COMPLETED_TAB = 1;
export const ERROR_PAGE_TIMEOUT_DELAY = 2000;
export const ERROR_PAGE_RELOADED = '1';
export const DELETE_COUNT = 1;
export const NO_ELEMENT_FOUND = -1;
export const FIRST_LOG_QUESTION_INDEX = 0;
export const AUTO_COMPLETION_TAGS = {
  DELETE_OLD_POSITION_TAG_COUNT: 1,
  DELETE_NEW_POSITION_TAG_COUNT: 0
};
export const FIRST_DEVICE_INFO_INDEX = 0;
export const PLATFORM = ['Android', 'Windows', 'iOS', 'MacOS'];

export const ROUTINE_PLAYER_FEATURE = {
  ACTIVITY_CATEGORIES: 1,
  ROUTINE_ACTIVITIES: 2,
  ACTIVITY_PLAYER: 3,
  ACTIVITY_SUMMARY: 4
};
export const MINIMUM_NUMBER_OF_MEMBER = 1;
export const REDIRECTION_COMPLETED = '1';
export const MAXIMUM_NUMBER_OF_CHARACTERS = 80;
export const PROFILE_IMAGE = {
  FIRST_IMAGE_INDEX: 0,
  MAXIMUM_FILES: 1,
  MAXIMUM_FILE_SIZE: 1000000, // number of bytes => 1MB
  FILE_TYPES: ['jpg', 'png']
};
export const PULL_TO_REFRESH = {
  PULL_DOWN_TO_REFRESH: 10,
  MAXIMUM_PULL_DOWN_DISTANCE: 60
};
export const TEAM_PAYMENT = {
  STRIPE: 'stripe',
  OFFLINE: 'offline'
};
export const MINIMUM_NUMBER_OF_PAYMENT_METHODS = 1;
export const PLATFORM_INDEX = 1;
export const REFETCH_PROJECTS_TIMEOUT = 20000;
export const CALENDER_PLATFORMS = [
  INTEGRATION_PLATFORM.GOOGLE,
  INTEGRATION_PLATFORM.MICROSOFT
];
export const FOCUS_TEMPLATE_FEATURE = {
  NAME: 'name',
  DESCRIPTION: 'description',
  DESCRIPTION_VIDEO_URL: 'description_video_url',
  WELCOME_MESSAGE: 'welcome_message',
  WELCOME_VIDEO_URL: 'welcome_video_url',
  AUTHOR_NAME: 'author_name',
  ALLOWED_URLS: 'allowed_urls',
  ALLOWED_APPS: 'allowed_apps',
  IS_FEATURED: 'is_featured',
  FEATURE_FOR_ONBOARDING: 'featured_for_onboarding',
  MARKETPLACE_APPROVAL_STATUS: 'marketplace_approval_status'
};
export const TOAST_AUTO_CLOSE_TIMEOUT_DELAY = 2000;

export const ASSIGN_OPTIONS: DropDownSelectedValue[] = [
  {
    label: i18n.t('only_sync_assigned_tasks'),
    value: '1'
  },
  {
    label: i18n.t('sync_all_tasks'),
    value: '2'
  }
];

export const DEFAULT_ASSIGN_STATUS = [
  {
    platform: INTEGRATION_PLATFORM.ZOHO,
    status: '1'
  },
  {
    platform: INTEGRATION_PLATFORM.CLICK_UP,
    status: '1'
  },
  {
    platform: INTEGRATION_PLATFORM.ASANA,
    status: '1'
  },
  {
    platform: INTEGRATION_PLATFORM.JIRA,
    status: '1'
  },
  {
    platform: INTEGRATION_PLATFORM.MONDAY,
    status: '1'
  },
  {
    platform: INTEGRATION_PLATFORM.TRELLO,
    status: '1'
  }
];

export const ROUTINE = {
  MORNING: 'morning',
  EVENING: 'evening',
  BREAKING: 'breaking',
  SUGGESTION: 'suggestion'
};
export const MOVE_OPTION = {
  TOP: 'top',
  BOTTOM: 'bottom'
};
export const ROUTINE_FIRST_ACTIVITY_INDEX = 0;
export const NUMBER_OF_DELETE_ITEMS = 1;
export const NUMBER_OF_WEEK_DAYS = 7;
export const COURSE_ID_PARAM = 'course_id';
export const FIRST_LESSON_OFFSET = 0;
export const DEFAULT_VIDEO_DURATION = {
  playedSeconds: 0,
  totalSeconds: 0
};
export const SEEK_TO_START = 0;
export const TASK_PROJECT = {
  MAXIMUM_NUMBER_OF_CHARACTERS: 12,
  MAXIMUM_NUMBER_OF_TASKS: 2
};
export const SORT_VALUE = {
  LEFT_LESS_THAN_RIGHT: -1,
  LEFT_GREATER_THAN_RIGHT: 1
};
export const LEADER_BOARD_COLUMN = {
  RANK: 0
};
export const EXPORT_TO_DO_TASKS_EMPTY_PLACEHOLDER = '--';
export const NUMBER_OF_STARS = 5;
export const FIRST_TEAM_INDEX = 0;
export const MORE_OPTION_TAB = {
  TIMING: 'timing',
  YOUTUBE_VIDEOS: 'youtube_videos',
  ALLOWED_APPS: 'focus_mode.allowed_apps',
  LOG_DATA: 'log_data',
  SETTINGS: 'settings',
  ADVANCED: 'advanced',
  INSTRUCTIONS: 'instructions'
};
export const MINIMUM_CHARACTERS_CANCEL_REASON = 10;
export const MAXIMUM_TO_DO_DETAILS_CHARACTERS = 3200;
export const MAXIMUM_TO_DO_OBJECTIVE_CHARACTERS = 3200;
export const NUMBER_OF_GOALS_HEADERS = 9;
export const NUMBER_OF_VISIBLE_LEVELS = 7;
export const GOALS_LEVEL = {
  start: 0,
  level_1: 1,
  level_2: 2,
  level_3: 3,
  level_4: 4,
  level_5: 5,
  level_6: 6,
  level_7: 7,
  level_8: 8,
  level_9: 9
};
export const GOALS_LEVEL_TARGET = {
  [GOALS_LEVEL.level_1]: {
    [t('stats_page.morning_routine_streak')]: 1,
    [t('stats_page.micro_break_streak')]: 1,
    [t('stats_page.evening_routine_streak')]: 1,
    [t('stats_page.focus_mode_streak')]: 1
  },
  [GOALS_LEVEL.level_2]: {
    [t('stats_page.morning_routine_streak')]: 2,
    [t('stats_page.micro_break_streak')]: 2,
    [t('stats_page.evening_routine_streak')]: 2,
    [t('stats_page.focus_mode_streak')]: 2
  },
  [GOALS_LEVEL.level_3]: {
    [t('stats_page.morning_routine_streak')]: 5,
    [t('stats_page.micro_break_streak')]: 5,
    [t('stats_page.evening_routine_streak')]: 5,
    [t('stats_page.focus_mode_streak')]: 5,
    [t('stats_page.chat_with_focus_bear')]: 1
  },
  [GOALS_LEVEL.level_4]: {
    [t('stats_page.morning_routine_streak')]: 7,
    [t('stats_page.micro_break_streak')]: 7,
    [t('stats_page.evening_routine_streak')]: 7,
    [t('stats_page.focus_mode_streak')]: 10
  },
  [GOALS_LEVEL.level_5]: {
    [t('stats_page.morning_routine_streak')]: 10,
    [t('stats_page.micro_break_streak')]: 10,
    [t('stats_page.evening_routine_streak')]: 10,
    [t('stats_page.focus_mode_streak')]: 15
  },
  [GOALS_LEVEL.level_6]: {
    [t('stats_page.morning_routine_streak')]: 15,
    [t('stats_page.micro_break_streak')]: 15,
    [t('stats_page.evening_routine_streak')]: 15,
    [t('stats_page.focus_mode_streak')]: 30
  },
  [GOALS_LEVEL.level_7]: {
    [t('stats_page.morning_routine_streak')]: 30,
    [t('stats_page.micro_break_streak')]: 30,
    [t('stats_page.evening_routine_streak')]: 30,
    [t('stats_page.focus_mode_streak')]: 50
  },
  [GOALS_LEVEL.level_8]: {
    [t('stats_page.morning_routine_streak')]: 60,
    [t('stats_page.micro_break_streak')]: 60,
    [t('stats_page.evening_routine_streak')]: 60,
    [t('stats_page.focus_mode_streak')]: 100
  },
  [GOALS_LEVEL.level_9]: {
    [t('stats_page.morning_routine_streak')]: 100,
    [t('stats_page.micro_break_streak')]: 100,
    [t('stats_page.evening_routine_streak')]: 100,
    [t('stats_page.focus_mode_streak')]: 200
  }
};

export const GOALS_REQUIREMENT_WITHOUT_COUNTER = [
  t('stats_page.chat_with_focus_bear'),
  t('stats_page.setup_a_focus_mode')
];

export const TO_DO_PLAYER = {
  MINIMUM_DURATION: 1,
  TIMEOUT_DURATION: 1000,
  EMOJI_SIZE: {
    DEFAULT: 20,
    NEW_TASK: 30
  },
  FIRST_TASK_INDEX: 0,
  NUMBER_OF_TASK_TO_DELETE: 0
};

export const DEFAULT_TODO_TASK_DURATION = 60;
export const DEFAULT_TO_DO_TASK_EMOJI = '1f600';
export const TO_DO_COMPLETED_STATUS = 2;
export const NUMBER_OF_TOAST_VISIBLE = 1;
export const EMPTY_TASKS = 0;

export const EMPTY_LIST = 0;

export const YOUTUBE_URL_TAB_INDEX = 1;

export const DEFAULT_FEEDBACK_CHOICES = 11;
export const DEFAULT_HABIT_DURATION = 300;

export const TO_DO_PLAYER_SEARCH_DEBOUNCE_DELAY = 500;
export const FIRST_SUGGESTION_INDEX = 0;
export const FIRST_SETTING_TAB_INDEX = 0;
export const TO_DO_SUB_TASK_WHERE = {
  DASHBOARD: 'dashboard',
  TO_DO_PLAYER: 'to-do-player'
};
export const FOCUS_END_DURATION_NUMBER_OF_DECIMAL_PLACES = 1;
export const DEFAULT_NEW_TODO_TASK_DURATION = 600;
export const DEFAULT_TO_DO_PLAYER_TOAST_AUTO_CLOSE = 2000;
export const PROCRASTINATE_NOTE_MAXIMUM_NUMBER_OF_CHARACTERS = 255;

export const CACHE_NAME = {
  FOCUS_BEAR_STATIC: 'focus-bear-static',
  FOCUS_BEAR_DYNAMIC: 'focus-bear-dynamic'
};

export const TO_DO_PLAYER_TASK_BTN_DURATION = {
  INCREMENT_A_MINUTE: 'increment-a-minute',
  INCREMENT_TEN_MINUTE: 'increment-ten-minute',
  DECREMENT_A_MINUTE: 'decrement-a-minute',
  DECREMENT_TEN_MINUTE: 'decrement-ten-minute',
  MANUAL: 'manual'
};
export const MORNING_ROUTINE_TAB_INDEX = 0;
export const EVENING_ROUTINE_TAB_INDEX = 2;
export const DEFAULT_ROUTINE_DURATION = 10;
export const ROUTINE_SUGGESTION_MAXIMUM_DURATION = 100;
export const REACT_KEY = {
  MORNING_ROUTINE_SUGGESTION: '8bf7044c-235e-43ff-8464-2f53895ac3ab',
  EVENING_ROUTINE_SUGGESTION: 'fc6a6c9a-999d-47fd-911c-2e8a3e53e5ab'
};

export const ENROLLED_COURSES_TAB = {
  title: t('course.enrolled_courses'),
  index: 0
};

export const OTHER_COURSES_TAB = {
  title: t('course.other_courses'),
  index: 1
};
export const DEFAULT_TO_DO_PLAYER_DURATION = 10;
export const DEFAULT_SURVEYS_PER_PAGE = 20;
export const DEFAULT_TASKS_PER_PAGE = 50;
export const MOBILE_APP_EVENT = {
  SUGGESTED_ACTIVITIES_INSTALLED: 'suggested-activities-installed',
  SEND_SELECTED_HABIT_PACK_ID: 'send-selected-habit-pack-id',
  EDIT_HABITS_ENABLED: 'edit-habits-enabled'
};
export const TO_DO_SEARCH_DEBOUNCE_DELAY = 500;
export const MAC_APP_DATA_MESSAGE = {
  ACTIVE_SUBSCRIPTION: 'The user subscriptions status is active',
  INACTIVE_SUBSCRIPTION: 'The user subscriptions status is inactive',
  EDIT_HABITS_ENABLED: 'Edit habits enabled',
  ROUTINE_SUGGESTIONS_INSTALLED_SUCCESSFULLY:
    'routine-suggestions-installed-successfully'
};
export const TO_DO_PLAYER_EXTEND_TASK_TIME = 5; //@Description: the unit is minute
export const SECOND_SUBTASK_POSITION = 2;
export const ROUTINE_SUGGESTION_WIZARD_STEP = {
  GOALS: 0,
  ROUTINES: 1,
  DURATION: 2,
  HABITS: 3
};
export const ROUTINE_INTRODUCTION_YOUTUBE_VIDEO =
  'https://www.youtube.com/watch?v=ZRbrfTWa8AI';
