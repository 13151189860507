import { useEffect, useState } from 'react';
import {
  EMPTY_STRING,
  INVITATION_TOKEN_QUERY,
  LOCAL_STORAGE
} from 'constants/general';
import { ROUTES } from 'constants/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getLocalStorage,
  removeLocalStorage,
  updateLocalStorage
} from 'utils/support';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { acceptInvitation } from 'store/reducer/team/extra';
import Spinner from 'components/common/Spinner';
import jwtDecode from 'jwt-decode';
import { InvitationToken } from 'interfaces';
import {
  getUserAvailablePlans,
  getUserDetails,
  getUserSubscriptionInfo
} from 'store/reducer/user/extra';
import { updateIsAcceptingInvitation } from 'store/reducer/team/slice';
import TeamWelcomeModal from 'components/dashboard/team/TeamWelcomeModal';
import { DEFAULT_TEAM_NAME } from 'assets/data';

// @Description: only for a user in session
const TeamAcceptInvitation = () => {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState('');
  const token = new URLSearchParams(useLocation().search).get(
    INVITATION_TOKEN_QUERY
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const {
    team: { isInvitationAccepted, isAcceptingInvitation, showTeamWelcomeModal },
    setting: { isTokenAddedToInterceptor },
    user: { details: userInfo }
  } = useAppSelector((state) => state);

  useEffect(() => {
    initInvitation();
  }, []);

  useEffect(() => {
    if (isTokenAddedToInterceptor && userInfo?.email) {
      const invitation_data = getLocalStorage(LOCAL_STORAGE.INVITATION_TOKEN);
      if (invitation_data) {
        const parsedData = JSON.parse(invitation_data);
        if (parsedData?.email === userInfo.email) {
          setTeamName(parsedData?.teamName ?? DEFAULT_TEAM_NAME);
          dispatch(acceptInvitation(parsedData.token));
          removeLocalStorage([LOCAL_STORAGE.INVITATION_TOKEN]);
        } else {
          dispatch(updateIsAcceptingInvitation(false));
        }
      } else {
        navigate(ROUTES.NOT_FOUND);
      }
    }
    isTokenAddedToInterceptor &&
      userInfo?.email === EMPTY_STRING &&
      getUserDetails();
  }, [isTokenAddedToInterceptor, userInfo]);

  useEffect(() => {
    async function updateDetails() {
      await Promise.all([
        dispatch(getUserSubscriptionInfo()),
        dispatch(getUserAvailablePlans())
      ]);
      navigate(ROUTES.HOME);
    }
    isInvitationAccepted && updateDetails();
  }, [isInvitationAccepted]);

  const initInvitation = () => {
    if (token) {
      const tokenInfo: InvitationToken = jwtDecode(token);
      updateLocalStorage(
        LOCAL_STORAGE.INVITATION_TOKEN,
        JSON.stringify({ email: tokenInfo?.email, token })
      );
      !isAuthenticated && navigate(ROUTES.LOGIN);
    } else {
      navigate(ROUTES.NOT_FOUND);
    }
  };

  return (
    <div className='w-full min-h-screen flex items-center justify-center bg-bear bg-center bg-contain bg-no-repeat'>
      <div className='w-full min-h-screen flex flex-col gap-4 items-center justify-center bg-focusBear/90'>
        {isAcceptingInvitation && !isInvitationAccepted ? (
          <Spinner title={t('admin_team.processing_invitation_link')} />
        ) : (
          <>
            <p className='text-sm md:text-lg lg:text-xl font-semibold'>
              {t('could_not_process_the_request')}
            </p>
            <button
              onClick={() => navigate(ROUTES.HOME)}
              className='bg-orange-400 hover:bg-orange-600 text-white px-3 py-1 rounded-md text-xs sm:text-sm md:text-base font-semibold'
            >
              {t('back_to_homepage')}
            </button>
          </>
        )}
      </div>
      {!showTeamWelcomeModal && <TeamWelcomeModal teamName={teamName} />}
    </div>
  );
};

export default TeamAcceptInvitation;
