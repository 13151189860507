import { CHECKBOX } from 'constants/enum';
import React from 'react';

interface CheckBoxProps
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.Attributes {
  label?: string;
  inputStyles?: string;
  labelStyles?: string;
  checkboxType?: CHECKBOX;
  position?: number;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    position?: number
  ) => void;
  icon?: JSX.Element;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  inputStyles = 'cursor-pointer outline-none accent-green-300',
  labelStyles = 'text-xs sm:text-sm',
  checkboxType,
  onChange,
  position,
  icon,
  ...rest
}) => {
  return (
    <label
      className={`${labelStyles ?? 'min-w-max w-fit flex items-center gap-1.5 cursor-pointer'}`}
    >
      {icon}
      <input
        className={inputStyles}
        type={checkboxType ?? CHECKBOX.DEFAULT}
        onChange={(event) => onChange?.(event, position)}
        {...rest}
      />
      {label}
    </label>
  );
};

export default CheckBox;
