import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_VARIANT, Mode } from 'constants/enum';
import {
  MAC_APP_DATA_LOCATION,
  MAC_APP_DATA_MESSAGE,
  MOBILE_APP_EVENT,
  POST_HOG_EVENT,
  ROUTINE
} from 'constants/general';
import { t } from 'i18next';
import { ActivityType } from 'interfaces/commonInterface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { saveUserSettings } from 'store/reducer/setting/extra';
import { suggestionsActionsSelector } from 'store/reducer/setting/selectors';
import {
  configureTabs,
  updateAreSuggestedHabitPacksInstalled,
  updateIsInstallingRoutineSuggestions,
  updateShowEditSuggestionsModal
} from 'store/reducer/setting/slice';
import { filterOutCutoffTimeAndTutorial } from 'utils/support';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import InfoCircle from 'assets/icons/InfoCircle';
import { postHogCapture } from 'utils/events';

const SuggestionsActions = ({
  morning_activities,
  evening_activities
}: {
  morning_activities: ActivityType[];
  evening_activities: ActivityType[];
}) => {
  const dispatch = useAppDispatch();
  const {
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userEmail,
    userId
  } = useAppSelector(suggestionsActionsSelector);

  const handleGeneratedHabits = async () => {
    postHogCapture(
      POST_HOG_EVENT.ROUTINE_SUGGESTIONS_USE_GENERATED_BUTTON_CLICKED,
      userEmail,
      { platform, userId }
    );
    dispatch(updateIsInstallingRoutineSuggestions(true));
    const [morning_habits] = filterOutCutoffTimeAndTutorial(
      morning_activities,
      []
    );
    const response = await dispatch(
      saveUserSettings({
        ...currentSettings,
        morning_activities: morning_habits,
        evening_activities
      })
    );
    dispatch(updateIsInstallingRoutineSuggestions(false));
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(updateAreSuggestedHabitPacksInstalled(true));
      sendDataToPlatform(
        platform,
        MAC_APP_DATA_MESSAGE.ROUTINE_SUGGESTIONS_INSTALLED_SUCCESSFULLY,
        MAC_APP_DATA_LOCATION.SEND_SUGGESTED_ACTIVITIES,
        MOBILE_APP_EVENT.SUGGESTED_ACTIVITIES_INSTALLED
      );
    }
  };

  return (
    <div className='flex flex-col items-center gap-4 py-2 text-black'>
      {isInstallingRoutineSuggestions ? (
        <div className='w-5 h-5 rounded-full border-t-2 border-black animate-spin'></div>
      ) : (
        <div className='flex items-center gap-4 sm:gap-8'>
          <ButtonFocusBear
            title={t('routine_suggestion.edit_habits')}
            onClick={() => {
              dispatch(
                configureTabs({
                  mode: Mode.CUSTOM,
                  routine: ROUTINE.SUGGESTION
                })
              );
              dispatch(updateShowEditSuggestionsModal(true));
              postHogCapture(
                POST_HOG_EVENT.ROUTINE_SUGGESTIONS_EDIT_HABITS_BUTTON_CLICKED,
                userEmail,
                { platform, userId }
              );
            }}
            variant={BTN_FB_VARIANT.SECONDARY}
          />
          <ButtonFocusBear
            title={t('routine_suggestion.use_generated_habits')}
            onClick={handleGeneratedHabits}
          />
        </div>
      )}
      <p className='w-fit text-sm sm:text-base py-5 text-center flex items-center gap-1'>
        <InfoCircle />
        {t('routine_suggestion.you_can_also_customise_your_habits_later')}
      </p>
    </div>
  );
};

export default SuggestionsActions;
