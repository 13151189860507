import InfoCircle from 'assets/icons/InfoCircle';
import { useEffect, useMemo, useRef } from 'react';
import {
  PlacesType,
  Tooltip as ReactTooltip,
  TooltipRefProps
} from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash';

interface TooltipProps {
  message?: string;
  place?: PlacesType;
  icon?: JSX.Element;
  iconStyles?: string;
  iconFillDarkMode?: string;
}

const Tooltip = ({
  message,
  place,
  icon,
  iconStyles,
  iconFillDarkMode
}: TooltipProps) => {
  const divRef = useRef(null);
  const tooltipRef = useRef<TooltipRefProps | null>(null);

  const id = useMemo(() => uuid(), []);

  useEffect(() => {
    const handleResize = debounce((entries) => {
      for (const _ of entries) {
        tooltipRef.current?.close();
      }
    }, 100);

    const resizeObserver = new ResizeObserver(handleResize);
    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={divRef} className='flex items-center gap-1'>
      <span data-tooltip-id={id} className='cursor-pointer'>
        {icon ?? (
          <InfoCircle styles={iconStyles} fillDarkMode={iconFillDarkMode} />
        )}
      </span>
      <ReactTooltip
        id={id}
        place={place}
        content={message}
        className='max-w-[80vw] z-[99999]'
        ref={tooltipRef}
      />
    </div>
  );
};

export default Tooltip;
