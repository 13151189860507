import ContentWrapper from '../wrappers/ContentWrapper';
import {
  getStripeCheckoutURL,
  getSubscriptionManagementPortal
} from 'services/subscription';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateError } from 'store/reducer/setting/slice';
import {
  saveMarkerIndicatingUserOpenedStripe,
  updateLocalStorage
} from 'utils/support';
import { t } from 'i18next';
import { isRouteEmbedded } from 'utils/validation';
import { LOCAL_STORAGE, SUBSCRIPTION_PRICE_ID } from 'constants/general';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { useAppSelector } from 'store/hooks';
import { DEFAULT_TRIAL_ACCOUNT_FEATURES } from 'assets/data';
import CheckMark from 'assets/icons/CheckMark';
import { manageActiveSubscriptionSelector } from 'store/reducer/user/selectors';
import { isTrialOrPromoActive } from 'utils/subscriptions';
import { useMemo } from 'react';

export default function ManageActiveSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showCancelReasonModal, subscriptionInfo } = useAppSelector(
    manageActiveSubscriptionSelector
  );

  const trialOrPromoSubscription = useMemo(
    () => isTrialOrPromoActive(subscriptionInfo),
    [subscriptionInfo]
  );

  const navigateToStripePortal = () => {
    try {
      isRouteEmbedded(window.location.pathname) &&
        updateLocalStorage(
          LOCAL_STORAGE.REDIRECT_TO_MANAGE_SUBSCRIPTION_VIA_WEBVIEW,
          window.location.pathname
        );
      saveMarkerIndicatingUserOpenedStripe();
      getSubscriptionManagementPortal();
    } catch (error) {
      Sentry.captureException(error);
      dispatch(updateError(t('errors.couldnt_fetch_stripe_portal')));
      navigate('/error');
    }
  };

  return (
    <ContentWrapper>
      <p className='text-lg md:text-xl lg:text-2xl font-bold'>
        {trialOrPromoSubscription
          ? t('subscription_plan.sign_up_for_a_personal_plan')
          : t('manage_subscription')}
      </p>
      {trialOrPromoSubscription ? (
        <ul className='w-full px-11 sm:px-16 md:px-12 xl:px-20'>
          {DEFAULT_TRIAL_ACCOUNT_FEATURES.map((feature) => (
            <li
              key={feature}
              className='flex items-center gap-2 text-left py-1 text-xs sm:text-sm md:text-base lg:text-lg'
            >
              <CheckMark />
              {feature}
            </li>
          ))}
        </ul>
      ) : (
        <p className='text-sm md:text-lg lg:text-xl px-2'>
          {t('active_subscription_message')}
        </p>
      )}
      {trialOrPromoSubscription ? (
        <ButtonFocusBear
          onClick={() => {
            getStripeCheckoutURL(SUBSCRIPTION_PRICE_ID.PERSONAL_MONTHLY);
          }}
          title={t('add_payment_method')}
        />
      ) : (
        <ButtonFocusBear
          onClick={navigateToStripePortal}
          title={t('change_payment_method')}
          disabled={showCancelReasonModal}
        />
      )}
    </ContentWrapper>
  );
}
