import { Entitlement, TEAM_OWNER_ACTION } from 'constants/enum';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateSearchMember,
  updateSelectedMembersId,
  updateShowTeamOwnerMassUpdateModal,
  updateTeamOwnerAction
} from 'store/reducer/team/slice';
import {
  assignAdmin,
  fetchAllMembers,
  removeAdmin,
  removeMember
} from 'store/reducer/team/extra';
import {
  EMPTY_STRING,
  FIRST_TEAM_INDEX,
  MINIMUM_NUMBER_OF_MEMBER,
  TEAM_PAYMENT
} from 'constants/general';
import { toast } from 'react-toastify';
import Search from 'assets/icons/Search';
import COLOR from 'constants/color';
import {
  getRemainingTeams,
  saveMarkerIndicatingUserOpenedStripe
} from 'utils/support';
import { getSubscriptionManagementPortal } from 'services/subscription';
import { updateError } from 'store/reducer/setting/slice';
import * as Sentry from '@sentry/react';
import TrashFilled from 'assets/icons/TrashFilled';
import InviteFilled from 'assets/icons/InviteFilled';
import BatchAssignFilled from 'assets/icons/BatchAssignFilled';
import { useMemo } from 'react';

interface BtnActionProps {
  title: string;
  action: TEAM_OWNER_ACTION;
  icon: JSX.Element;
  disabled?: boolean;
}

const BtnAction = ({ title, action, icon, disabled }: BtnActionProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    areMembersLoading,
    selectedMembersId,
    selectedTeam,
    isRemovingMember
  } = useAppSelector((state) => state.team);
  const shouldDisableBtnAction = Boolean(
    areMembersLoading || isRemovingMember || disabled
  );

  const handleBtnAction = async () => {
    if (action === TEAM_OWNER_ACTION.ASSIGN_ADMIN) {
      dispatch(updateTeamOwnerAction(TEAM_OWNER_ACTION.ASSIGN_ADMIN));
      const results = await Promise.allSettled(
        selectedMembersId?.map((member_id) =>
          dispatch(
            assignAdmin({
              member_id,
              team_id: selectedTeam?.id ?? EMPTY_STRING
            })
          )
        )
      );
      const successful = results.filter(
        (result) => result.status === 'fulfilled'
      );
      if (successful?.length && selectedTeam?.id) {
        dispatch(fetchAllMembers(selectedTeam?.id));
        dispatch(updateSelectedMembersId([]));
      } else {
        toast.error(t('admin_team.could_not_assign_admin_role'));
      }
    } else if (action === TEAM_OWNER_ACTION.REMOVE_ADMIN) {
      dispatch(updateTeamOwnerAction(TEAM_OWNER_ACTION.REMOVE_ADMIN));
      const results = await Promise.allSettled(
        selectedMembersId?.map((member_id) =>
          dispatch(
            removeAdmin({
              member_id,
              team_id: selectedTeam?.id ?? EMPTY_STRING
            })
          )
        )
      );
      const successful = results.filter(
        (result) => result.status === 'fulfilled'
      );
      if (successful?.length && selectedTeam?.id) {
        dispatch(fetchAllMembers(selectedTeam?.id));
        dispatch(updateSelectedMembersId([]));
      } else {
        toast.error(t('admin_team.could_not_remove_admin_role'));
      }
    } else if (action === TEAM_OWNER_ACTION.REMOVE_MEMBER) {
      const results = await Promise.allSettled(
        selectedMembersId?.map((member_id) =>
          dispatch(
            removeMember({
              member_id,
              team_id: selectedTeam?.id ?? EMPTY_STRING
            })
          )
        )
      );
      const successful = results.filter(
        (result) => result.status === 'fulfilled'
      );
      if (successful?.length && selectedTeam?.id) {
        dispatch(fetchAllMembers(selectedTeam?.id));
        dispatch(updateSelectedMembersId([]));
      } else {
        toast.error(t('admin_team.could_not_remove_member'));
      }
    } else if (action === TEAM_OWNER_ACTION.MASS_UPDATES) {
      dispatch(updateShowTeamOwnerMassUpdateModal(true));
    } else {
      dispatch(updateTeamOwnerAction(action));
    }
  };

  return (
    <button
      disabled={shouldDisableBtnAction}
      onClick={handleBtnAction}
      className={`min-w-max flex items-center gap-1 ${
        shouldDisableBtnAction
          ? 'bg-gray-100 text-gray-500'
          : 'text-white bg-gray-600  hover:bg-gray-700 cursor-pointer'
      } py-1 px-2.5 rounded-lg text-xs md:text-sm disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:hover:bg-gray-200 focus:bg-gray-400 animate-fadeIn shadow outline-none`}
    >
      {icon}
      {title}
    </button>
  );
};

const TeamOwnerActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    team: {
      selectedMembersId,
      searchMember,
      teamMembers: { members },
      teams,
      areMembersLoading,
      selectedTeam
    },
    user: {
      subscriptionInfo: { activeEntitlements }
    }
  } = useAppSelector((state) => state);
  const shouldAllowMassUpdate =
    selectedMembersId?.length > MINIMUM_NUMBER_OF_MEMBER;
  const remainingLicenses = useMemo(
    () => getRemainingTeams(teams[FIRST_TEAM_INDEX] ?? null),
    [members]
  );
  const stripe_payment = selectedTeam?.payment_type === TEAM_PAYMENT.STRIPE;

  return (
    <div className='w-full flex items-end justify-between flex-wrap gap-y-4 gap-x-8 py-3'>
      <div className='w-fit flex items-end gap-4 flex-wrap'>
        <div className='w-fit h-fit relative shadow-sm'>
          <Search
            fill={COLOR.WHITE}
            styles='bg-gray-700 w-6 h-full absolute top-1/2 -translate-y-1/2 left-0 rounded-r-md -scale-x-100 px-1'
          />
          <input
            disabled={areMembersLoading}
            className='min-w-[300px] pl-8 pr-2 py-1 outline-none rounded-md border-2 border-gray-300 focus:bg-gray-50 text-xs md:text-sm'
            placeholder={t('search')}
            value={searchMember}
            onChange={({ target: { value } }) =>
              dispatch(updateSearchMember(value))
            }
          />
        </div>
        <div className='w-fit flex items-end gap-3 flex-wrap'>
          {activeEntitlements?.includes(Entitlement.TEAM_OWNER) ? (
            <button
              disabled={areMembersLoading}
              onClick={() => {
                try {
                  saveMarkerIndicatingUserOpenedStripe();
                  getSubscriptionManagementPortal();
                } catch (error) {
                  Sentry.captureException(error);
                  dispatch(
                    updateError(t('errors.couldnt_fetch_stripe_portal'))
                  );
                  navigate('/error');
                }
              }}
              className='flex items-center gap-1 text-white bg-blue-600  hover:bg-blue-600/80 cursor-pointer py-1.5 px-2.5 rounded-lg text-xs md:text-sm disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:hover:bg-gray-200 focus:bg-blue-400 animate-fadeIn shadow truncate'
            >
              {t('manage_subscription')}
            </button>
          ) : null}
          {!areMembersLoading && (
            <p className='text-sm 2x:text-base text-white bg-gray-600 px-2 py-0.5 rounded-lg animate-fadeIn'>
              {stripe_payment
                ? t('admin_team.total_number_of_members', {
                    total: members.length
                  })
                : t('admin_team.total_number_of_licenses', {
                    active: members.length,
                    remaining: remainingLicenses
                  })}
            </p>
          )}
        </div>
      </div>
      <div className='w-fit flex items-center flex-wrap gap-y-4 gap-x-8'>
        {selectedMembersId?.length ? (
          <BtnAction
            title={t('admin_team.remove_member')}
            action={TEAM_OWNER_ACTION.REMOVE_MEMBER}
            icon={<TrashFilled />}
          />
        ) : null}
        {shouldAllowMassUpdate ? (
          <BtnAction
            title={t('admin_team.mass_updates')}
            action={TEAM_OWNER_ACTION.MASS_UPDATES}
            icon={<BatchAssignFilled />}
          />
        ) : null}
        <BtnAction
          title={t('admin_team.invite_member')}
          action={TEAM_OWNER_ACTION.INVITE_MEMBER}
          icon={<InviteFilled />}
          disabled={stripe_payment ? false : !remainingLicenses}
        />
      </div>
    </div>
  );
};

export default TeamOwnerActions;
