import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import ChoicesModal from '../ChoicesModal';
import { FLAG, Mode, THEME_OPTION } from 'constants/enum';
import URLModal from '../URLModal';
import {
  ACTIVITY_TYPES,
  EMPTY_STRING,
  LOG_SUMMARY_OPTION,
  MAXIMUM_ALLOWED_MINUTES,
  MODAL_TYPES,
  MORE_OPTION_TAB,
  NUMBERS,
  PLATFORMS,
  SCREEN
} from 'constants/general';
import {
  updateIsShowMoreOptionsOpened,
  updateActivityFromMoreOptions
} from 'store/reducer/setting/slice';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import {
  convertToMinutes,
  convertToSeconds,
  getRoutineActivitiesOfCurrentSettings,
  getValidNumber,
  validateDuration
} from 'utils/support';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateIsYouTubeURLMetadataUpdating } from 'store/reducer/cache/slice';
import { fetchYoutubeURLMetadata } from 'store/reducer/cache/extra';
import YouTubeVideos from './YouTubeVideos';
import AllowedApps from './AllowedApps';
import LogData from './LogData';
import Advanced from './Advanced';
import ImagesModal from '../ImagesModal';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalHeader from 'components/shared/ModalHeader';
import {
  updateIsMoreOptionEdited,
  updateMoreOptionAreLogQuestionsInvalid,
  updateMoreOptionDuration,
  updateMoreOptionLogSummaryType,
  updateMoreOptionName
} from 'store/reducer/modal/slice';
import Timing from './Timing';
import { ATTRIB } from 'constants/test';
import _ from 'lodash';
import { SettingsPageContext } from 'components/setting-generator';
import { Video_Metadata } from 'interfaces/settingInterface';
import Warning from 'assets/icons/Warning';
import moment from 'moment';
import CheckListsModal from './Settings/CheckListModal';
import Settings from './Settings';
import Accordion from 'components/common/Accordion';
import Instructions from './Instructions';
import { updateTutorials } from 'store/reducer/course/slice';
import { DEFAULT_MORE_OPTION_TABS } from 'assets/data';
import { t } from 'i18next';
import NerdFace from 'assets/icons/NerdFace';
import SmilingFaceWithSunglasses from 'assets/icons/SmilingFaceWithSunglasses';
import Tags from './Tags';
import { themeSelector } from 'store/reducer/setting/selectors';

export const DEFAULT_MORE_OPTIONS_TABS_CONTENTS = {
  [MORE_OPTION_TAB.TIMING]: <Timing />,
  [MORE_OPTION_TAB.YOUTUBE_VIDEOS]: <YouTubeVideos />,
  [MORE_OPTION_TAB.ALLOWED_APPS]: <AllowedApps />,
  [MORE_OPTION_TAB.LOG_DATA]: <LogData />,
  [MORE_OPTION_TAB.SETTINGS]: <Settings />,
  [MORE_OPTION_TAB.ADVANCED]: <Advanced />,
  [MORE_OPTION_TAB.INSTRUCTIONS]: <Instructions />
};

interface MoreOptionsTab {
  title: string;
  test_id: string;
}

const Wrapper = ({ children }: PropsWithChildren<object>) => {
  const themeMode = useAppSelector(themeSelector);
  return (
    <div
      className={`relative top-[15%] -translate-y-[15%] m-auto w-[90%] h-fit flex flex-col gap-2 pb-1 shadow-lg rounded-md ${
        themeMode === THEME_OPTION.DARK ? 'bg-gray-700' : 'bg-white'
      }`}
    >
      {children}
    </div>
  );
};

const ModalContentWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit flex flex-col gap-10 sm:gap-6 px-5 max-h-[80vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400'>
    {children}
  </div>
);

const ModalContentHeaderWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='flex flex-col sm:flex-row items-center gap-2 relative'>
    {children}
  </div>
);

const ModalActions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    modal: {
      moreOption: {
        activity,
        showYouTubeURLModal,
        showAllowedURLModal,
        areLogQuestionsInvalid,
        showChoicesModal,
        isMoreOptionEdited
      }
    },
    setting: {
      moreOptions: { type, position },
      currentSettings,
      mode,
      activityLibrary
    },
    course: { tutorials }
  } = useAppSelector((state) => state);

  useEffect(() => {
    checkOptionsChanges();
  }, [activity]);

  useEffect(() => {
    validateLogQuestionsChanges();
  }, [showChoicesModal]);

  const isDurationValid =
    activity?.duration_seconds &&
    activity?.duration_seconds <= convertToSeconds(MAXIMUM_ALLOWED_MINUTES);
  const isActivityNameValid = Boolean(activity?.name);
  const isCutoffTimeValid = moment(
    activity.cutoff_time_for_doing_activity,
    'HH:mm'
  ).isBefore(
    moment(
      currentSettings.cutoff_time_for_non_high_priority_activities,
      'HH:mm'
    )
  );

  const isSavingNotAllowed =
    showYouTubeURLModal ||
    showAllowedURLModal ||
    showChoicesModal ||
    areLogQuestionsInvalid ||
    !isDurationValid ||
    !isActivityNameValid ||
    isCutoffTimeValid;

  const handleSave = () => {
    dispatch(
      updateActivityFromMoreOptions({ type, position, value: activity })
    );
    dispatch(updateIsShowMoreOptionsOpened(false));

    dispatch(
      updateTutorials(
        tutorials.filter((tutorial) => tutorial.id !== activity.tutorial)
      )
    );
  };

  const validateLogQuestionsChanges = () => {
    const questions = activity?.log_quantity_questions ?? [];
    const areQuestionsInvalid =
      questions.filter((item) => item.question === EMPTY_STRING).length >
      NUMBERS.ZERO;
    dispatch(
      updateMoreOptionAreLogQuestionsInvalid(
        questions.length ? areQuestionsInvalid : false
      )
    );
  };

  const checkOptionsChanges = () => {
    const routineActivities =
      mode === Mode.LIBRARY
        ? activityLibrary
        : getRoutineActivitiesOfCurrentSettings(type, currentSettings);
    const isEdited = !_.isEqual(activity, routineActivities[position]);
    dispatch(updateIsMoreOptionEdited(isEdited));
  };

  return isMoreOptionEdited ? (
    <button
      data-test={ATTRIB.TEST.SAVE_MORE_OPTIONS}
      disabled={isSavingNotAllowed}
      onClick={handleSave}
      className={`w-fit h-fit text-sm sm:text-base font-medium px-4 md:px-7 py-1 rounded-md self-end ${
        isSavingNotAllowed
          ? 'text-gray-500 bg-transparent border-2 border-gray-500 cursor-not-allowed'
          : 'text-white bg-blue-600 hover:bg-blue-800 cursor-pointer transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300'
      } mr-6 my-1.5`}
    >
      {t('save')}
    </button>
  ) : (
    <></>
  );
};

const ModalContentHeaderHabitName = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    modal: {
      moreOption: { activity }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const isActivityNameValid = Boolean(activity?.name);

  const doesActivityUseTimer = Boolean(!activity?.completion_requirements);
  return (
    <div
      className={`w-full ${
        doesActivityUseTimer && 'md:w-3/4 lg:w-4/5 xl:w-5/6'
      } h-fit flex flex-col justify-center`}
    >
      <h6 className='text-xs pb-0.5 font-medium text-left'>{t('habit')}</h6>
      <input
        className={`py-2 px-3 bg-gray-200 rounded text-sm font-medium outline-none text-black ${
          isActivityNameValid ? 'border-none' : 'border border-red-400'
        } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-gray-100'}`}
        type='text'
        value={activity?.name ?? ''}
        onChange={({
          target: { value }
        }: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(updateMoreOptionName(value));
        }}
        placeholder={t('placeholder.reading')}
      />
    </div>
  );
};

const ModalContentHeaderHabitDuration = () => {
  const dispatch = useAppDispatch();
  const {
    modal: {
      moreOption: { activity }
    },
    setting: { themeMode }
  } = useAppSelector((state) => state);
  const [second, setSecond] = useState(
    String((activity?.duration_seconds ?? NUMBERS.ZERO) % NUMBERS.SIXTY)
  );

  const handleInputMinuteDuration = useCallback(
    (value: string) => {
      const minutes = convertToMinutes(
        getValidNumber(second),
        getValidNumber(value)
      );
      const total_duration = convertToSeconds(minutes);
      validateDuration(total_duration);
      dispatch(updateMoreOptionDuration(total_duration));
    },
    [activity?.duration_seconds, second]
  );

  const handleInputSecondDuration = useCallback(
    (value) => {
      const total_duration = convertToSeconds(
        convertToMinutes(activity?.duration_seconds ?? NUMBERS.ZERO),
        getValidNumber(value)
      );
      validateDuration(total_duration);
      dispatch(updateMoreOptionDuration(total_duration));
      setSecond((total_duration % NUMBERS.SIXTY)?.toString());
    },
    [activity?.duration_seconds]
  );

  const isDurationValid = Boolean(
    (activity?.duration_seconds ?? NUMBERS.ZERO) <=
      convertToSeconds(MAXIMUM_ALLOWED_MINUTES)
  );

  return (
    <div className='w-full md:w-1/4 lg:w-1/5 xl:w-1/6 h-fit flex items-end gap-2'>
      <div className='w-1/2 flex flex-col relative'>
        <div className='text-xs pb-0.5 font-medium '>{t('duration')}</div>
        <input
          className={`w-full py-2 px-3 bg-gray-200 rounded text-sm font-medium outline-none text-center text-black ${
            isDurationValid ? 'border-none' : 'border border-red-400'
          } ${themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-gray-100'}`}
          type='number'
          value={String(convertToMinutes(activity.duration_seconds))}
          onChange={({
            target: { value }
          }: React.ChangeEvent<HTMLInputElement>) => {
            handleInputMinuteDuration(value);
          }}
          step={1}
          min={1}
          placeholder='10'
        />
        <div className='absolute -bottom-3 lg:-bottom-4 right-0 text-[10px] lg:text-xs 2xl:text-xs font-medium self-end'>
          {t('minutes')}
        </div>
      </div>
      <div className='w-1/2 relative'>
        <input
          className={`w-full py-2 px-3 bg-gray-200 rounded text-sm font-medium outline-none text-center text-black ${
            themeMode === THEME_OPTION.DARK ? 'bg-gray-400' : 'bg-gray-100'
          } ${isDurationValid ? 'border-none' : 'border border-red-400'} `}
          type='number'
          value={second}
          onChange={({
            target: { value }
          }: React.ChangeEvent<HTMLInputElement>) => {
            handleInputSecondDuration(value);
          }}
          step={NUMBERS.ONE}
          min={NUMBERS.ZERO}
          placeholder='30'
        />
        <div className='absolute -bottom-3 lg:-bottom-4 right-0 text-[10px] lg:text-xs 2xl:text-xs font-medium'>
          {t('seconds')}
        </div>
      </div>
    </div>
  );
};

const ModalContentHeader = () => {
  const {
    modal: {
      moreOption: { activity }
    },
    cache: {
      youtube_urls_metadata: { videos_metadata }
    },
    setting: { flags, platform, customRoutine }
  } = useAppSelector((state) => state);
  const doesActivityUseTimer = Boolean(!activity?.completion_requirements);
  let found_duration;
  const isMetaDataDurationLonger = videos_metadata.some((metadata) => {
    const duration =
      metadata.duration?.split(':').length === 2
        ? `00:${metadata.duration}`
        : metadata.duration;

    if (
      activity?.video_urls?.includes(metadata.video_url) &&
      moment.duration(duration).asSeconds() < activity?.duration_seconds
    ) {
      found_duration = moment.duration(duration).asSeconds();
      return true;
    }
    return false;
  });
  const isGeekMode = flags.includes(FLAG.GEEK_MODE);
  return (
    <ModalContentHeaderWrapper>
      <ModalContentHeaderHabitName />
      {doesActivityUseTimer && <ModalContentHeaderHabitDuration />}
      {isMetaDataDurationLonger && (
        <div
          className={`absolute -bottom-11 right-0 text-xs bg-orange-200 rounded px-2 py-1 flex items-end gap-1 z-10 text-black`}
        >
          <Warning />
          <p>
            {t('activity_duration_warning', {
              video_duration: found_duration,
              activity_duration: activity.duration_seconds
            })}
          </p>
        </div>
      )}
      {platform !== PLATFORMS.WEB && (
        <span className='absolute md:relative top-1 md:top-0 -right-4 md:right-0 w-fit px-4'>
          {!customRoutine &&
            (isGeekMode ? <NerdFace /> : <SmilingFaceWithSunglasses />)}
        </span>
      )}
    </ModalContentHeaderWrapper>
  );
};

const DesktopViewModalContentBody = ({
  more_options_tabs
}: {
  more_options_tabs: MoreOptionsTab[];
}) => {
  const dispatch = useAppDispatch();
  const {
    setting: { themeMode },
    modal: {
      moreOption: { activity }
    }
  } = useAppSelector((state) => state);

  useEffect(() => {
    const choices = activity?.choices ?? [];
    if (choices.length) {
      dispatch(updateMoreOptionLogSummaryType(LOG_SUMMARY_OPTION.SUMMATION));
    }
  }, [activity.choices]);

  return (
    <Tabs
      className='w-full h-fit max-h-[60vh] pb-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300'
      selectedTabClassName={`${
        themeMode === THEME_OPTION.DARK
          ? 'bg-gray-800 border-b-none'
          : 'bg-white border-t border-gray-200'
      } rounded-t`}
    >
      <TabList className='text-sm font-medium my-2 border-b border-gray-200'>
        {more_options_tabs.map((tab) => (
          <Tab key={tab.test_id} data-test={tab.test_id}>
            {t(tab.title)}
          </Tab>
        ))}
      </TabList>
      {more_options_tabs.map((tab, idx) => (
        <TabPanel key={`${tab.test_id}${idx}`}>
          {DEFAULT_MORE_OPTIONS_TABS_CONTENTS[tab.title]}
        </TabPanel>
      ))}
      <TabPanel>
        <Timing />
      </TabPanel>
    </Tabs>
  );
};

const ModalContent = () => {
  const {
    moreOptions: { type },
    platform,
    flags,
    mode
  } = useAppSelector((state) => state.setting);
  const settingPageContext = useContext(SettingsPageContext);
  const shouldActiveMobileView =
    (settingPageContext.width ?? SCREEN.WIDTH.SMALL) <= SCREEN.WIDTH.SMALL;

  const more_options_tabs = DEFAULT_MORE_OPTION_TABS.filter((tab) => {
    if (flags.includes(FLAG.GEEK_MODE)) {
      return type !== ACTIVITY_TYPES.BREAKING && platform !== PLATFORMS.IOS
        ? true
        : tab.title !== MORE_OPTION_TAB.ALLOWED_APPS;
    }
    return platform === PLATFORMS.WEB
      ? true
      : tab.title === MORE_OPTION_TAB.YOUTUBE_VIDEOS;
  });

  return (
    <ModalContentWrapper>
      <ModalContentHeader />
      {shouldActiveMobileView ? (
        <div className='w-full h-fit flex flex-col gap-4 py-2'>
          {/* MobileViewModalContentBody */}
          {more_options_tabs.map((tab, idx) => (
            <Accordion key={`${idx}-${tab.test_id}`} title={t(tab.title)}>
              {DEFAULT_MORE_OPTIONS_TABS_CONTENTS[tab.title]}
            </Accordion>
          ))}
        </div>
      ) : (
        <DesktopViewModalContentBody more_options_tabs={more_options_tabs} />
      )}
      {mode === Mode.ROUTINE && <Tags />}
    </ModalContentWrapper>
  );
};

const MoreOptions = () => {
  const dispatch = useAppDispatch();
  const {
    cache: {
      youtube_urls_metadata: { videos_metadata }
    },
    modal: {
      moreOption: {
        activity,
        showYouTubeURLModal,
        showAllowedURLModal,
        showChoicesModal,
        showImageURLModal,
        showCheckListModal
      },
      choice: { showChoicesYouTubeURLModal }
    }
  } = useAppSelector((state) => state);

  useEffect(() => {
    const allowedURLsWithMetaData = videos_metadata?.filter(
      (metadata: Video_Metadata) =>
        !activity?.video_urls?.includes(metadata.video_url)
    );
    if (videos_metadata.length === 0 || allowedURLsWithMetaData.length !== 0) {
      dispatch(updateIsYouTubeURLMetadataUpdating(true));
      dispatch(fetchYoutubeURLMetadata(activity?.video_urls ?? []));
    }
  }, []);

  return (
    <>
      <ModalOverlay zIndex='z-[60]'>
        <Wrapper>
          <ModalHeader title={activity?.name ?? t('more_options')} />
          <ModalContent />
          <ModalActions />
        </Wrapper>
      </ModalOverlay>
      {showYouTubeURLModal && <URLModal modalType={MODAL_TYPES.YOUTUBE_URLS} />}
      {showAllowedURLModal && <URLModal modalType={MODAL_TYPES.ALLOWED_URLS} />}
      {showChoicesYouTubeURLModal && (
        <URLModal modalType={MODAL_TYPES.CHOICE_YOUTUBE} />
      )}
      {showCheckListModal && (
        <CheckListsModal modalType={MODAL_TYPES.CHECK_LIST} />
      )}
      {showChoicesModal && <ChoicesModal />}
      {showImageURLModal && <ImagesModal />}
    </>
  );
};

export default MoreOptions;
