import COLOR from 'constants/color';
import { IconProps } from 'interfaces';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { isDarkModeActivated } from 'utils/validation';

const AddToList = ({
  fill = COLOR.GRAY_800,
  styles = 'w-5 h-auto'
}: IconProps) => {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='addToListIconTitle'
      stroke='#000000'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      color={
        !isDarkModeActivated(useAppSelector(themeSelector)) ? fill : COLOR.WHITE
      }
      className={styles}
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path d='M6 10H18'></path> <path d='M6 6H18'></path>
        <path d='M6 14H10'></path> <path d='M14 16H18'></path>
        <path d='M16 14L16 18'></path> <path d='M6 18H10'></path>
      </g>
    </svg>
  );
};

export default AddToList;
