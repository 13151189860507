import {
  CONSENT_OPTION,
  DEFAULT_DURATION,
  DEFAULT_MAC_APPS_IF_ALLOWED_URLS_SET,
  DEFAULT_TODO_TASK_DURATION,
  DEFAULT_TO_DO_TASK_EMOJI,
  DEFAULT_WINDOWS_APPS_IF_ALLOWED_URLS_SET,
  EISENHOWER_QUADRANT,
  EMPTY_STRING,
  INITIAL_RATING,
  LOG_SUMMARY_OPTION,
  MORE_OPTION_TAB,
  ROUTINE_DESTINATION_POSITION,
  TO_DO_SUB_TASK_WHERE
} from 'constants/general';
import {
  HabitPackFormat,
  LanguageCodes,
  MarketplaceRequestStatus,
  TO_DO_STATUS
} from 'constants/enum';
import { NewTaskFormValues } from 'interfaces';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { SettingsType } from 'interfaces/settingInterface';
import { ChoiceType } from 'interfaces/commonInterface';
import { ATTRIB } from 'constants/test';
import { t } from 'i18next';

const morningActivitySequenceId = uuid();
const breakingActivitySequenceId = uuid();
const eveningActivitySequenceId = uuid();
const default_allowed_urls: string[] = [];
const default_log_quantity = {
  allowed: true,
  not_allowed: false
};
const default_log_quantity_question = '';
const default_choices: ChoiceType[] = [];

export const DEFAULT_SETTINGS = {
  shutdown_time: '20:30',
  startup_time: '05:15',
  cutoff_time_for_non_high_priority_activities: '',
  morning_activities: [
    {
      id: uuid(),
      activity_sequence_id: morningActivitySequenceId,
      name: 'Yoga',
      duration_seconds: 300,
      log_quantity: default_log_quantity.not_allowed,
      video_urls: [
        'https://www.youtube.com/watch?v=nz5MCv0sFDA',
        'https://www.youtube.com/watch?v=oexYiIy75V8'
      ],
      log_summary_type: LOG_SUMMARY_OPTION.AVERAGE,
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    },
    {
      id: uuid(),
      activity_sequence_id: morningActivitySequenceId,
      name: 'Journaling',
      video_urls: [],
      duration_seconds: 300,
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    },
    {
      id: uuid(),
      activity_sequence_id: morningActivitySequenceId,
      name: 'Deep breathing',
      duration_seconds: 180,
      log_quantity: default_log_quantity.not_allowed,
      video_urls: ['https://youtu.be/HhMXYsk_Pvo'],
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    }
  ],
  break_after_minutes: 15,
  break_activities: [
    {
      id: uuid(),
      activity_sequence_id: breakingActivitySequenceId,
      name: 'Micro-workout',
      duration_seconds: 30,
      include_in_every_break: true,
      video_urls: [],
      choice_type: 'random',
      choices: [
        {
          id: uuid(),
          name: 'Pushups',
          video_urls: [
            'https://www.youtube.com/watch?v=StXac04arIc',
            'https://www.youtube.com/watch?v=dOY-VkXbJCY'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Situps',
          video_urls: ['https://www.youtube.com/watch?v=ueSWOFxOZSc'],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Squats',
          video_urls: [
            'https://www.youtube.com/watch?v=EL1wNBsEHiY',
            'https://www.youtube.com/watch?v=R0Ut6nldt9g',
            'https://www.youtube.com/watch?v=VciI0zL6TAo'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Lunges',
          video_urls: [
            'https://www.youtube.com/watch?v=AZWPE5D1hHQ',
            'https://www.youtube.com/watch?v=9StkRofe0HE',
            'https://www.youtube.com/watch?v=aLgQiruL6nQ'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Stretching',
          video_urls: [
            'https://www.youtube.com/watch?v=s5V_sVvagrM',
            'https://www.youtube.com/watch?v=_BJFBz-NlFU',
            'https://www.youtube.com/watch?v=RH3La6XK1u4',
            'https://www.youtube.com/watch?v=ZYmJTpwQrCU',
            'https://www.youtube.com/watch?v=vetZTx-76So',
            'https://www.youtube.com/watch?v=pSXVJwcH-eY',
            'https://www.youtube.com/watch?v=XAyuDBV5fYM',
            'https://www.youtube.com/watch?v=EKeCZoCUs2M',
            'https://www.youtube.com/watch?v=cHBZbYlR490'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Move Your Body',
          video_urls: [
            'https://www.youtube.com/watch?v=2LVjYfxwz4c',
            'https://www.youtube.com/watch?v=GapAX8tOe2s'
          ],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Abs',
          video_urls: [
            'https://www.youtube.com/watch?v=cymGONhOFuQ',
            'https://www.youtube.com/watch?v=f97P6JDr1c8'
          ],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Dips',
          video_urls: ['https://www.youtube.com/watch?v=C9r55uFuQhU'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Legs',
          video_urls: [
            'https://www.youtube.com/watch?v=ysRa2bc4FNA',
            'https://www.youtube.com/watch?v=OQZkH6bS8sk',
            'https://www.youtube.com/watch?v=v2xJyrOLAgo',
            'https://www.youtube.com/watch?v=2jurEGsCPpU'
          ],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        }
      ],
      log_quantity: default_log_quantity.not_allowed,
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls,
      is_office_friendly: false
    },
    {
      id: uuid(),
      activity_sequence_id: breakingActivitySequenceId,
      name: 'Deep breathing',
      duration_seconds: 30,
      is_office_friendly: true,
      include_in_every_break: true,
      video_urls: ['https://youtu.be/HhMXYsk_Pvo'],
      log_quantity: default_log_quantity.not_allowed,
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls,
      choice_type: 'random',
      choices: default_choices
    },
    {
      id: uuid(),
      activity_sequence_id: breakingActivitySequenceId,
      name: 'Office Exercises',
      duration_seconds: 55,
      log_quantity: default_log_quantity.not_allowed,
      video_urls: [],
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls,
      include_in_every_break: true,
      is_office_friendly: true,
      choice_type: 'random',
      choices: [
        {
          id: uuid(),
          name: 'Upper Body Twist',
          video_urls: ['https://www.youtube.com/watch?v=0V6jabZ7vT0'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Neck Rotation',
          video_urls: ['https://www.youtube.com/watch?v=swypmUBr-ak'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Hip Marching',
          video_urls: ['https://www.youtube.com/watch?v=xq1VYNb1qmg'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Chest Stretch',
          video_urls: ['https://www.youtube.com/watch?v=GJP2_2do2nA'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Arm Raises',
          video_urls: ['https://www.youtube.com/watch?v=Iqel5RkbVNw'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Rest Eyes',
          video_urls: ['https://www.youtube.com/watch?v=5hNoCxonFbc'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        }
      ]
    }
  ],
  evening_activities: [
    {
      id: uuid(),
      activity_sequence_id: eveningActivitySequenceId,
      name: 'Tidy up desk',
      duration_seconds: 180,
      video_urls: [],
      log_quantity: default_log_quantity.not_allowed
    },
    {
      id: uuid(),
      activity_sequence_id: eveningActivitySequenceId,
      name: 'Journal about day',
      duration_seconds: 300,
      video_urls: [],
      log_quantity: default_log_quantity.not_allowed
    },
    {
      id: uuid(),
      activity_sequence_id: eveningActivitySequenceId,
      name: 'Plan to-do list and schedule for tomorrow',
      duration_seconds: 420,
      video_urls: []
    }
  ]
};

export const DEFAULT_FREESTYLE_PACK = {
  shutdown_time: '03:59',
  startup_time: '04:01',
  cutoff_time_for_non_high_priority_activities: '',
  break_after_minutes: 15,
  break_activities: [
    {
      id: uuid(),
      activity_sequence_id: breakingActivitySequenceId,
      name: 'Micro-workout',
      duration_seconds: 30,
      include_in_every_break: true,
      video_urls: [],
      choice_type: 'random',
      choices: [
        {
          id: uuid(),
          name: 'Pushups',
          video_urls: [
            'https://www.youtube.com/watch?v=StXac04arIc',
            'https://www.youtube.com/watch?v=dOY-VkXbJCY'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Situps',
          video_urls: ['https://www.youtube.com/watch?v=ueSWOFxOZSc'],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Squats',
          video_urls: [
            'https://www.youtube.com/watch?v=EL1wNBsEHiY',
            'https://www.youtube.com/watch?v=R0Ut6nldt9g',
            'https://www.youtube.com/watch?v=VciI0zL6TAo'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Lunges',
          video_urls: [
            'https://www.youtube.com/watch?v=AZWPE5D1hHQ',
            'https://www.youtube.com/watch?v=9StkRofe0HE',
            'https://www.youtube.com/watch?v=aLgQiruL6nQ'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Stretching',
          video_urls: [
            'https://www.youtube.com/watch?v=s5V_sVvagrM',
            'https://www.youtube.com/watch?v=_BJFBz-NlFU',
            'https://www.youtube.com/watch?v=RH3La6XK1u4',
            'https://www.youtube.com/watch?v=ZYmJTpwQrCU',
            'https://www.youtube.com/watch?v=vetZTx-76So',
            'https://www.youtube.com/watch?v=pSXVJwcH-eY',
            'https://www.youtube.com/watch?v=XAyuDBV5fYM',
            'https://www.youtube.com/watch?v=EKeCZoCUs2M',
            'https://www.youtube.com/watch?v=cHBZbYlR490'
          ],
          log_quantity: default_log_quantity.allowed
        },
        {
          id: uuid(),
          name: 'Move Your Body',
          video_urls: [
            'https://www.youtube.com/watch?v=2LVjYfxwz4c',
            'https://www.youtube.com/watch?v=GapAX8tOe2s'
          ],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Abs',
          video_urls: [
            'https://www.youtube.com/watch?v=cymGONhOFuQ',
            'https://www.youtube.com/watch?v=f97P6JDr1c8'
          ],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Dips',
          video_urls: ['https://www.youtube.com/watch?v=C9r55uFuQhU'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Legs',
          video_urls: [
            'https://www.youtube.com/watch?v=ysRa2bc4FNA',
            'https://www.youtube.com/watch?v=OQZkH6bS8sk',
            'https://www.youtube.com/watch?v=v2xJyrOLAgo',
            'https://www.youtube.com/watch?v=2jurEGsCPpU'
          ],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        }
      ],
      log_quantity: default_log_quantity.not_allowed,
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls,
      is_office_friendly: false
    },
    {
      id: uuid(),
      activity_sequence_id: breakingActivitySequenceId,
      name: 'Deep breathing',
      duration_seconds: 30,
      is_office_friendly: true,
      include_in_every_break: true,
      video_urls: ['https://youtu.be/HhMXYsk_Pvo'],
      log_quantity: default_log_quantity.not_allowed,
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls,
      choice_type: 'random',
      choices: default_choices
    },
    {
      id: uuid(),
      activity_sequence_id: breakingActivitySequenceId,
      name: 'Office Exercises',
      duration_seconds: 55,
      log_quantity: default_log_quantity.not_allowed,
      video_urls: [],
      log_quantity_question: default_log_quantity_question,
      allowed_apps: [],
      allowed_urls: default_allowed_urls,
      include_in_every_break: true,
      is_office_friendly: true,
      choice_type: 'random',
      choices: [
        {
          id: uuid(),
          name: 'Upper Body Twist',
          video_urls: ['https://www.youtube.com/watch?v=0V6jabZ7vT0'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Neck Rotation',
          video_urls: ['https://www.youtube.com/watch?v=swypmUBr-ak'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Hip Marching',
          video_urls: ['https://www.youtube.com/watch?v=xq1VYNb1qmg'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Chest Stretch',
          video_urls: ['https://www.youtube.com/watch?v=GJP2_2do2nA'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Arm Raises',
          video_urls: ['https://www.youtube.com/watch?v=Iqel5RkbVNw'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_question: default_log_quantity_question
        },
        {
          id: uuid(),
          name: 'Rest Eyes',
          video_urls: ['https://www.youtube.com/watch?v=5hNoCxonFbc'],
          log_quantity: default_log_quantity.allowed,
          log_quantity_questions: []
        }
      ]
    }
  ],
  morning_activities: [],
  evening_activities: []
};

export const DEFAULT_ROUTINE_HABIT_PACK: SettingsType = {
  id: uuid(),
  pack_type: HabitPackFormat.ROUTINE,
  pack_name: '',
  description: '',
  description_video_url: '',
  welcome_message: '',
  welcome_video_url: '',
  marketplace_approval_status: false,
  is_featured: false,
  featured_for_onboarding: false,
  language: LanguageCodes.EN,
  morning_activities: [
    {
      id: uuid(),
      name: 'Journalling',
      video_urls: [],
      duration_seconds: 300,
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    },
    {
      id: uuid(),
      name: 'Deep breathing',
      duration_seconds: 180,
      log_quantity: default_log_quantity.not_allowed,
      video_urls: ['https://www.youtube.com/watch?v=36mnXAQGRzc'],
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    }
  ],
  evening_activities: [
    {
      id: uuid(),
      name: 'Tidy up desk',
      duration_seconds: 180,
      video_urls: [],
      log_quantity: default_log_quantity.not_allowed,
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    },
    {
      id: uuid(),
      name: 'Journal about day',
      duration_seconds: 300,
      video_urls: [],
      log_quantity: default_log_quantity.not_allowed,
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    }
  ],
  break_activities: [
    {
      id: uuid(),
      name: 'Deep breathing',
      duration_seconds: 30,
      is_office_friendly: true,
      include_in_every_break: true,
      video_urls: ['https://www.youtube.com/watch?v=36mnXAQGRzc'],
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    },
    {
      id: uuid(),
      name: 'Stretching',
      duration_seconds: 45,
      is_office_friendly: true,
      include_in_every_break: true,
      video_urls: ['https://www.youtube.com/watch?v=s5V_sVvagrM'],
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    }
  ]
};

export const DEFAULT_STANDALONE_PACK: SettingsType = {
  id: uuid(),
  pack_name: '',
  pack_type: HabitPackFormat.STANDALONE,
  description: '',
  description_video_url: '',
  welcome_message: '',
  welcome_video_url: '',
  marketplace_approval_status: false,
  is_featured: false,
  featured_for_onboarding: false,
  language: LanguageCodes.EN,
  standalone_activities: [
    {
      id: uuid(),
      name: 'Deep breathing',
      duration_seconds: 60,
      is_office_friendly: true,
      include_in_every_break: true,
      video_urls: ['https://www.youtube.com/watch?v=36mnXAQGRzc'],
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    },
    {
      id: uuid(),
      name: 'Stretching',
      duration_seconds: 120,
      is_office_friendly: true,
      include_in_every_break: true,
      video_urls: ['https://www.youtube.com/watch?v=s5V_sVvagrM'],
      log_summary_type: 'SUM',
      log_quantity_questions: [],
      allowed_apps: [],
      allowed_urls: default_allowed_urls
    }
  ]
};

export const DEFAULT_LOG_QUESTION = (isCompetencyBasedActivity: boolean) => ({
  id: uuid(),
  question: '',
  min_value: isCompetencyBasedActivity ? 1 : 0,
  min_value_description: '',
  max_value: isCompetencyBasedActivity ? 10 : 0,
  max_value_description: ''
});

export const DEFAULT_MORE_OPTION_ACTIVITY = {
  id: '',
  name: '',
  duration_seconds: DEFAULT_DURATION.MORE_OPTION,
  video_urls: [],
  choices: default_choices
};

export const DEFAULT_CHOICE_ITEM = () => ({
  id: uuid(),
  name: '',
  video_urls: [],
  log_quantity: default_log_quantity.allowed,
  log_quantity_question: default_log_quantity_question,
  completion_requirements: '',
  duration_seconds: 60,
  log_summary_type: 'SUM'
});

export const DEFAULT_COMPETENCY_CHOICE_ITEM = () => ({
  id: uuid(),
  name: '',
  video_urls: [],
  log_quantity: default_log_quantity.allowed,
  log_quantity_question: default_log_quantity_question,
  completion_requirements: '',
  competency_level: 0,
  duration_seconds: 60
});

export const DEFAULT_FOCUS_TEMPLATE = {
  id: uuid(),
  name: '',
  description: '',
  description_video_url: '',
  welcome_message: '',
  welcome_video_url: '',
  allowed_urls: DEFAULT_MAC_APPS_IF_ALLOWED_URLS_SET,
  allowed_apps: DEFAULT_WINDOWS_APPS_IF_ALLOWED_URLS_SET,
  marketplace_approval_status: false,
  marketplace_request: MarketplaceRequestStatus.UNREQUESTED,
  language: 'en'
};

export const INIT_NEW_HABIT = () => ({
  id: uuid(),
  activity_sequence_id: '',
  name: '',
  duration_seconds: 300,
  video_urls: [],
  log_summary_type: 'SUM',
  log_quantity_question: default_log_quantity_question,
  allowed_apps: [],
  allowed_urls: default_allowed_urls
});

export const DEFAULT_USER_DETAILS = {
  id: '',
  name: '',
  email: '',
  focus_modes: [],
  metadata: { profile_image: { url: '', file_path: '' }, description: '' },
  current_activity_sequence_id: null,
  current_activity_assigned_at: null,
  timezone: '',
  user_type: '',
  devices: [],
  device: null,
  last_completed_sequence_id: null,
  last_completed_sequence_at: null,
  last_completed_sequence_started_at: null,
  has_consented_to_terms_of_service: false,
  long_term_goals: [],
  username: '',
  local_device_settings: {},
  synced_platforms: {
    zoho: false,
    jira: false,
    clickup: false,
    trello: false,
    asana: false,
    monday: false,
    google: false,
    microsoft: false
  }
};

export const DEFAULT_CURRENT_ACTIVITY_PROPS = {
  current_activity: null,
  current_activity_assigned_at: null,
  current_activity_sequence: {
    id: '',
    type: '',
    user_id: '',
    activity_ids: [],
    total_duration_seconds: '',
    generated_sequence_activity_ids: null,
    pack_id: null,
    created_at: null,
    updated_at: null
  },
  current_focus_mode: null,
  current_focus_mode_finish_time: null,
  current_sequence_skipped_activities: [],
  last_completed_sequence: {
    id: '',
    activity_ids: [],
    type: '',
    created_at: null,
    updated_at: null
  },
  last_completed_sequence_at: null,
  last_completed_sequence_started_at: null,
  current_sequence_completed_activities: []
};

export const DEFAULT_ONBOARDING_STATS = {
  level: 1,
  total_percent: 0,
  has_edited_settings: false,
  has_edited_always_blocked_urls: false,
  has_edited_focus_mode: false,
  has_installed_desktop_app: false,
  has_installed_mobile_app: false,
  morning_routine_completion_streak_days: 0,
  evening_routine_completion_streak_days: 0,
  focus_mode_completion_streak_days: 0,
  morning_routines_completion_percentage_for_current_level: 0,
  evening_routines_completion_percentage_for_current_level: 0,
  focus_modes_completion_percentage_for_current_level: 0,
  average_morning_routines_completion_percentage: 0,
  average_evening_routines_completion_percentage: 0,
  average_num_focus_modes_completed_per_day: 0,
  routines_threshold: 7,
  focus_modes_threshold: 10,
  has_chatted_with_focus_bear: false
};

export const DEFAULT_CONSENT_PAYLOAD = {
  TERMS_OF_SERVICE: {
    consent_type: 'terms_of_service',
    consent_status: true
  },
  EVENT_TRACKING: {
    id: uuid(),
    consent_type: CONSENT_OPTION.EVENT_TRACKING,
    consent_status: true,
    withdrawal_date: moment().utc().format(),
    metadata: {}
  }
};

export const DEFAULT_TODO_NEW_TASK: NewTaskFormValues = {
  title: EMPTY_STRING,
  details: EMPTY_STRING,
  tags: [],
  due_date: moment().format('YYYY-MM-DD'),
  eisenhower_quadrant: { label: EMPTY_STRING, value: EMPTY_STRING },
  objective: EMPTY_STRING
};

export const DEFAULT_TEXT_EDITOR_TOOLBAR = {
  options: ['inline', 'list', 'blockType', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline']
  },
  list: {
    options: ['unordered', 'ordered']
  }
};

export const DEFAULT_LOCAL_DEVICE_SETTING = {
  Android: {
    areBreaksEnabledOnMobile: false
  },
  iOS: {
    areBreaksEnabledOnMobile: false
  },
  MacOS: {},
  Windows: {},
  Web: {}
};

export const DEFAULT_TEAM_MEMBERS = {
  admins: [],
  members: []
};

export const DEFAULT_TO_DO_SUBTASKS = {
  taskId: EMPTY_STRING,
  task: EMPTY_STRING,
  subTasks: [],
  isNewTask: false,
  isGeneratingSubtasks: false,
  isUpdatingSubTaskStatus: false,
  areSubTasksGenerated: false,
  where: TO_DO_SUB_TASK_WHERE.DASHBOARD
};

export const DEFAULT_NEW_TASK = () => ({
  id: uuid(),
  title: EMPTY_STRING,
  details: EMPTY_STRING,
  objective: EMPTY_STRING,
  tags: [],
  due_date: moment().format('YYYY-MM-DD'),
  eisenhower_quadrant: parseInt(EISENHOWER_QUADRANT.DO),
  status: TO_DO_STATUS.NOT_STARTED,
  created_at: new Date().toISOString()
});

export const DEFAULT_CONFIRM_ACTION_MODAL = {
  showConfirmModal: false,
  confirmTitle: '',
  modalType: ''
};

export const DEFAULT_PLATFORM_DEVICES = {
  android: 0,
  macOS: 0,
  windows: 0,
  iOS: 0
};

export const DEFAULT_LESSON_INFO = {
  currentLesson: 0,
  lastCompletedLesson: 0,
  isLastLesson: false,
  showContents: false
};

export const DEFAULT_REACT_PLAYER_CONFIG = {
  youtube: {
    playerVars: { showinfo: 0 }
  }
};

export const DEFAULT_FILTER_DATA = {
  taskName: EMPTY_STRING,
  focusMode: null,
  project: null,
  quadrant: null,
  status: null
};

export const DEFAULT_DUE_DATE_FILTERS = {
  dueToday: false,
  dueThisWeek: false,
  dueNextWeek: false
};

export const DEFAULT_HTML_ATTRIBUTES = {
  autoCorrect: 'off',
  autoComplete: 'off'
};

export const DEFAULT_START_RATING = { rating: 0, hover: 0 };

export const DEFAULT_TEAM_NAME = 'TEAM A';

export const DEFAULT_MORE_OPTION_TABS = [
  { title: MORE_OPTION_TAB.TIMING, test_id: ATTRIB.TEST.TAB_TIMING },
  {
    title: MORE_OPTION_TAB.YOUTUBE_VIDEOS,
    test_id: ATTRIB.TEST.TAB_YOUTUBE_VIDEOS
  },
  { title: MORE_OPTION_TAB.ALLOWED_APPS, test_id: ATTRIB.TEST.TAB_OTHER_APPS },
  { title: MORE_OPTION_TAB.LOG_DATA, test_id: ATTRIB.TEST.TAB_LOG_DATA },
  { title: MORE_OPTION_TAB.SETTINGS, test_id: ATTRIB.TEST.TAB_SETTINGS },
  { title: MORE_OPTION_TAB.ADVANCED, test_id: ATTRIB.TEST.TAB_ADVANCED },
  { title: MORE_OPTION_TAB.INSTRUCTIONS, test_id: ATTRIB.TEST.TAB_INSTRUCTIONS }
];

export const DEFAULT_TO_DO_PLAYER_TASK = () => ({
  id: uuid(),
  title: '',
  icon: DEFAULT_TO_DO_TASK_EMOJI,
  duration: 5,
  startTime: '',
  endTime: '',
  focusedDuration: 0,
  status: '',
  details: '',
  due_date: '',
  eisenhower_quadrant: 1,
  tags: [],
  created_at: new Date().toISOString()
});

export const DEFAULT_TO_DO_TASK_STATUS = [
  {
    label: t('to_do_procrastinate.not_started'),
    value: TO_DO_STATUS.NOT_STARTED
  },
  {
    label: t('to_do_procrastinate.in_progress'),
    value: TO_DO_STATUS.IN_PROGRESS
  },
  {
    label: t('to_do_procrastinate.completed'),
    value: TO_DO_STATUS.COMPLETED
  }
];

export const DEFAULT_PLAYER_CONFIG = {
  counter: DEFAULT_TODO_TASK_DURATION,
  isPlaying: false,
  isPlayingStarted: false
};

export const DEFAULT_SURVEY_DATA = {
  rate: INITIAL_RATING,
  userFeedback: EMPTY_STRING,
  answerChoices: []
};

export const DEFAULT_ROUTINE_DESTINATION_OPTIONS = [
  {
    label: t('move_habit.start_of_the_routine'),
    value: ROUTINE_DESTINATION_POSITION.START
  },
  {
    label: t('move_habit.middle_of_the_routine'),
    value: ROUTINE_DESTINATION_POSITION.MIDDLE
  },
  {
    label: t('move_habit.end_of_the_routine'),
    value: ROUTINE_DESTINATION_POSITION.END
  }
];

export const DEFAULT_ROUTINES_SUGGESTIONS = [
  { title: t('routine_suggestion.morning_routine'), value: true },
  { title: t('routine_suggestion.evening_routine'), value: true }
];

export const DEFAULT_GOAL_SUGGESTIONS = [
  t('routine_suggestion.build_heathy_habits'),
  t('routine_suggestion.stay_focused_at_work'),
  t('routine_suggestion.meditate_consistently'),
  t('routine_suggestion.sleep_better'),
  t('routine_suggestion.exercise_more'),
  t('routine_suggestion.improve_work_life_balance'),
  t('routine_suggestion.improve_eve_health'),
  t('routine_suggestion.get_oranised'),
  t('routine_suggestion.know_thyself'),
  t('routine_suggestion.chill_out'),
  t('routine_suggestion.be_more_creative'),
  t('routine_suggestion.learn_new_things'),
  t('routine_suggestion.track_quantified_self_metrics'),
  t('routine_suggestion.take_medicine')
];

export const DEFAULT_SURVEY_CHOICES = {
  CHOICE_WITH_NUMBER: {
    label: t('admin.checkbox_with_text'),
    value: 'CHOICES_NUMBER'
  },
  CHOICE_WITH_BOOLEAN: {
    label: t('admin.radio_button_with_text'),
    value: 'CHOICES_BOOLEAN'
  },
  TEXT: { label: t('admin.text_and_rating'), value: 'TEXT_AND_RATING' },
  TEXT_AND_RATING: { label: t('admin.text_only'), value: 'TEXT' }
};

export const DEFAULT_SURVEY_SUGGESTIONS = {
  STRONGLY_DISAGREE: {
    id: 'strongly_disagree',
    text: t('admin.strongly_disagree')
  },
  DISAGREE: {
    id: 'disagree',
    text: t('admin.disagree')
  },
  NEUTRAL: {
    id: 'neutral',
    text: t('admin.neutral')
  },
  AGREE: {
    id: 'agree',
    text: t('admin.agree')
  },
  STRONGLY_AGREE: {
    id: 'strongly_agree',
    text: t('admin.strongly_agree')
  },
  YES: {
    id: 'yes',
    text: t('admin.yes')
  },
  NO: {
    id: 'no',
    text: t('admin.no_')
  }
};

export const DEFAULT_TRIAL_ACCOUNT_FEATURES = [
  t('subscription_plan.ai_powered_distraction_blocking'),
  t('subscription_plan.unlimited_habits'),
  t('subscription_plan.cross_device_sync')
];
