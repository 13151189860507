import { PDFDownloadLink } from '@react-pdf/renderer';
import { EMPTY_LIST, NUMBERS, PDF_FILENAME, SCREEN } from 'constants/general';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { getPackIds } from 'utils/support';
import { HabitPackFormat } from 'constants/enum';
import HabitPackActionButton from 'components/common/buttons/HabitPackActionButton';
import PackMarketplaceControls from 'components/common/buttons/PackMarketplaceControls';
import DetailsPageActionsWrapper from '../wrappers/DetailsPageActionsWrapper';
import { useState, useContext } from 'react';
import { LayoutContext } from '../layout';
import HabitPackPdfDocument from './habitPackPdf/HabitPdfDocument';
import { useAuth0 } from '@auth0/auth0-react';

const AuthenticatedActions = () => {
  const { t } = useTranslation();
  const {
    habitPack: { currentViewedPack, installedHabitPacks },
    user: {
      subscriptionInfo: { userId }
    }
  } = useAppSelector((state) => state);
  const userIsPackAuthor = userId === currentViewedPack?.user_id;
  const installedPacksIds = getPackIds(installedHabitPacks);
  const userHasPackInstalled =
    currentViewedPack?.id && installedPacksIds.includes(currentViewedPack?.id);
  const currentUrl = window.location.href;
  const showAuthorOptionOutsideGenerator =
    userIsPackAuthor &&
    currentUrl.includes('dashboard') &&
    !currentUrl.includes('settings');
  const isPlayingStandaloneRoutineAllowed =
    userHasPackInstalled &&
    currentViewedPack?.pack_type === HabitPackFormat.STANDALONE;
  const [hasDownloadedPdf, setHasDownloadedPdf] = useState(false);
  const screen = useContext(LayoutContext);

  const pdfButtonText = (loading: boolean, hasDownloadedPdf: boolean) => {
    if (loading) {
      return t('loading_document');
    } else if (hasDownloadedPdf) {
      return t('downloaded');
    } else {
      return t('download_pdf');
    }
  };

  return (
    <>
      {showAuthorOptionOutsideGenerator && (
        <>
          <HabitPackActionButton text={t('market.edit')} />
          <HabitPackActionButton text={t('market.delete')} />
          <HabitPackActionButton text={t('duplicate')} />
        </>
      )}
      {screen?.width ?? NUMBERS.ZERO < SCREEN.WIDTH.TABLET ? (
        <PDFDownloadLink
          className='md:hidden mt-8 sm:mt-0 text-base sm:text-xl font-semibold px-8 sm:px-10 py-2 bg-blue-700 text-white rounded-md'
          onClick={() => setHasDownloadedPdf(true)}
          document={<HabitPackPdfDocument habitPack={currentViewedPack} />}
          fileName={PDF_FILENAME.SETTINGS}
        >
          {({ loading }) => pdfButtonText(loading, hasDownloadedPdf)}
        </PDFDownloadLink>
      ) : (
        <HabitPackActionButton text={t('generate_pdf')} />
      )}
      {isPlayingStandaloneRoutineAllowed && (
        <HabitPackActionButton text={t('market.play')} />
      )}
      {showAuthorOptionOutsideGenerator && currentViewedPack && (
        <PackMarketplaceControls habitPack={currentViewedPack} />
      )}
    </>
  );
};

export default function HabitPackActions() {
  const { t } = useTranslation();
  const {
    habitPack: { currentViewedPack, installedHabitPacks },
    setting: { isTokenAddedToInterceptor }
  } = useAppSelector((state) => state);
  const installedPacksIds = getPackIds(installedHabitPacks);
  const userHasPackInstalled =
    currentViewedPack?.id && installedPacksIds.includes(currentViewedPack?.id);
  const { isAuthenticated } = useAuth0();
  const isFocusOnlyHabitPack =
    [
      ...(currentViewedPack?.morning_activities ?? []),
      ...(currentViewedPack?.break_activities ?? []),
      ...(currentViewedPack?.evening_activities ?? [])
    ].length === EMPTY_LIST;

  return (
    <DetailsPageActionsWrapper>
      <HabitPackActionButton text={t('back')} />
      {isTokenAddedToInterceptor &&
        !isFocusOnlyHabitPack &&
        (userHasPackInstalled ? (
          <HabitPackActionButton text={t('market.uninstall')} />
        ) : (
          <HabitPackActionButton text={t('market.install')} />
        ))}
      {isAuthenticated && <AuthenticatedActions />}
      {}
    </DetailsPageActionsWrapper>
  );
}
