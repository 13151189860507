import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const Moon = ({
  styles = 'w-8 sm:w-4 xl:w-5 h-auto',
  fill = COLOR.GRAY_5OO
}: IconProps) => {
  return (
    <svg
      className={styles}
      viewBox='0 0 35 35'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      fill={fill}
      transform='rotate(270)'
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <defs> </defs>
        <g
          id='Vivid.JS'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
        >
          <g
            id='Vivid-Icons'
            transform='translate(-200.000000, -332.000000)'
            fill='#FDC567'
          >
            <g id='Icons' transform='translate(37.000000, 169.000000)'>
              <g id='moon' transform='translate(156.000000, 156.000000)'>
                <g transform='translate(7.000000, 7.000000)' id='Shape'>
                  <path d='M14.848,20.134 C20.1556269,25.4283051 28.2054618,26.8427784 35,23.675 C31.6908874,30.8513085 24.056803,34.9974194 16.2357109,33.8659829 C8.41461883,32.7345463 2.26851715,26.5949279 1.12882679,18.7750343 C-0.0108635669,10.9551407 4.12718795,3.31668468 11.3,-3.55271368e-15 C8.13088963,6.78965337 9.5489182,14.8366058 14.848,20.134 Z'></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Moon;
