import { PLATFORMS } from 'constants/general';
import { PropsWithChildren, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { platformSelector } from 'store/reducer/setting/selectors';
import BreakFrequency from './BreakFrequency';
import CutoffTimeForNonHighPriorityActivities from './CutoffTimeForNonHighPriorityActivities';
import ShutDownTime from './ShutDownTime';
import StartUpTime from './StartUpTime';
import TimingSummary from './TimingSummary';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit max-h-full flex flex-col gap-3 px-2 py-4 overflow-y-auto scrollbar-thin'>
    {children}
  </div>
);

const Timing = () => {
  const platform = useAppSelector(platformSelector);
  const shouldSupportWebAndDesktopApps = useMemo(
    () => [PLATFORMS.MAC, PLATFORMS.WIN, PLATFORMS.WEB].includes(platform),
    [platform]
  );
  return (
    <Wrapper>
      <StartUpTime />
      <ShutDownTime />
      <CutoffTimeForNonHighPriorityActivities />
      {shouldSupportWebAndDesktopApps ? <BreakFrequency /> : null}
      {/* {shouldBeMobileApp && <MobileApplicationsBreak />} @Description, temporary disabled for mobile apps */}

      <TimingSummary />
    </Wrapper>
  );
};

export default Timing;
