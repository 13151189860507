import { useMemo } from 'react';
import PlayerTasksList from 'components/todo-player/player-tasks-list';
import PlayerSelectedTask from 'components/todo-player/player-selected-task/';
import PlayerAddTask from 'components/todo-player/player-add-task';
import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import PlayerSelectedTaskCompletionPopup from 'components/todo-player/PlayerSelectedTaskCompletionPopup';
import SubTasksModal from 'components/dashboard/to-do/SubTasksModal';
import { isDarkModeActivated } from 'utils/validation';
import BrainDump from 'components/todo-player/brain-dump';
import { playerContentsSelector } from 'store/reducer/to-do/selectors';
import TotalDurationCounter from 'components/todo-player/TotalDurationCounter';
import classNames from 'classnames';
import { INTRO_TO_DO_PLAYER_STEPS } from 'constants/intro';
import Joyride from 'react-joyride';
import PlayerIntention from './PlayerIntention';
import ConfirmActionModal from 'components/common/ConfirmActionModal';

const PlayerContents = () => {
  const {
    showTodoSubTasksModal,
    player,
    themeMode,
    isUserOnboarding,
    showConfirmModal
  } = useAppSelector(playerContentsSelector);
  const shouldActivateDarkMode = useMemo(
    () => isDarkModeActivated(themeMode),
    [themeMode]
  );

  return (
    <div
      className={classNames(
        'w-full min-h-screen h-full flex flex-col items-center justify-center px-2 sm:px-16 md:px-36 xl:px-80 2xl:px-96 relative overflow-y-auto scrollbar-thin',
        {
          'bg-gray-600 text-white': shouldActivateDarkMode,
          'bg-white text-black': !shouldActivateDarkMode
        }
      )}
    >
      <Joyride
        steps={INTRO_TO_DO_PLAYER_STEPS.FIRST}
        continuous={true}
        run={isUserOnboarding}
        showProgress={true}
        scrollToFirstStep={true}
      />
      {player.tasks?.length ? (
        <>
          <PlayerSelectedTask />
          <PlayerTasksList />
        </>
      ) : (
        <div className='w-fit h-fit flex flex-col items-center gap-3'>
          {player.intention ? (
            <PlayerIntention intentionTextStyles='w-fit text-center here_is_the_intention text-lg sm:text-xl md:text-2xl' />
          ) : null}
          <h5 className='w-fit text-center text-gray-500'>
            {t('to_do_player.empty_tasks')}
          </h5>
        </div>
      )}
      <PlayerAddTask />
      <BrainDump />
      <TotalDurationCounter />
      {player.isSelectedTaskCompleted && <PlayerSelectedTaskCompletionPopup />}
      {showTodoSubTasksModal && <SubTasksModal />}
      {showConfirmModal && <ConfirmActionModal />}
    </div>
  );
};

export default PlayerContents;
