import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import {
  EMPTY_STRING,
  LOCAL_STORAGE,
  MAC_APP_DATA_LOCATION,
  PLATFORMS,
  POST_HOG_EVENT
} from 'constants/general';
import { t } from 'i18next';
import { BrainDumpTask } from 'interfaces/commonInterface';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { convertBrianDumpToTasks } from 'store/reducer/to-do/extra';
import { brainDumpSelector } from 'store/reducer/to-do/selectors';
import { getLocalStorage, updateLocalStorage } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import BrainDumpConvertedTasksModal from './BrainDumpConvertedTasksModal';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import { postHogCapture } from 'utils/events';

interface BrainDumpType {
  brainDump: string;
  brainDumpTasks: BrainDumpTask[];
}

const initBrainDump = { brainDump: EMPTY_STRING, brainDumpTasks: [] };

export const BrainDumpContext = createContext<{
  brainDumpContext: BrainDumpType;
  setBrainDumpContext?: Dispatch<SetStateAction<BrainDumpType>>;
}>({ brainDumpContext: initBrainDump });

const BrainDump = () => {
  const dispatch = useAppDispatch();
  const [brainDumpContext, setBrainDumpContext] =
    useState<BrainDumpType>(initBrainDump);
  const {
    platform,
    brainDumpTasks,
    isConvertingBrianDump,
    showBrainDumpTasksModal,
    themeMode,
    userId,
    userEmail
  } = useAppSelector(brainDumpSelector);

  useEffect(() => {
    setBrainDumpContext?.((prev) => ({
      ...prev,
      brainDump:
        getLocalStorage(LOCAL_STORAGE.BRIAN_DUMP_CONTENTS) ?? EMPTY_STRING
    }));
  }, []);

  useEffect(() => {
    const brain_dump_content = decodeURIComponent(
      brainDumpContext.brainDump.replaceAll('"', '')
    );
    sendDataToPlatform(
      platform,
      platform === PLATFORMS.MAC ? brain_dump_content : { brain_dump_content },
      MAC_APP_DATA_LOCATION.SEND_BRAIN_DUMP_CONTENT
    );
  }, [brainDumpContext.brainDump]);

  useEffect(() => {
    setBrainDumpContext?.((prev) => ({ ...prev, brainDumpTasks }));
  }, [isConvertingBrianDump]);

  const previousBrainDumpContents = getLocalStorage(
    LOCAL_STORAGE.BRIAN_DUMP_CONTENTS
  );

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <BrainDumpContext.Provider
      value={useMemo(
        () => ({ brainDumpContext, setBrainDumpContext }),
        [brainDumpContext]
      )}
    >
      <div className='w-full h-fit flex flex-col gap-2 relative mt-4'>
        <textarea
          disabled={isConvertingBrianDump}
          value={brainDumpContext.brainDump}
          rows={3}
          onChange={({ target: { value } }) =>
            setBrainDumpContext?.((prev) => ({ ...prev, brainDump: value }))
          }
          placeholder={t('to_do_player.brain_dump_placeholder')}
          className={classNames(
            'w-full outline-none border border-gray-200 resize-none shadow text-xs lg:text-sm p-2 rounded-md while_your_are_working_on_a_task',
            {
              'bg-gray-500': shouldActivateDarkMode,
              'bg-white': !shouldActivateDarkMode
            }
          )}
          {...DEFAULT_HTML_ATTRIBUTES}
        ></textarea>
        {isConvertingBrianDump ? (
          <div className='w-4 h-4 border-t-2 border-sunRayFirst rounded-full animate-spin self-end'></div>
        ) : (
          <ButtonFocusBear
            disabled={
              !brainDumpContext.brainDump ||
              brainDumpContext.brainDump === previousBrainDumpContents
            }
            title={t('to_do_player.use_ai_to_convert_to_tasks')}
            size={BTN_FB_SIZE.SMALL}
            onClick={() => {
              if (!isConvertingBrianDump) {
                updateLocalStorage(
                  LOCAL_STORAGE.BRIAN_DUMP_CONTENTS,
                  brainDumpContext.brainDump
                );
                dispatch(convertBrianDumpToTasks(brainDumpContext.brainDump));
                postHogCapture(
                  POST_HOG_EVENT.TODO_PLAYER_BRAINDUMP_AI_BUTTON_CLICKED,
                  userEmail,
                  {
                    userId
                  }
                );
              }
            }}
            additionalStyles='self-end you_can_use_ai_to_convert_brain_dump'
          />
        )}
        <p className='absolute -top-4 left-2 px-2 py-0.5 rounded-t-md text-[0.55rem] bg-sunRayFirst text-white'>
          {t('to_do_player.brain_dump')}
        </p>
      </div>
      {showBrainDumpTasksModal ? <BrainDumpConvertedTasksModal /> : null}
    </BrainDumpContext.Provider>
  );
};

export default BrainDump;
