import { useMemo, useState } from 'react';
import { TEAM_OWNER_ACTION } from 'constants/enum';
import { EMPTY_STRING } from 'constants/general';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  assignAdmin,
  removeAdmin,
  updateMemberExpiryDate
} from 'store/reducer/team/extra';
import {
  updateSelectedMemberId,
  updateSelectedMembersId,
  updateTeamOwnerAction
} from 'store/reducer/team/slice';
import Edit from 'assets/icons/Edit';
import { TeamMember } from 'interfaces';
import Tooltip from 'components/common/Tooltip';

const Loading = () => (
  <div className='w-4 h-4 rounded-full border-t border-gray-600 animate-spin'></div>
);

const MemberActions = ({ member }: { member: TeamMember }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const {
    team: {
      selectedTeam,
      isUpdatingExpiryDate,
      teamMembers: { admins }
    },
    user: { details: userInfo }
  } = useAppSelector((state) => state);
  const isOwner = admins.some(
    (admin) =>
      admin.email === userInfo?.email && member.email === userInfo?.email
  );

  if (isEditing) {
    return member.id && isUpdatingExpiryDate ? (
      <div className='w-4 h-4 border-t-2 border-gray-500 rounded-full animate-spin'></div>
    ) : (
      <input
        type='date'
        value={moment(member.member_expiry_date).format('YYYY-MM-DD')}
        onChange={({ target: { value } }) => {
          dispatch(updateSelectedMemberId(member.id));
          selectedTeam &&
            dispatch(
              updateMemberExpiryDate({
                member_id: member.id,
                team_id: selectedTeam?.id,
                expiry_date: moment(value).endOf('day').format()
              })
            );
        }}
        className='px-1.5 py-0.5 rounded outline-none cursor-pointer bg-gray-200 focus:bg-gray-200/70 text-center'
        min={moment().format('YYYY-MM-DD')}
      />
    );
  }
  const isMemberAccessExpired = moment(member.member_expiry_date).isAfter(
    moment()
  );

  return (
    <>
      <p>
        {isMemberAccessExpired
          ? t('admin_team.yes_until', {
              date: moment(member.member_expiry_date).format('YYYY-MM-DD')
            })
          : t('no')}
      </p>
      {!isOwner ? (
        <button
          onClick={() => setIsEditing(true)}
          className='w-fit h-fit border-2 border-gray-600 p-0.5 rounded-md hover:bg-gray-100'
        >
          <Tooltip icon={<Edit styles='w-3 h-auto' />} />
        </button>
      ) : null}
    </>
  );
};

const Member = ({ member }: { member: TeamMember }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    team: {
      teamMembers: { admins },
      selectedMembersId,
      selectedTeam,
      isAssigningAdmin,
      isRemovingAdmin,
      selectedMemberId,
      isRemovingMember,
      teamOwnerAction
    },
    user: { details: userInfo }
  } = useAppSelector((state) => state);
  const isOwner = admins.some(
    (admin) =>
      admin.email === userInfo?.email && member.email === userInfo?.email
  );
  const isAdmin = admins?.some(
    (admin) => admin.id === member.id && admin.email !== userInfo.email
  );

  const memberType = useMemo(() => {
    if (isOwner) {
      return t('admin_team.owner');
    } else if (isAdmin) {
      return t('admin_team.admin');
    }
    return t('admin_team.standard');
  }, [admins]);

  const handlePrivileges = () => {
    dispatch(updateSelectedMemberId(member.id));
    dispatch(updateTeamOwnerAction('' as TEAM_OWNER_ACTION));
    if (isAdmin) {
      dispatch(
        removeAdmin({
          member_id: member.id ?? EMPTY_STRING,
          team_id: selectedTeam?.id ?? EMPTY_STRING
        })
      );
    } else {
      dispatch(
        assignAdmin({
          member_id: member.id,
          team_id: selectedTeam?.id ?? EMPTY_STRING
        })
      );
    }
    dispatch(updateSelectedMembersId([]));
  };

  const shouldInitRemoveOrAssignAdminRoles = [
    TEAM_OWNER_ACTION.REMOVE_ADMIN,
    TEAM_OWNER_ACTION.ASSIGN_ADMIN
  ].includes(teamOwnerAction ?? ('' as TEAM_OWNER_ACTION));
  const isEitherAssigningOrRemovingAdminRoles =
    isAssigningAdmin || isRemovingAdmin;

  return (
    <div className='min-w-max w-full flex items-center justify-between gap-2 px-3 py-1.5 text-xs md:text-sm xl:text-base border-b-2 border-gray-300'>
      {isOwner ? (
        <div className='min-w-[11.5px] w-[3%]'></div>
      ) : (
        <div className='min-w-[20px] w-[3%] flex items-center justify-center'>
          {shouldInitRemoveOrAssignAdminRoles &&
          isEitherAssigningOrRemovingAdminRoles &&
          selectedMembersId?.includes(member.id) ? (
            <Loading />
          ) : (
            <input
              disabled={isRemovingMember}
              type='checkbox'
              className='w-5 h-5 cursor-pointer disabled:cursor-not-allowed'
              checked={selectedMembersId?.includes(member.id)}
              onChange={({ target: { checked } }) => {
                dispatch(
                  updateSelectedMembersId(
                    checked
                      ? [...selectedMembersId, member.id]
                      : selectedMembersId.filter((id) => id !== member.id)
                  )
                );
              }}
            />
          )}
        </div>
      )}

      <p className='min-w-[220px] w-[20%] px-3 py-1 truncate'>{member.email}</p>
      <p className='min-w-[220px] w-[15%] px-3 py-1 text-center truncate'>
        {member.first_name ?? '--------'}
      </p>
      <p className='min-w-[220px] w-[15%] px-3 py-1 text-center truncate'>
        {member.last_name ?? '--------'}
      </p>
      <p className='min-w-[200px] w-[15%] px-3 py-1 text-center truncate'>
        {moment(member.last_active_date).format('MMM DD, YYYY')}
      </p>
      <div className='min-w-[300px] w-[22%] px-3 py-1 flex items-center justify-center gap-1'>
        {isOwner ? '--------' : <MemberActions member={member} />}
      </div>
      <div className='min-w-[220px] w-[10%] flex items-center justify-center'>
        {!shouldInitRemoveOrAssignAdminRoles &&
        isEitherAssigningOrRemovingAdminRoles &&
        selectedMemberId === member.id ? (
          <Loading />
        ) : (
          <select
            disabled={isOwner}
            value={memberType}
            className='text-xs sm:text-sm px-1.5 py-1 rounded-md bg-gray-200 cursor-pointer outline-none disabled:cursor-not-allowed'
            onChange={handlePrivileges}
          >
            <option value={t('admin_team.admin')}>
              {t('admin_team.admin')}
            </option>
            <option value={t('admin_team.standard')}>
              {t('admin_team.standard')}
            </option>
            <option hidden value={t('admin_team.owner')}>
              {t('admin_team.owner')}
            </option>
          </select>
        )}
      </div>
      <p className='min-w-[200px] w-[15%] px-3 py-1 text-center truncate'>
        {member.morning_routines_streak ?? '--------'}
      </p>
      <p className='min-w-[200px] w-[15%] px-3 py-1 text-center truncate'>
        {member.evening_routines_streak ?? '--------'}
      </p>
      <p className='min-w-[250px] w-[15%] px-3 py-1 text-center truncate'>
        {member.focus_modes_streak ?? '--------'}
      </p>
    </div>
  );
};

export default Member;
