import { NUMBERS, TOAST } from 'constants/general';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { copyActivity } from 'store/reducer/setting/slice';

const CopyActivity = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    modal: {
      moreOption: { activity }
    },
    setting: {
      moreOptions: { type, position }
    }
  } = useAppSelector((state) => state);

  const handleClick = useCallback(() => {
    dispatch(
      copyActivity({
        activity: {
          ...activity,
          name: t('copy_of_activity_name', { activity_name: activity.name })
        },
        position: position + NUMBERS.ONE,
        type
      })
    );
    toast.info(t('copied'), { autoClose: TOAST.AUTO_CLOSE.COPIED });
  }, [activity?.name]);

  return (
    <button
      className='w-fit max-w-[80vw] sm:max-w-[30vw] h-fit bg-gray-500 hover:bg-gray-600 rounded-md px-3 py-1 text-white text-xs truncate'
      onClick={handleClick}
    >
      {t('copy_activity', { activity_name: activity?.name })}
    </button>
  );
};

export default CopyActivity;
