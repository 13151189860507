import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateMoreOptionShowYouTubeURLModal,
  updateMoreOptionShowAllowedURLModal,
  updateMoreOptionShowChoicesModal,
  updateChoicesShowYouTubeURLModal,
  updateMoreOptionShowImageURLModal,
  initLogQuantityAnswers,
  updateShowLogQuantityModal,
  updateNoteShowNoteModal,
  updateMoreOptionShowCheckListModal,
  updateLogQuantityShowQuestionDetailsModal,
  updateAddToCalendarShowAddToCalendarModalOpened,
  updateFocusSummaryGraphShowFocusSummaryGraphModal,
  updateEisenhowerMatrixIsEisenhowerMatrixModalOpened,
  updateRoutineActivitiesStatsShowRoutineActivitiesStatsModal,
  updateFocusSummaryMore,
  updateShowKeywordModal,
  updateIsLongTermGoalsModalOpened,
  resetMoreOptionAllowedAppsTab
} from 'store/reducer/modal/slice';
import {
  updateIsAccountDeleteConfirmationModalShown,
  updateIsFetchingNextRoutineActivity,
  updateIsRequestDataConfirmationModalShown,
  updateIsShowMoreOptionsOpened,
  updateIsShowRoutineModal,
  updateIsUnlockHabitPackModalShown,
  updateShowSkipActivityModal
} from 'store/reducer/setting/slice';
import {
  MODAL_TYPES,
  TAKE_NOTE_OPTION,
  TO_DO_SUB_TASK_WHERE
} from 'constants/general';
import CloseCircle from 'assets/icons/CloseCircle';
import { ATTRIB } from 'constants/test';
import { THEME_OPTION } from 'constants/enum';
import {
  updateNewTask,
  updateSelectedPlayerSubTasks,
  updateShowEmbeddedCreateToDoModal,
  updateShowTodoSubTasksModal
} from 'store/reducer/to-do/slice';
import { isRouteEmbedded } from 'utils/validation';
import { updateShowProjectTasksModal } from 'store/reducer/platform-integration/slice';
import {
  updateShowCourseDetailsModal,
  updateShowCourseLessonsModal
} from 'store/reducer/course/slice';
import { updateShowSurveyDetailModal } from 'store/reducer/admin/slice';

interface ModalHeaderProps {
  title?: string;
  modalType?: string;
  style?: string;
}

const ModalHeader = ({
  title,
  modalType,
  style = 'bg-gray-800'
}: ModalHeaderProps) => {
  const dispatch = useAppDispatch();
  const {
    modal: {
      logQuantity: { areLogDataValid },
      note: { isCurrentActivityPlaying },
      toDoSubTasks: { subTasks, isNewTask, where }
    },
    setting: { currentPlayingActivity, themeMode },
    toDo: { newTask }
  } = useAppSelector((state) => state);

  const handleOnCloseLogQuantityModal = () => {
    if (!areLogDataValid) {
      dispatch(initLogQuantityAnswers([]));
    }
    dispatch(updateShowLogQuantityModal(false));
    //check next step, note modal or next activity
    if (
      currentPlayingActivity?.take_notes === TAKE_NOTE_OPTION.END_OF_ACTIVITY
    ) {
      dispatch(updateNoteShowNoteModal(true));
    } else {
      dispatch(updateIsFetchingNextRoutineActivity(true));
    }
  };

  const handleClick = () => {
    if (modalType === MODAL_TYPES.ROUTINE) {
      dispatch(updateIsShowRoutineModal(false));
    } else if (modalType === MODAL_TYPES.YOUTUBE_URLS) {
      dispatch(updateMoreOptionShowYouTubeURLModal(false));
    } else if (modalType === MODAL_TYPES.ALLOWED_URLS) {
      dispatch(updateMoreOptionShowAllowedURLModal(false));
    } else if (modalType === MODAL_TYPES.CHOICES) {
      dispatch(updateMoreOptionShowChoicesModal(false));
    } else if (modalType === MODAL_TYPES.CHOICE_YOUTUBE) {
      dispatch(updateChoicesShowYouTubeURLModal(false));
    } else if (modalType === MODAL_TYPES.IMAGE_URLS) {
      dispatch(updateMoreOptionShowImageURLModal(false));
    } else if (modalType === MODAL_TYPES.UNLOCK_HABIT_PACK) {
      dispatch(updateIsUnlockHabitPackModalShown(false));
    } else if (modalType === MODAL_TYPES.LOG_QUESTION_DETAILS) {
      dispatch(updateLogQuantityShowQuestionDetailsModal(false));
    } else if (modalType === MODAL_TYPES.LOG_QUANTITY) {
      handleOnCloseLogQuantityModal();
    } else if (modalType === MODAL_TYPES.NOTE) {
      dispatch(updateNoteShowNoteModal(false));
      if (!isCurrentActivityPlaying) {
        dispatch(updateIsFetchingNextRoutineActivity(true));
      }
    } else if (modalType === MODAL_TYPES.CHECK_LIST) {
      dispatch(updateMoreOptionShowCheckListModal(false));
    } else if (modalType === MODAL_TYPES.SKIP_ACTIVITY_MODAL) {
      dispatch(updateShowSkipActivityModal(false));
    } else if (modalType === MODAL_TYPES.SET_REMINDER) {
      dispatch(updateAddToCalendarShowAddToCalendarModalOpened(false));
    } else if (modalType === MODAL_TYPES.REQUEST_DATA_CONFIRMATION_MODAL) {
      dispatch(updateIsRequestDataConfirmationModalShown(false));
    } else if (modalType === MODAL_TYPES.ACCOUNT_DELETION_MODAL) {
      dispatch(updateIsAccountDeleteConfirmationModalShown(false));
    } else if (modalType === MODAL_TYPES.FOCUS_SUMMARY_GRAPH) {
      dispatch(updateFocusSummaryGraphShowFocusSummaryGraphModal(false));
    } else if (modalType === MODAL_TYPES.EISENHOWER_MATRIX_MODAL) {
      dispatch(updateEisenhowerMatrixIsEisenhowerMatrixModalOpened(false));
    } else if (modalType === MODAL_TYPES.ROUTINE_ACTIVITIES_STATS_MODAL) {
      dispatch(
        updateRoutineActivitiesStatsShowRoutineActivitiesStatsModal(false)
      );
    } else if (modalType === MODAL_TYPES.EMBEDDED_CREATE_TO_DO_MODAL) {
      dispatch(updateShowEmbeddedCreateToDoModal(false));
    } else if (modalType === MODAL_TYPES.TO_DO_SUB_TASK_MODAL) {
      if (where === TO_DO_SUB_TASK_WHERE.DASHBOARD && isNewTask) {
        dispatch(updateNewTask({ ...newTask, subtasks: subTasks }));
      } else {
        dispatch(updateSelectedPlayerSubTasks(subTasks));
      }
      dispatch(updateShowTodoSubTasksModal(false));
    } else if (modalType === MODAL_TYPES.PROJECT_TASKS_MODAL) {
      dispatch(updateShowProjectTasksModal(false));
    } else if (modalType === MODAL_TYPES.FOCUS_SUMMARY_MORE) {
      dispatch(
        updateFocusSummaryMore({
          showFocusSummaryMore: false,
          focusSummary: null
        })
      );
    } else if (modalType === MODAL_TYPES.UPDATE_KEYWORD_MODAL) {
      dispatch(updateShowKeywordModal(false));
    } else if (modalType === MODAL_TYPES.LONG_TERM_GOALS_MODAL) {
      dispatch(updateIsLongTermGoalsModalOpened(false));
    } else if (modalType === MODAL_TYPES.COURSE_LESSONS_MODAL) {
      dispatch(updateShowCourseLessonsModal(false));
    } else if (modalType === MODAL_TYPES.COURSE_DETAILS_MODAL) {
      dispatch(updateShowCourseDetailsModal(false));
    } else if (modalType === MODAL_TYPES.SURVEY_DETAIL_MODAL) {
      dispatch(updateShowSurveyDetailModal(false));
    } else {
      dispatch(updateIsShowMoreOptionsOpened(false));
      dispatch(resetMoreOptionAllowedAppsTab());
    }
  };

  return (
    <div
      className={`w-full flex justify-between items-center px-4 py-3 -mt-0.5 rounded-t-md ${style}`}
    >
      <div
        className={`w-5/6 text-sm lg:text-base xl:text-lg truncate capitalize ${
          (themeMode === THEME_OPTION.LIGHT ||
            isRouteEmbedded(window.location.pathname)) &&
          'text-white'
        }`}
      >
        {title ?? ''}
      </div>
      {!(
        modalType === MODAL_TYPES.INQUIRY ||
        modalType === MODAL_TYPES.REQUEST_USER_DETAILS
      ) && (
        <button
          data-test={ATTRIB.TEST.CLOSE_MODAL}
          onClick={handleClick}
          className='w-fit h-fit cursor-pointer'
        >
          <CloseCircle />
        </button>
      )}
    </div>
  );
};
export default ModalHeader;
