import { DEFAULT_HTML_ATTRIBUTES } from 'assets/data';
import { Attributes, DetailedHTMLProps, InputHTMLAttributes } from 'react';
interface TextInputProps
  extends DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    Attributes {
  readonly type?: 'text';
  readonly additionalStyles?: string;
}

export default function TextInput({
  type = 'text',
  className,
  additionalStyles,
  ...rest
}: TextInputProps) {
  return (
    <input
      type={type}
      {...rest}
      className={`
        ${
          className ??
          'border-orange-300 border outline-none rounded-md px-2 py-1 min-w-[30vw] text-sm md:text-base'
        } ${additionalStyles}`}
      {...DEFAULT_HTML_ATTRIBUTES}
    />
  );
}
