import CheckBox from 'components/common/CheckBox';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateRoutineSuggestionPayload } from 'store/reducer/setting/slice';
import { WizardSectionWrapper } from '.';
import { routineSelectionSelector } from 'store/reducer/setting/selectors';
import { useEffect } from 'react';
import { updateTabLayoutTitles } from 'store/reducer/general/slice';

const RoutineSelection = () => {
  const dispatch = useAppDispatch();
  const routineSuggestionPayload = useAppSelector(routineSelectionSelector);

  useEffect(() => {
    dispatch(
      updateTabLayoutTitles(
        routineSuggestionPayload.routines
          .filter((routine) => routine.value)
          .map((routine) => routine.title)
      )
    );
  }, [routineSuggestionPayload.routines]);

  const handleSelection = (title: string, checked: boolean) => {
    dispatch(
      updateRoutineSuggestionPayload({
        ...routineSuggestionPayload,
        routines: routineSuggestionPayload.routines.map((routine) =>
          routine.title === title ? { ...routine, value: checked } : routine
        )
      })
    );
  };

  return (
    <WizardSectionWrapper>
      <p className='text-lg md:text-xl lg:text-2xl text-center'>
        {t('routine_suggestion.choose_whether_you_want_to_do_your_habits')}
      </p>
      <div className='flex items-center justify-center flex-wrap gap-5 sm:gap-10'>
        {routineSuggestionPayload?.routines.map(({ title, value }) => (
          <CheckBox
            key={title}
            label={title}
            checked={value}
            onChange={({ target: { checked } }) =>
              handleSelection(title, checked)
            }
            inputStyles='w-5 md:w-6 w-5 md:h-6 cursor-pointer outline-none accent-sunRayFirst'
            labelStyles='text-base sm:text-xl md:text-2xl'
          />
        ))}
      </div>
    </WizardSectionWrapper>
  );
};

export default RoutineSelection;
