import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { fetchAllMembers } from 'store/reducer/team/extra';
import { TEAM_NAME_PLACEHOLDER } from 'constants/general';
import Spinner from 'components/common/Spinner';
import Tooltip from 'components/common/Tooltip';
import { updateTeamOwnerAction } from 'store/reducer/team/slice';
import Edit from 'assets/icons/Edit';
import { TEAM_OWNER_ACTION } from 'constants/enum';
import ListOfMembers from './ListOfMembers';
import TeamOwnerActions from '../teamOwnerActions';
import { t } from 'i18next';

const TeamMembers = () => {
  const dispatch = useAppDispatch();
  const { selectedTeam, areMembersLoading } = useAppSelector(
    (state) => state.team
  );

  useEffect(() => {
    selectedTeam?.id && dispatch(fetchAllMembers(selectedTeam?.id));
  }, [selectedTeam]);

  return (
    <div className='w-full flex flex-col animate-fadeIn'>
      <div className='flex items-center gap-4'>
        <h2 className='text-xl sm:text-2xl md:text-3xl lg:text-4xl'>
          {selectedTeam?.name ?? TEAM_NAME_PLACEHOLDER}
        </h2>
        <button
          onClick={() =>
            dispatch(updateTeamOwnerAction(TEAM_OWNER_ACTION.UPDATE_NAME))
          }
          className='w-fit h-fit border-2 border-gray-600 p-0.5 rounded-md hover:bg-gray-100'
        >
          <Tooltip
            icon={<Edit styles='w-4 h-4' />}
            message={t('admin_team.update_team_name')}
          />
        </button>
      </div>
      <TeamOwnerActions />
      {areMembersLoading ? <Spinner /> : <ListOfMembers />}
    </div>
  );
};

export default TeamMembers;
