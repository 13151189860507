import AppStore from 'assets/icons/AppStore';
import AppleLogo from 'assets/icons/AppleLogo';
import CloseFilled from 'assets/icons/CloseFilled';
import GooglePlayLogo from 'assets/icons/GooglePlayLogo';
import WindowsLogo from 'assets/icons/WindowsLogo';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import { DOWNLOAD_LINK } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/hooks';
import {
  updateIsInvitationAccepted,
  updateShowTeamWelcomeModal
} from 'store/reducer/team/slice';

const platforms = [
  { icon: <AppleLogo />, link: DOWNLOAD_LINK.MAC_APP },
  { icon: <WindowsLogo />, link: DOWNLOAD_LINK.WINDOWS_APP },
  { icon: <GooglePlayLogo />, link: DOWNLOAD_LINK.MOBILE.ANDROID },
  { icon: <AppStore />, link: DOWNLOAD_LINK.MOBILE.IOS }
];

const TeamWelcomeModal = ({ teamName }: { teamName: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <ModalOverlay zIndex='z-[99]'>
      <ModalWrapper>
        <button
          className='self-end'
          onClick={() => {
            dispatch(updateShowTeamWelcomeModal(false));
            dispatch(updateIsInvitationAccepted(true));
          }}
        >
          <CloseFilled />
        </button>
        <p className='text-xl md:text-2xl lg:text-3xl py-1 sm:py-2'>
          {t('welcome_to_team', { teamName })}
        </p>
        <p className='text-sm sm:text-base md:text-lg lg:text-xl pt-1 sm:pt-3 md:pt-6 pb-1 sm:pb-2'>
          {t('select_your_device_to_download_the_app')}
        </p>
        <div className='w-full flex items-center justify-around pb-10'>
          {platforms.map(({ link, icon }) => (
            <a
              target='_blank'
              key={link}
              href={link}
              rel='noreferrer'
              className='bg-focusBearText px-2 sm:px-3 md:px-4 py-1.5 sm:py-2 md:py-3 rounded-md sm:rounded-lg md:rounded-xl shadow-md'
            >
              {icon}
            </a>
          ))}
        </div>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default TeamWelcomeModal;
