import HabitPackActions from 'components/dashboard/marketplace/HabitPackActions';
import InquiryNoticeModal from 'components/dashboard/inquiry/InquiryNoticeModal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setCurrentViewedPack } from 'store/reducer//habit-pack/slice';
import HabitPackActivityList from 'pages/marketplace/HabitPackActivityList';
import { EMPTY_LIST, PLATFORMS } from 'constants/general';
import { PAGES } from 'constants/routes';
import DetailsPageWrapper from 'components/dashboard/wrappers/DetailsPageWrapper';
import DetailsPageHeader from 'components/dashboard/marketplace/DetailsPageHeader';
import DetailsPageBodyWrapper from 'components/dashboard/wrappers/DetailsPageBodyWrapper';
import DetailsPageListWrapper from 'components/dashboard/wrappers/DetailsPageListWrapper';

export default function PackDetails() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    habitPack: { currentViewedPack },
    inquiry: { is_inquiry_modal_shown },
    setting: { platform }
  } = useAppSelector((state) => state);

  useEffect(() => {
    return () => {
      dispatch(setCurrentViewedPack(null));
    };
  }, []);

  const isFocusOnlyHabitPack =
    [
      ...(currentViewedPack?.morning_activities ?? []),
      ...(currentViewedPack?.break_activities ?? []),
      ...(currentViewedPack?.evening_activities ?? [])
    ].length === EMPTY_LIST;

  return (
    <DetailsPageWrapper page={PAGES.HABIT_PACK}>
      <DetailsPageHeader />
      <DetailsPageBodyWrapper>
        <HabitPackActions />
        <DetailsPageListWrapper styles='h-fit max-h-[calc(100vh/3)] md:max-h-[calc(100vh/1.75)] px-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100'>
          {isFocusOnlyHabitPack ? (
            <p className='m-auto py-20'>{t('market.focus_mode_pack_notice')}</p>
          ) : (
            <>
              {(currentViewedPack?.morning_activities ?? []).length ? (
                <HabitPackActivityList
                  title={t('market.morning_habits')}
                  habitActivities={currentViewedPack?.morning_activities ?? []}
                />
              ) : null}

              {(currentViewedPack?.evening_activities ?? []).length ? (
                <HabitPackActivityList
                  title={t('market.evening_habits')}
                  habitActivities={currentViewedPack?.evening_activities ?? []}
                />
              ) : null}

              {(currentViewedPack?.break_activities ?? []).length ? (
                <HabitPackActivityList
                  title={t('market.break_activities')}
                  habitActivities={currentViewedPack?.break_activities ?? []}
                />
              ) : null}
            </>
          )}

          {(currentViewedPack?.standalone_activities ?? []).length ? (
            <HabitPackActivityList
              title={t('market.standalone_activities')}
              habitActivities={currentViewedPack?.standalone_activities ?? []}
            />
          ) : null}
        </DetailsPageListWrapper>
      </DetailsPageBodyWrapper>
      {is_inquiry_modal_shown && platform === PLATFORMS.WEB && (
        <InquiryNoticeModal />
      )}
    </DetailsPageWrapper>
  );
}
