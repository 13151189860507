import { TAB } from 'constants/general';
import { PropsWithChildren } from 'react';
import { useAppSelector } from 'store/hooks';
import AdvancedBtn from '../AdvancedBtn';
import FilterItemDaysOfWeek from './FilterItemDaysOfWeek';
import FilterItemName from './FilterItemName';
import FilterItemPriority from './FilterItemPriority';
import FilterWidgetActions from './FilterWidgetActions';

const WidgetWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-fit h-fit flex items-start justify-end gap-2 absolute -top-2 right-2.5'>
    {children}
  </div>
);

const ToolbarWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-fit max-w-[75vw] h-fit flex items-center justify-between gap-4 bg-gray-600 py-3 px-3 rounded-b-2xl animate-slideIn z-20 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400'>
    {children}
  </div>
);

const Widgets = () => {
  const {
    filter: { isSettingToolbarShown },
    setting: { activeTabIndex, tabs }
  } = useAppSelector((state) => state);

  return (
    <WidgetWrapper>
      <AdvancedBtn />
      {isSettingToolbarShown && (
        <ToolbarWrapper>
          {TAB.MICRO_BREAKS !== tabs[activeTabIndex] && (
            <FilterItemDaysOfWeek />
          )}
          <FilterItemPriority />
          <FilterItemName />
        </ToolbarWrapper>
      )}
      <FilterWidgetActions />
    </WidgetWrapper>
  );
};

export default Widgets;
