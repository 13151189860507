import * as Sentry from '@sentry/react';
import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import { PAGINATE } from 'constants/enum';
import { DEFAULT_TASKS_PER_PAGE } from 'constants/general';
import { ROUTES } from 'constants/routes';
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getFocusModeTags } from 'store/reducer/focus-mode/extra';
import { updateTabLayout } from 'store/reducer/general/slice';
import { initPagination } from 'store/reducer/pagination/slice';
import { themeSelector } from 'store/reducer/setting/selectors';
import { todoSelector } from 'store/reducer/to-do/selectors';
import { loadAccessTokenForToDo } from 'utils/focusBear_apps';
import { isDarkModeActivated, isEmbeddedTodoList } from 'utils/validation';
import EmbeddedCreateToDoModal from './EmbeddedCreateToDoModal';
import SubTasksModal from './SubTasksModal';
import TodoListOfTasksContainer from './todo-list-of-tasks-container';

export const ToDoContext = createContext<{
  shouldEmbedTodoList: boolean;
  shouldToolsEmbedTodo: boolean;
  shouldEmbedTodoPlayer: boolean;
}>({
  shouldEmbedTodoList: false,
  shouldToolsEmbedTodo: false,
  shouldEmbedTodoPlayer: false
});

const Wrapper: React.FC = ({ children }: PropsWithChildren<object>) => {
  const [
    shouldActivateDarkMode,
    shouldEmbedTodoPlayer,
    shouldEmbedTodoList,
    shouldToolsEmbedTodo
  ] = [
    isDarkModeActivated(useAppSelector(themeSelector)),
    window.location.pathname === ROUTES.WEBVIEW_TO_DO_PLAYER,
    isEmbeddedTodoList(window.location.pathname),
    window.location.pathname === ROUTES.WEBVIEW_TOOLS_TODO_LIST
  ];

  return (
    <section
      className={`w-full flex ${
        shouldEmbedTodoList
          ? 'h-screen flex-col-reverse justify-end p-4 items-center gap-1'
          : 'h-fit flex-col xl:flex-row xl:items-start 2xl:gap-8 pt-4 lg:pt-8 gap-10 xl:gap-4'
      } ${shouldActivateDarkMode ? 'bg-gray-600' : 'bg-transparent'}`}
    >
      <ToDoContext.Provider
        value={useMemo(
          () => ({
            shouldEmbedTodoList,
            shouldToolsEmbedTodo,
            shouldEmbedTodoPlayer
          }),
          []
        )}
      >
        {children}
      </ToDoContext.Provider>
    </section>
  );
};

const ToDo = () => {
  const dispatch = useAppDispatch();
  const {
    isTokenAddedToInterceptor,
    showEmbeddedCreateToDoModal,
    showTodoSubTasksModal
  } = useAppSelector(todoSelector);
  const shouldEmbedTodoList = isEmbeddedTodoList(window.location.pathname);

  useEffect(() => {
    loadAccessTokenForToDo();
    dispatch(updateTabLayout([]));
    dispatch(
      initPagination({
        type: PAGINATE.TO_DO_TASKS,
        itemsPerPage: DEFAULT_TASKS_PER_PAGE
      })
    );
    dispatch(getFocusModeTags());
  }, []);

  return (
    <>
      {isTokenAddedToInterceptor ? (
        <Wrapper>
          <TodoListOfTasksContainer />
        </Wrapper>
      ) : (
        <InitEmbeddedServices />
      )}
      {shouldEmbedTodoList && showEmbeddedCreateToDoModal && (
        <EmbeddedCreateToDoModal />
      )}
      {showTodoSubTasksModal && <SubTasksModal />}
    </>
  );
};

export default Sentry.withProfiler(ToDo);
