import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  DEFAULT_HTML_ATTRIBUTES,
  DEFAULT_TO_DO_PLAYER_TASK
} from 'assets/data';
import {
  EMPTY_STRING,
  TO_DO_PLAYER_SEARCH_DEBOUNCE_DELAY
} from 'constants/general';
import { ToDoPlayerTask } from 'interfaces';
import { t } from 'i18next';
import COLOR from 'constants/color';
import ModalOverlay from 'components/shared/ModalOverlay';
import CloseCircle from 'assets/icons/CloseCircle';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateFocusPlayer,
  updateNewTodoPlayerTask,
  updatePlayerIsReadyToSendUpdates
} from 'store/reducer/to-do/slice';
import Search from 'assets/icons/Search';
import {
  decrement,
  formatToDoPlayerTaskTimeConstraints,
  getTodoPlayerTaskDuration
} from 'utils/support';
import { searchToDos } from 'store/reducer/to-do/extra';
import { useDebounce } from 'usehooks-ts';
import {
  playerAddTaskModalBodySelector,
  playerAddTaskSelector
} from 'store/reducer/to-do/selectors';
import RecentTasks from './RecentTasks';
import SearchedTasks from './SearchedTasks';
import AddTaskBtn from './AddTaskBtn';
import Joyride from 'react-joyride';
import { INTRO_TO_DO_PLAYER_STEPS } from 'constants/intro';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';
import classNames from 'classnames';

interface NewTaskModalType {
  task: ToDoPlayerTask;
  showEmojiPicker: boolean;
  showNewTaskForm: boolean;
  searchedTitle: string;
  isSearchTitleFocused: boolean;
}

export const INIT_NEW_TASK = {
  task: DEFAULT_TO_DO_PLAYER_TASK(),
  showEmojiPicker: false,
  showNewTaskForm: false,
  searchedTitle: EMPTY_STRING,
  isSearchTitleFocused: false
};

export const NewTaskModalContext = createContext<{
  newTaskModal: NewTaskModalType;
  setNewTaskModal?: Dispatch<SetStateAction<NewTaskModalType>>;
}>({
  newTaskModal: {
    task: DEFAULT_TO_DO_PLAYER_TASK(),
    showEmojiPicker: false,
    showNewTaskForm: false,
    searchedTitle: EMPTY_STRING,
    isSearchTitleFocused: false
  }
});

const PlayerAddTaskWrapper = ({ children }: PropsWithChildren<object>) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <div
      className={classNames(
        'relative left-1.5 top-[99%] -translate-y-full w-[90%] md:w-3/4 xl:w-2/3 h-fit flex rounded-lg py-3 shadow mx-auto animate-bottomUpFast overflow-hidden',
        {
          'bg-gray-600 text-white': shouldActivateDarkMode,
          'bg-gray-100 text-black': !shouldActivateDarkMode
        }
      )}
    >
      {children}
    </div>
  );
};

const PlayerAddTaskModalBody = () => {
  const dispatch = useAppDispatch();
  const { newTaskModal, setNewTaskModal } = useContext(NewTaskModalContext);
  const debounceSearchedTitle = useDebounce(
    newTaskModal.searchedTitle,
    TO_DO_PLAYER_SEARCH_DEBOUNCE_DELAY
  );
  const { isCreatingToDo, isUserOnboarding, themeMode } = useAppSelector(
    playerAddTaskModalBodySelector
  );

  useEffect(() => {
    debounceSearchedTitle &&
      dispatch(searchToDos({ title: debounceSearchedTitle }));
  }, [debounceSearchedTitle]);

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <div className='min-w-full min-h-[25vh] h-fit max-h-[90vh] flex flex-col items-center gap-3 bg-main p-2'>
      <div className='w-11/12 flex gap-2 bg-gray-300 rounded-md pr-2 shadow enter_the_name_of_your_task_here'>
        <input
          disabled={isCreatingToDo}
          value={newTaskModal.searchedTitle ?? EMPTY_STRING}
          type='text'
          className={classNames('w-full p-2 outline-none  rounded-l-md', {
            'bg-gray-500 focus:bg-gray-500/70 disabled:bg-gray-600':
              shouldActivateDarkMode,
            'bg-gray-50 focus:bg-white disabled:bg-gray-200':
              !shouldActivateDarkMode
          })}
          placeholder={t('to_do_player.type_your_task_here')}
          onChange={({ target: { value } }) =>
            setNewTaskModal?.((prev) => ({ ...prev, searchedTitle: value }))
          }
          {...DEFAULT_HTML_ATTRIBUTES}
          onFocus={() =>
            setNewTaskModal?.((prev) => ({
              ...prev,
              isSearchTitleFocused: true
            }))
          }
          onBlur={() =>
            setNewTaskModal?.((prev) => ({
              ...prev,
              isSearchTitleFocused: false
            }))
          }
        />
        <Search styles='w-5 h-auto rotate-90' fill={COLOR.BLACK} />
      </div>
      <div className='w-full h-[35vh] flex flex-col gap-1 overflow-y-auto scrollbar-thin rounded relative p-2 sm:px-4'>
        <SearchedTasks searchedTitle={debounceSearchedTitle} />
        <RecentTasks />
      </div>
      <Joyride
        showProgress={true}
        steps={INTRO_TO_DO_PLAYER_STEPS.SECOND}
        run={isUserOnboarding}
        scrollToFirstStep={true}
      />
    </div>
  );
};

const PlayerAddTask = () => {
  const dispatch = useAppDispatch();
  const { newTodoPlayerTask, player, isCreatingToDo } = useAppSelector(
    playerAddTaskSelector
  );
  const [newTaskModal, setNewTaskModal] =
    useState<NewTaskModalType>(INIT_NEW_TASK);

  useEffect(() => {
    if (newTodoPlayerTask) {
      const tasks = formatToDoPlayerTaskTimeConstraints([
        ...player.tasks,
        {
          ...newTodoPlayerTask,
          startTime: '',
          endTime: '',
          focusedDuration: 0,
          duration:
            newTodoPlayerTask.duration ??
            getTodoPlayerTaskDuration(
              decrement(player.tasks.length),
              player.tasks.length,
              player.total_duration
            )
        }
      ]);
      dispatch(
        updateFocusPlayer({
          ...player,
          tasks
        })
      );
      setNewTaskModal({ ...INIT_NEW_TASK, showNewTaskForm: false });
      dispatch(updatePlayerIsReadyToSendUpdates(true));
    }
    dispatch(updateNewTodoPlayerTask(null));
  }, [newTodoPlayerTask]);

  return (
    <NewTaskModalContext.Provider
      value={useMemo(() => ({ newTaskModal, setNewTaskModal }), [newTaskModal])}
    >
      {newTaskModal?.showNewTaskForm ? (
        <ModalOverlay styles='bg-gray-400/50'>
          <PlayerAddTaskWrapper>
            <button
              onClick={() => !isCreatingToDo && setNewTaskModal(INIT_NEW_TASK)}
              className='absolute top-0.5 right-0.5'
            >
              <CloseCircle
                styles='w-5 h-auto'
                fill={COLOR.BLACK}
                fillDarkMode={COLOR.BLACK}
              />
            </button>
            <PlayerAddTaskModalBody />
          </PlayerAddTaskWrapper>
        </ModalOverlay>
      ) : (
        <AddTaskBtn />
      )}
    </NewTaskModalContext.Provider>
  );
};

export default PlayerAddTask;
