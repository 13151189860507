import { PropsWithChildren, useState } from 'react';
import ModalOverlay from 'components/shared/ModalOverlay';
import { t } from 'i18next';
import {
  convertToSeconds,
  formatToDoPlayerTaskTimeConstraints,
  getTodoTaskStatus
} from 'utils/support';
import { updateToDoStatus } from 'store/reducer/to-do/extra';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import {
  updatePlayerIsReadyToSendUpdates,
  updatePlayerTaskCompletion
} from 'store/reducer/to-do/slice';
import sound from 'assets/audios/todo_player_task_end_notification.mp3';
import * as Sentry from '@sentry/react';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, TO_DO_STATUS } from 'constants/enum';
import { TO_DO_PLAYER_EXTEND_TASK_TIME } from 'constants/general';
import { playerSelectedTaskCompletionPopupSelector } from 'store/reducer/to-do/selectors';

const Wrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='relative left-1.5 top-[99%] -translate-y-full w-[95%] text-black sm:w-3/4 lg:w-1/2 h-fit flex flex-col items-center gap-4 shadow-lg rounded-md bg-focusBear p-2 md:p-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 animate-bottomUpFast'>
    {children}
  </div>
);

const PlayNotificationSound = () => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio autoPlay>
      <source src={sound} type='audio/mp3' />
    </audio>
  );
};

const PlayerSelectedTaskCompletionPopup = () => {
  const dispatch = useAppDispatch();
  const { player, isUpdatingStatus } = useAppSelector(
    playerSelectedTaskCompletionPopupSelector
  );
  const taskStatusOptions = getTodoTaskStatus(player.completedTask);
  const [status, setStatus] = useState(player.completedTask?.status ?? '');

  const handleSave = async () => {
    try {
      if (player.completedTask) {
        const previousStatus = player.completedTask?.status;
        const { duration, focusedDuration, startTime, endTime, ...rest } =
          player.completedTask ?? {};
        const response = await dispatch(
          updateToDoStatus({
            todoTask: { ...rest, status },
            isTodoPlayer: true
          })
        );
        if (response.meta.requestStatus === 'rejected') {
          setStatus(previousStatus);
          toast.error(
            t('to_do_procrastinate.could_not_update_the_task', {
              title: player.completedTask.title
            })
          );
        } else {
          const tasks = (player.tasks ?? []).map((task) =>
            task.id === player.completedTask?.id
              ? { ...task, focusedDuration: task.focusedDuration, status }
              : task
          );
          dispatch(
            updatePlayerTaskCompletion({
              isSelectedTaskCompleted: false,
              completedTask: null,
              tasks,
              tasksPlayedIds: [
                ...player.tasksPlayedIds,
                player.completedTask.id
              ]
            })
          );
          dispatch(updatePlayerIsReadyToSendUpdates(true));
        }
      }
    } catch (error) {
      Sentry.captureException(JSON.stringify(error));
    }
  };

  const handleMoreMinutes = () => {
    const { tasks, completedTask } = player;
    const currentTasks = [...tasks];
    if (completedTask) {
      const updatedTasks = formatToDoPlayerTaskTimeConstraints([
        ...currentTasks.map((task) =>
          task.id === completedTask.id
            ? {
                ...completedTask,
                duration:
                  parseInt(completedTask?.duration?.toString() ?? '0') +
                  convertToSeconds(TO_DO_PLAYER_EXTEND_TASK_TIME)
              }
            : task
        )
      ]);

      dispatch(
        updatePlayerTaskCompletion({
          isSelectedTaskCompleted: false,
          completedTask: null,
          selectedTask: updatedTasks.find(
            (task) => task.id === completedTask.id
          ),
          tasks: updatedTasks
        })
      );
    }
  };

  return (
    <ModalOverlay>
      <Wrapper>
        <p className='w-full text-center text-sm lg:text-base xl:text-lg'>
          {t('to_do_player.time_up', { task: player.completedTask?.title })}
        </p>

        <div className='w-fit h-fit flex items-end gap-5 text-sm'>
          <div className='flex flex-col'>
            <p className='text-base'>{t('to_do_player.task_status')}</p>
            <select
              disabled={isUpdatingStatus}
              value={status}
              onChange={({ target: { value } }) => setStatus(value)}
              className='w-full md:w-fit text-sm px-2 py-1 rounded outline-none border-b border-gray-400 cursor-pointer shadow disabled:cursor-not-allowed'
            >
              {taskStatusOptions.map((status) => (
                <option
                  key={status.value}
                  value={status.value}
                  className='text-sm cursor-pointer'
                >
                  {status.label}
                </option>
              ))}
            </select>
          </div>
          {isUpdatingStatus && player.completedTask?.id ? (
            <span className='w-6 h-6 rounded-full border-t-2 border-gray-700 animate-spin'></span>
          ) : (
            <>
              <ButtonFocusBear
                size={BTN_FB_SIZE.SMALL}
                title={t('save')}
                onClick={handleSave}
              />
              {player.completedTask?.status !== TO_DO_STATUS.COMPLETED && (
                <ButtonFocusBear
                  size={BTN_FB_SIZE.SMALL}
                  title={t('to_do_player.i_need_five_more_minutes')}
                  onClick={handleMoreMinutes}
                />
              )}
            </>
          )}
        </div>
      </Wrapper>
      <PlayNotificationSound />
    </ModalOverlay>
  );
};

export default PlayerSelectedTaskCompletionPopup;
