import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import COLOR from 'constants/color';
import PlusCircle from 'assets/icons/PlusCircle';
import {
  EISENHOWER_QUADRANT_OPTIONS,
  EMPTY_STRING,
  INITIAL_TEXT_EDITOR_VALUE,
  MAXIMUM_TO_DO_DETAILS_CHARACTERS,
  MAXIMUM_TO_DO_OBJECTIVE_CHARACTERS,
  REACT_SELECT_DROPDOWN,
  RICH_TEXT_EDITOR_HEIGHT
} from 'constants/general';
import { DEFAULT_NEW_TASK, DEFAULT_TODO_NEW_TASK } from 'assets/data';
import TextEditor from 'components/common/TextEditor';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  validateNewToDoTask,
  isEmbeddedTodoList,
  isDarkModeActivated,
  isRouteEmbedded
} from 'utils/validation';
import { createOrUpdateToDo } from 'store/reducer/to-do/extra';
import { v4 as uuid } from 'uuid';
import { TO_DO_STATUS } from 'constants/enum';
import { CreateToDoPayload } from 'interfaces';
import {
  updateNewTask,
  updateShowEmbeddedCreateToDoModal,
  updateShowTodoSubTasksModal
} from 'store/reducer/to-do/slice';
import DropDown from 'components/common/DropDown';
import { updateTodoSubTasks } from 'store/reducer/modal/slice';
import { AutoCompletionTags } from 'components/common/AutoCompletionTags';
import Tooltip from 'components/common/Tooltip';
import InfoCircle from 'assets/icons/InfoCircle';
import { getToDoUpdateTask } from 'utils/support';
import classNames from 'classnames';
import { t } from 'i18next';
import { newTaskSelector } from 'store/reducer/to-do/selectors';

const ErrorMessage = ({ message }: { message?: string }) => (
  <span className='text-xs sm:text-sm xl:text-base font-medium text-red-600 pl-2 lowercase'>
    {message ?? `*`}
  </span>
);

const NewTask = () => {
  const dispatch = useAppDispatch();

  const {
    tags,
    isCreatingToDo,
    shouldResetNewTaskForm,
    active,
    task,
    isUpdatingToDo,
    newTask,
    showTodoSubTasksModal,
    subTasks,
    themeMode,
    areTagsFetching
  } = useAppSelector(newTaskSelector);

  const shouldEmbedTodoList = useMemo(
    () => isEmbeddedTodoList(window.location.pathname),
    []
  );
  const todoTaskToBeUpdated = useMemo(() => getToDoUpdateTask(task), [task]);

  useEffect(() => {
    if (shouldResetNewTaskForm) {
      formik.resetForm({
        values: {
          ...formik.values,
          title: EMPTY_STRING,
          details: EMPTY_STRING,
          objective: EMPTY_STRING
        }
      });
      dispatch(updateNewTask(DEFAULT_NEW_TASK()));
      shouldEmbedTodoList && dispatch(updateShowEmbeddedCreateToDoModal(false));
    }
  }, [shouldResetNewTaskForm]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: active
      ? todoTaskToBeUpdated
      : {
          ...DEFAULT_TODO_NEW_TASK,
          eisenhower_quadrant: shouldEmbedTodoList
            ? EISENHOWER_QUADRANT_OPTIONS[0]
            : DEFAULT_TODO_NEW_TASK.eisenhower_quadrant
        },
    validate: (values) => {
      dispatch(
        updateNewTask({
          ...newTask,
          title: values.title,
          details: values.details,
          objective: values.objective,
          due_date: values.due_date,
          eisenhower_quadrant: parseInt(values.eisenhower_quadrant.value),
          tags: values.tags?.map((tag) => ({ id: tag.value, text: tag.label }))
        })
      );
      return validateNewToDoTask(values);
    },
    onSubmit: (values) => {
      let payload: CreateToDoPayload = {
        id: active ? todoTaskToBeUpdated.id : uuid(),
        status: TO_DO_STATUS.NOT_STARTED,
        title: values.title,
        details:
          values.details === INITIAL_TEXT_EDITOR_VALUE
            ? EMPTY_STRING
            : values.details,
        objective:
          values.objective === INITIAL_TEXT_EDITOR_VALUE
            ? EMPTY_STRING
            : values.objective,
        due_date: values.due_date,
        eisenhower_quadrant: parseInt(values.eisenhower_quadrant.value),
        subtasks: subTasks ?? []
      };
      if (values.tags.length) {
        payload = {
          ...payload,
          tags: values.tags.map((tag) => ({ id: tag.value, text: tag.label }))
        };
      }
      dispatch(
        createOrUpdateToDo({
          payload
        })
      );
    }
  });

  const isCreatingOrUpdatingTask =
    !showTodoSubTasksModal && (isCreatingToDo || isUpdatingToDo);

  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classNames(
        'w-full h-full flex flex-col items-center gap-4 shadow-md rounded-b-md p-3 md:p-6 relative',
        {
          'xl:w-[45%]': !shouldEmbedTodoList,
          'bg-gray-700 text-white': shouldActivateDarkMode,
          'bg-gray-100 text-black border border-gray-200':
            !shouldActivateDarkMode
        }
      )}
    >
      {!shouldEmbedTodoList ? (
        <h6 className='absolute -top-2 left-3 text-xs bg-gradient-to-b from-transparent via-gray-100 to-gray-100 px-3 text-gray-700'>
          {t('to_do_procrastinate.new_task')}
        </h6>
      ) : null}
      <input
        type='text'
        {...formik.getFieldProps('title')}
        className={classNames(
          'w-full px-2 py-1 rounded text-xs md:text-sm outline-none border text-black',
          {
            'bg-gray-400': shouldActivateDarkMode,
            'bg-white': !shouldActivateDarkMode,
            'border-rose-500': formik.errors.title,
            'border-gray-200': !formik.errors.title
          }
        )}
        placeholder={t('to_do_procrastinate.title')}
      />
      <TextEditor
        textMarkup={formik.values.details}
        onTextChange={(value) => {
          formik.setFieldValue('details', value);
        }}
        height={
          shouldEmbedTodoList
            ? RICH_TEXT_EDITOR_HEIGHT.NEW_TASK
            : RICH_TEXT_EDITOR_HEIGHT.DEFAULT
        }
        maximumCharacters={MAXIMUM_TO_DO_DETAILS_CHARACTERS}
        placeholder={t('to_do_procrastinate.description')}
      />
      <TextEditor
        textMarkup={formik.values.objective}
        onTextChange={(value) => {
          formik.setFieldValue('objective', value);
        }}
        height={
          shouldEmbedTodoList
            ? RICH_TEXT_EDITOR_HEIGHT.NEW_TASK
            : RICH_TEXT_EDITOR_HEIGHT.DEFAULT
        }
        maximumCharacters={MAXIMUM_TO_DO_OBJECTIVE_CHARACTERS}
        placeholder={t('to_do_procrastinate.why_do_you_want_to_do_this_task')}
      />
      <div className='w-fit flex items-center gap-2 self-end'>
        <button
          disabled={!formik.values.title?.length}
          onClick={(event) => {
            event.preventDefault();
            dispatch(
              updateTodoSubTasks({
                subTasks: newTask.subtasks ?? [],
                isNewTask: true,
                title: newTask.title,
                taskId: newTask.id
              })
            );
            dispatch(updateShowTodoSubTasksModal(true));
          }}
          className={`w-fit h-fit px-2 py-1 rounded-md text-xs md:text-sm bg-gray-500 ${
            shouldActivateDarkMode
              ? 'hover:bg-gray-500/80'
              : 'hover:bg-gray-600'
          } disabled:bg-gray-300 disabled:text-gray-400 disabled:cursor-not-allowed animate-none text-white`}
        >
          {t('to_do_procrastinate.sub_tasks')}
        </button>
      </div>
      <div className='w-full flex flex-col gap-2 md:gap-4'>
        <label className='w-full flex items-center gap-2'>
          <span className='min-w-max w-1/2 text-xs md:text-sm font-medium'>
            {t('to_do_procrastinate.due_date')}
            {formik.errors.due_date ? <ErrorMessage /> : null}
          </span>
          <input
            className={`w-full px-2 py-1.5 rounded text-xs md:text-sm outline-none text-black ${
              shouldActivateDarkMode
                ? 'bg-gray-400 '
                : 'bg-white  border border-gray-200'
            } shadow`}
            type='date'
            {...formik.getFieldProps('due_date')}
          />
        </label>
        <DropDown
          title={t('priority')}
          value={formik.values.eisenhower_quadrant}
          options={EISENHOWER_QUADRANT_OPTIONS}
          handleChange={(result) =>
            result && formik.setFieldValue('eisenhower_quadrant', result)
          }
          containerStyles='w-full flex items-center gap-2'
          labelStyles='min-w-max w-1/2 text-xs md:text-sm font-medium'
          hasError={Boolean(formik.errors.eisenhower_quadrant)}
          ErrorMessage={
            <ErrorMessage message={formik.errors.eisenhower_quadrant?.value} />
          }
          zIndex={REACT_SELECT_DROPDOWN.Z_INDEX.NEW_TASK}
          defaultMenuIsOpen={isRouteEmbedded(window.location.pathname)}
        />
        <div className='w-full flex items-center gap-2'>
          <div className='min-w-max w-1/2  flex items-center gap-1'>
            <p className='text-xs md:text-sm font-medium'>
              {t('to_do_procrastinate.projects')}
            </p>
            <Tooltip
              icon={
                <InfoCircle
                  styles='w-4 md:w-5 h-auto'
                  fill={shouldActivateDarkMode ? COLOR.WHITE : COLOR.BLACK}
                />
              }
              message={t('to_do_procrastinate.project_description')}
            />
          </div>
          <AutoCompletionTags
            disabled={areTagsFetching}
            tags={(formik.values?.tags ?? []).map(({ label, value }) => ({
              id: value,
              text: label
            }))}
            setTags={(tags) =>
              formik.setFieldValue(
                'tags',
                (tags ?? []).map(({ id, text }) => ({ label: text, value: id }))
              )
            }
            handleAddTag={(newTag) => {
              let tag;
              if (tags.find((tag) => tag.id === newTag.id)) {
                tag = { value: newTag.id, label: newTag.text };
              } else {
                tag = { value: uuid(), label: newTag.text };
              }
              formik.setFieldValue('tags', [...formik.values.tags, tag]);
            }}
            suggestions={tags}
            placeholder={t('search')}
            containerStyles='w-full shadow'
          />
        </div>
      </div>
      <button
        disabled={isCreatingOrUpdatingTask}
        className={`w-fit bg-gray-500 ${
          isCreatingOrUpdatingTask
            ? 'cursor-not-allowed'
            : ' hover:bg-gray-600 cursor-pointer'
        }  text-white px-2.5 py-1 text-xs md:text-sm rounded-md flex items-center gap-1 my-5 self-end`}
        type='submit'
      >
        {active
          ? t('to_do_procrastinate.update')
          : t('to_do_procrastinate.create')}
        {isCreatingOrUpdatingTask && (
          <span className='w-3.5 h-3.5 rounded-full border-b border-white animate-spin ml-1'></span>
        )}
        {!isCreatingOrUpdatingTask && !active && (
          <PlusCircle styles='w-4 h-4' fill={COLOR.WHITE} />
        )}
      </button>
    </form>
  );
};

export default NewTask;
