import { THEME_OPTION } from 'constants/enum';
import { createAppSelector } from 'store/hooks';

export const isTokenAddedToInterceptorSelector = createAppSelector(
  [(state) => state.setting.isTokenAddedToInterceptor],
  (isTokenAddedToInterceptor) => isTokenAddedToInterceptor
);

export const themeSelector = createAppSelector(
  [(state) => state.setting.themeMode?.slice() as THEME_OPTION],
  (theme) => theme
);

export const routineSuggestionsSelector = createAppSelector(
  [
    (state) => state.setting.themeMode.slice() as THEME_OPTION,
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.habitPack.isSendingInstalledHabitPackId,
    (state) => state.setting.areSuggestedHabitPacksInstalled,
    (state) => state.setting.showEditSuggestionsModal
  ],
  (
    themeMode,
    isTokenAddedToInterceptor,
    isLogTermGoalUpdating,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal
  ) => ({
    themeMode,
    isTokenAddedToInterceptor,
    isLogTermGoalUpdating,
    isSendingInstalledHabitPackId,
    areSuggestedHabitPacksInstalled,
    showEditSuggestionsModal
  })
);

export const routineSuggestionsWizardSelector = createAppSelector(
  [
    (state) => state.setting.routineSuggestionPayload,
    (state) => state.setting.platform,
    (state) => state.user.details?.email,
    (state) => state.user.details?.id
  ],
  (routineSuggestionPayload, platform, userEmail, userId) => ({
    routineSuggestionPayload,
    platform,
    userEmail,
    userId
  })
);

export const routineGoalsSelector = createAppSelector(
  [
    (state) => state.user.details?.long_term_goals?.slice(),
    (state) => state.setting.routineSuggestionPayload.user_goals
  ],
  (long_term_goals, user_goals) => ({
    long_term_goals,
    user_goals
  })
);

export const listOfSelectedGoalsSelector = createAppSelector(
  [
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.setting.routineSuggestionPayload
  ],
  (isLogTermGoalUpdating, routineSuggestionPayload) => ({
    isLogTermGoalUpdating,
    routineSuggestionPayload
  })
);

export const selectedGoalSelector = createAppSelector(
  [(state) => state.user.details, (state) => state.user.isLogTermGoalUpdating],
  (details, isLogTermGoalUpdating) => ({ details, isLogTermGoalUpdating })
);

export const routineOptionsSelector = createAppSelector(
  [
    (state) => state.setting.isFetchingRoutineSuggestions,
    (state) => state.setting.routineSuggestionPayload,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.platform
  ],
  (
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  ) => ({
    isFetchingRoutineSuggestions,
    routineSuggestionPayload,
    userId,
    userEmail,
    platform
  })
);

export const routineAvailableGoalsSelector = createAppSelector(
  [
    (state) => state.user.details,
    (state) => state.user.isLogTermGoalUpdating,
    (state) => state.setting.routineSuggestionPayload
  ],
  (details, isLogTermGoalUpdating, routineSuggestionPayload) => ({
    details,
    isLogTermGoalUpdating,
    routineSuggestionPayload
  })
);

export const routineExploreHabitPacksSelector = createAppSelector(
  [
    (state) => state.habitPack.onboardingHabitPacks.slice(),
    (state) => state.habitPack.areHabitPacksLoading
  ],
  (onboardingHabitPacks, areHabitPacksLoading) => ({
    onboardingHabitPacks,
    areHabitPacksLoading
  })
);

export const routineHabitPackDetailsSelector = createAppSelector(
  [
    (state) => state.habitPack.installingPack,
    (state) => state.setting.platform,
    (state) => state.habitPack.isSendingInstalledHabitPackId,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (
    installingPack,
    platform,
    isSendingInstalledHabitPackId,
    userId,
    userEmail
  ) => ({
    installingPack,
    platform,
    isSendingInstalledHabitPackId,
    userId,
    userEmail
  })
);

export const suggestionsSelector = createAppSelector(
  [
    (state) => state.setting.routineSuggestions?.slice(),
    (state) => state.setting.routineSuggestionPayload.routines,
    (state) => state.setting.areSuggestedHabitPacksInstalled,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email,
    (state) => state.setting.platform
  ],
  (
    routineSuggestions,
    routines,
    areSuggestedHabitPacksInstalled,
    userId,
    userEmail,
    platform
  ) => ({
    routineSuggestions,
    routines,
    areSuggestedHabitPacksInstalled,
    userId,
    userEmail,
    platform
  })
);

export const tabBodySelector = createAppSelector(
  [
    (state) => state.setting.mode,
    (state) => state.setting.tabs,
    (state) => state.setting.isShowMoreOptionsOpened,
    (state) => state.setting.moreOptions.type,
    (state) => state.setting.moreOptions.position,
    (state) => state.setting.isShowRoutineModal,
    (state) => state.setting.activeTabIndex,
    (state) => state.setting.isUnlockHabitPackModalShown,
    (state) => state.setting.platform,
    (state) => state.setting.themeMode.slice() as THEME_OPTION,
    (state) => state.setting.isSettingLoaded,
    (state) => state.setting.customRoutine,
    (state) => state.setting.flags.slice()
  ],
  (
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags
  ) => ({
    mode,
    tabs,
    isShowMoreOptionsOpened,
    type,
    position,
    isShowRoutineModal,
    activeTabIndex,
    isUnlockHabitPackModalShown,
    platform,
    themeMode,
    isSettingLoaded,
    customRoutine,
    flags
  })
);

export const morningActivitiesSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.morning_activities?.slice(),
    (state) => state.filter
  ],
  (morning_activities, filter) => ({ morning_activities, filter })
);

export const eveningActivitiesSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.evening_activities?.slice(),
    (state) => state.filter,
    (state) => state.setting.mode
  ],
  (evening_activities, filter, mode) => ({ evening_activities, filter, mode })
);

export const routineActivitiesSelector = createAppSelector(
  [
    (state) => state.general.tabLayout.activeTab,
    (state) => state.setting.routineSuggestionPayload.routines,
    (state) => state.setting.routineSuggestions,
    (state) => state.setting.isInstallingRoutineSuggestions
  ],
  (activeTab, routines, routineSuggestions) => ({
    activeTab,
    routines,
    routineSuggestions
  })
);

export const routineSelectionSelector = createAppSelector(
  [(state) => state.setting.routineSuggestionPayload],
  (routineSuggestionPayload) => routineSuggestionPayload
);

export const suggestionsActionsSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.currentSettings,
    (state) => state.setting.isInstallingRoutineSuggestions,
    (state) => state.user.details?.id,
    (state) => state.user.details?.email
  ],
  (
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userId,
    userEmail
  ) => ({
    platform,
    currentSettings,
    isInstallingRoutineSuggestions,
    userId,
    userEmail
  })
);

export const errorPageSelector = createAppSelector(
  [(state) => ({ ...state.setting.error })],
  (error) => error
);

export const errorMessageSelector = createAppSelector(
  [
    (state) => ({ ...state.setting.error }),
    (state) => state.user.subscriptionInfo
  ],
  (error, subscriptionInfo) => ({ error, subscriptionInfo })
);

export const habitPackAndLibraryActivitiesAutocompleteInputSelector =
  createAppSelector(
    [
      (state) => state.setting.activityLibrary?.slice(),
      (state) => state.modal.choice.choices?.slice(),
      (state) => state.habitPack.marketplaceApprovedPacks?.slice(),
      (state) => state.setting.themeMode
    ],
    (activityLibrary, choices, marketplaceApprovedPacks, themeMode) => ({
      activityLibrary,
      choices,
      marketplaceApprovedPacks,
      themeMode
    })
  );

export const timingSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.startup_time,
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.setting.currentSettings.break_after_minutes,
    (state) =>
      state.setting.currentSettings.cutoff_time_for_non_high_priority_activities
  ],
  (
    startup_time,
    shutdown_time,
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities
  ) => ({
    startup_time,
    shutdown_time,
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities
  })
);

export const platformSelector = createAppSelector(
  [(state) => state.setting.platform],
  (platform) => platform
);

export const enableEditHabitsSelector = createAppSelector(
  [(state) => state.setting.platform, (state) => state.setting.flags?.slice()],
  (platform, flags) => ({ platform, flags })
);

export const statsPageSelector = createAppSelector(
  [
    (state) => state.setting.platform,
    (state) => state.setting.font,
    (state) => state.setting.themeMode
  ],
  (platform, font, themeMode) => ({ platform, font, themeMode })
);

export const cutoffTimeForNonHighPriorityActivitiesSelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.startup_time,
    (state) => state.setting.currentSettings.shutdown_time,
    (state) => state.setting.currentSettings.break_after_minutes,
    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.currentSettings.evening_activities?.slice()
  ],
  (
    startup_time,
    shutdown_time,
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities,
    evening_activities
  ) => ({
    startup_time,
    shutdown_time,
    break_after_minutes,
    cutoff_time_for_non_high_priority_activities,
    evening_activities
  })
);

export const timingSummarySelector = createAppSelector(
  [
    (state) => state.setting.currentSettings.startup_time,
    (state) => state.setting.currentSettings.shutdown_time,

    (state) =>
      state.setting.currentSettings
        .cutoff_time_for_non_high_priority_activities,
    (state) => state.setting.currentSettings.morning_activities?.slice(),
    (state) => state.setting.currentSettings.evening_activities?.slice()
  ],
  (
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    morning_activities,
    evening_activities
  ) => ({
    startup_time,
    shutdown_time,
    cutoff_time_for_non_high_priority_activities,
    morning_activities,
    evening_activities
  })
);
