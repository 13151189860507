import classNames from 'classnames';
import { DEFAULT_TAB_LAYOUT_TAB, EMPTY_STRING } from 'constants/general';
import { ReactNode } from 'react';
import { createAppSelector, useAppDispatch, useAppSelector } from 'store/hooks';
import { updateTabLayoutActiveTab } from 'store/reducer/general/slice';

const TAB_LAYOUT_NAVIGATION_MAX_WIDTH = 99.75;

interface TabLayoutProps {
  tabs: JSX.Element[];
  navigation_styles?: string;
  body_styles?: string;
  selectedTabColor?: string;
}

interface TabLayoutNavigationProps {
  navigation_styles: string;
  selectedTabColor?: string;
}

const TabLayoutWrapper = ({ children }: { readonly children: ReactNode }) => (
  <div className='w-full h-full flex flex-col gap-4'>{children}</div>
);

const TabLayoutNavigation = ({
  navigation_styles,
  selectedTabColor
}: TabLayoutNavigationProps) => {
  const dispatch = useAppDispatch();
  const { activeTab, titles } = useAppSelector(
    createAppSelector(
      [
        (state) => state.general.tabLayout.activeTab,
        (state) => state.general.tabLayout.titles
      ],
      (activeTab, titles) => ({ activeTab, titles })
    )
  );

  const tabWidth = titles?.length
    ? TAB_LAYOUT_NAVIGATION_MAX_WIDTH / titles.length
    : TAB_LAYOUT_NAVIGATION_MAX_WIDTH;

  const isFirstTabActive = activeTab === DEFAULT_TAB_LAYOUT_TAB;
  const formatWidth = `${tabWidth}%`;

  const styles = classNames(
    `h-[80%] rounded-full shadow-md absolute left-0 duration-200 ease-in-out`,
    {
      [selectedTabColor ?? EMPTY_STRING]: true,
      'bg-black/60': !selectedTabColor
    }
  );

  return (
    <div
      className={`w-full h-fit p-1 md:p-2 flex items-center justify-between rounded-full relative shadow ${navigation_styles}`}
    >
      <div
        className={styles}
        style={{
          width: formatWidth,
          transform: `translateX(${isFirstTabActive ? '1.25%' : `${activeTab * TAB_LAYOUT_NAVIGATION_MAX_WIDTH}%`})`
        }}
      ></div>
      {titles?.map((title, index) => (
        <button
          key={`${title}-${index}`}
          className={classNames(
            `p-1 lg:p-2 text-center z-10 outline-none line-clamp-1 leading-4 md:leading-5 lg:leading-6 xl:leading-7 text-xs sm:text-sm md:text-base`,
            {
              'text-white': activeTab === index,
              'text-back': activeTab !== index
            }
          )}
          onClick={() => dispatch(updateTabLayoutActiveTab(index))}
          style={{ width: formatWidth }}
        >
          {title}
        </button>
      ))}
    </div>
  );
};

const TabLayoutBody = ({
  tabs,
  body_styles
}: {
  tabs: JSX.Element[];
  body_styles: string;
}) => {
  const activeTab = useAppSelector(
    createAppSelector(
      [(state) => state.general.tabLayout.activeTab],
      (activeTab) => activeTab
    )
  );

  return (
    <>
      {tabs
        ?.filter((_, index) => index === activeTab)
        ?.map((tab, idx) => ({ id: idx, tab }))
        ?.map(({ id, tab }) => (
          <div
            key={id}
            className={`w-full h-full flex flex-col items-center gap-3 animate-fadeIn overflow-y-auto ${body_styles}`}
          >
            {tab}
          </div>
        ))}
    </>
  );
};

const TabLayout = ({
  tabs,
  navigation_styles = 'text-xs md:text-sm bg-transparent',
  body_styles = 'text-sm md:text-base bg-transparent pt-3',
  selectedTabColor
}: TabLayoutProps) => {
  const titles = useAppSelector(
    createAppSelector([(state) => state.general.tabLayout.titles], (titles) =>
      titles?.slice()
    )
  );

  return (
    <TabLayoutWrapper>
      {titles?.length ? (
        <TabLayoutNavigation
          navigation_styles={navigation_styles}
          selectedTabColor={selectedTabColor}
        />
      ) : null}
      <TabLayoutBody tabs={tabs} body_styles={body_styles} />
    </TabLayoutWrapper>
  );
};

export default TabLayout;
