import PlayFilled from 'assets/icons/PlayFilled';
import Redo from 'assets/icons/Redo';
import classNames from 'classnames';
import Button from 'components/common/buttons/Button';
import COLOR from 'constants/color';
import { TO_DO_STATUS } from 'constants/enum';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateToDoStatus } from 'store/reducer/to-do/extra';
import { playerTaskItemSelector } from 'store/reducer/to-do/selectors';
import {
  updateFocusPlayer,
  updatePlayerSelectedTask,
  updatePlayerTasks
} from 'store/reducer/to-do/slice';
import { PlayerTaskItemContext } from '.';
import PlayerTaskStatus from '../PlayerTaskStatus';

const PlayerTaskItemPlayActions = () => {
  const dispatch = useAppDispatch();
  const player = useAppSelector(playerTaskItemSelector);
  const { task } = useContext(PlayerTaskItemContext);
  const { playerData } = useContext(ToDoPlayerContext);
  const { selectedTask } = player;

  const [shouldDisableTaskSelection, shouldDisablePlayIcon] = [
    task?.status === TO_DO_STATUS.COMPLETED || playerData.isPlaying,
    selectedTask?.id === task?.id || task?.status === TO_DO_STATUS.COMPLETED
  ];

  const handlePlay = () => {
    if (task) {
      const isTaskNotStarted = task?.status === TO_DO_STATUS.NOT_STARTED;
      const selectedTask = {
        ...task,
        status: isTaskNotStarted ? TO_DO_STATUS.IN_PROGRESS : task.status
      };
      if (isTaskNotStarted) {
        dispatch(
          updatePlayerTasks(
            player.tasks.map((player_task) =>
              player_task.id === task.id
                ? { ...player_task, status: TO_DO_STATUS.IN_PROGRESS }
                : player_task
            )
          )
        );
        dispatch(
          updateToDoStatus({
            todoTask: selectedTask,
            isTodoPlayer: true
          })
        );
      }
      dispatch(updatePlayerSelectedTask(selectedTask));
    }
  };

  const handleRedo = () => {
    if (task) {
      const selectedTask = {
        ...task,
        status: TO_DO_STATUS.IN_PROGRESS,
        focusedDuration: 0
      };
      dispatch(
        updateToDoStatus({
          todoTask: selectedTask,
          isTodoPlayer: true
        })
      );
      dispatch(
        updateFocusPlayer({
          ...player,
          selectedTask,
          tasks: player.tasks.map((task) =>
            task.id === selectedTask?.id ? { ...selectedTask } : task
          ),
          tasksPlayedIds: player.tasksPlayedIds.filter(
            (ids) => ids !== selectedTask?.id
          ),
          completedTask: null
        })
      );
    }
  };

  return (
    <div className='flex items-center gap-2'>
      {task?.status !== TO_DO_STATUS.COMPLETED &&
        player.selectedTask?.id !== task?.id && (
          <Button
            className={classNames(
              'self-start',
              {
                'animate-pulse': !selectedTask,
                'animate-none': selectedTask
              },
              'start_working_on_the_task'
            )}
            disabled={shouldDisablePlayIcon}
            onClick={(event) => {
              event.stopPropagation();
              handlePlay();
            }}
            icon={
              <PlayFilled
                styles='w-6 h-auto'
                fill={
                  shouldDisableTaskSelection || shouldDisablePlayIcon
                    ? COLOR.GRAY_3OO
                    : COLOR.BLACK
                }
              />
            }
          />
        )}
      {task?.status === TO_DO_STATUS.COMPLETED && (
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleRedo();
          }}
          icon={
            <Redo
              styles='w-3 h-auto'
              fill={
                shouldDisableTaskSelection || shouldDisablePlayIcon
                  ? COLOR.GRAY_3OO
                  : COLOR.BLACK
              }
            />
          }
          className='bg-black rounded-full p-1'
        />
      )}
      {task && (
        <PlayerTaskStatus
          task={task}
          selectStyles='w-full md:w-fit text-[0.75rem] px-1.5 py-1 rounded outline-none border-b border-gray-400 cursor-pointer disabled:cursor-not-allowed animate-fadeIn'
        />
      )}
    </div>
  );
};

export default PlayerTaskItemPlayActions;
