import { createContext, useCallback, useContext, useEffect } from 'react';
import {
  decrement,
  formatToDoPlayerTaskTimeConstraints,
  increment,
  updateLocalStorage
} from 'utils/support';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided
} from 'react-beautiful-dnd';
import {
  DEFAULT_TODO_TASK_DURATION,
  LOCAL_STORAGE,
  TO_DO_PLAYER,
  TO_DO_PLAYER_TASK_BTN_DURATION
} from 'constants/general';
import PlayerTaskItem from './PlayerTaskItem';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updatePlayerIsReadyToSendUpdates,
  updatePlayerSelectedTask,
  updatePlayerTasks
} from 'store/reducer/to-do/slice';
import { TO_DO_STATUS } from 'constants/enum';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { DEFAULT_PLAYER_CONFIG } from 'assets/data';
import { ToDoPlayerTask } from 'interfaces';
import Joyride from 'react-joyride';
import { INTRO_TO_DO_PLAYER_STEPS } from 'constants/intro';
import { playerTasksListSelector } from 'store/reducer/to-do/selectors';
import { updateToDoStatus } from 'store/reducer/to-do/extra';

export const PlayerTaskItemContext = createContext<{
  task?: ToDoPlayerTask;
  handleDuration?: (action: string, id?: string, value?: number) => void;
  provided?: DraggableProvided;
}>({});

const PlayerTasksList = () => {
  const dispatch = useAppDispatch();
  const { player, isUserOnboarding } = useAppSelector(playerTasksListSelector);
  const { playerData } = useContext(ToDoPlayerContext);

  useEffect(() => {
    playerData.counter &&
      playerData.isPlayingStarted &&
      calculateFocusDuration();
  }, [playerData.isPlaying, playerData.isPlayingStarted, playerData.counter]);

  const handleDuration = (action: string, id?: string, value?: number) => {
    let duration: number = DEFAULT_TODO_TASK_DURATION;
    const tasks = formatToDoPlayerTaskTimeConstraints(
      player.tasks.map((task) => {
        if (task.id === id) {
          switch (action) {
            case TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_A_MINUTE: {
              const updatedMinuteDuration = decrement(
                parseInt(String(task.duration)),
                60
              );
              duration = updatedMinuteDuration > 0 ? updatedMinuteDuration : 0;
              break;
            }
            case TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_TEN_MINUTE: {
              const updatedTenMinuteDuration = decrement(
                parseInt(String(task.duration)),
                60 * 10
              );
              duration =
                updatedTenMinuteDuration > 0 ? updatedTenMinuteDuration : 0;
              break;
            }
            case TO_DO_PLAYER_TASK_BTN_DURATION.INCREMENT_A_MINUTE:
              duration = increment(parseInt(String(task.duration)), 60);
              break;
            case TO_DO_PLAYER_TASK_BTN_DURATION.INCREMENT_TEN_MINUTE:
              duration = increment(parseInt(String(task.duration)), 60 * 10);
              break;
            default:
              duration = value ?? 0;
          }
          return { ...task, duration };
        }
        return task;
      })
    );
    dispatch(updatePlayerTasks(tasks));
    player.selectedTask?.id &&
      player.selectedTask?.id === id &&
      dispatch(
        updatePlayerSelectedTask({
          ...player.selectedTask,
          duration,
          startTime: moment().format('h:mm a'),
          endTime: moment().add(duration, 'minutes').format('h:mm a'),
          id: player.selectedTask?.id
        })
      );
    dispatch(updatePlayerIsReadyToSendUpdates(true));
  };

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const updateData = Array.from(player.tasks ?? []);
      const [removed] = updateData.splice(result.source.index, 1);
      updateData.splice(result.destination.index, 0, removed);
      const tasks = formatToDoPlayerTaskTimeConstraints(updateData);
      dispatch(updatePlayerTasks(tasks));
      const task = tasks?.length
        ? tasks[TO_DO_PLAYER.FIRST_TASK_INDEX]
        : undefined;
      const isTaskNotStarted = task?.status === TO_DO_STATUS.NOT_STARTED;
      const isTaskPlacedFirst =
        result.destination.index === TO_DO_PLAYER.FIRST_TASK_INDEX;

      if (isTaskPlacedFirst) {
        const selectedTask = task
          ? {
              ...task,
              status: isTaskNotStarted ? TO_DO_STATUS.IN_PROGRESS : task.status
            }
          : undefined;

        if (isTaskNotStarted && selectedTask) {
          dispatch(
            updateToDoStatus({
              todoTask: selectedTask,
              isTodoPlayer: true
            })
          );
        }
        dispatch(updatePlayerSelectedTask(selectedTask));
      }

      dispatch(updatePlayerIsReadyToSendUpdates(true));
    },
    [player.tasks]
  );

  const calculateFocusDuration = () => {
    if (playerData.isPlayingStarted) {
      const focusedDuration =
        (player.selectedTask?.duration ?? DEFAULT_PLAYER_CONFIG.counter) -
        playerData.counter;
      if (focusedDuration !== playerData.counter) {
        const latestTodoTasks = player.tasks?.map((task) =>
          task.id === player.selectedTask?.id
            ? {
                ...task,
                focusedDuration
              }
            : task
        );
        dispatch(updatePlayerTasks(latestTodoTasks));
        updateLocalStorage(
          LOCAL_STORAGE.TO_DO_PLAYER_TASKS,
          JSON.stringify(latestTodoTasks)
        );
      }
    }
  };

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId={`list-container-todo-player`}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='w-full min-h-[35vh] h-fit max-h-[80vh] flex flex-col gap-5 pr-4 pt-5 pb-10 overflow-y-auto text-black'
          >
            {[...player.tasks]
              ?.sort(
                (taskA, taskB) =>
                  Number(taskA?.status === TO_DO_STATUS.COMPLETED) -
                  Number(taskB?.status === TO_DO_STATUS.COMPLETED)
              )
              ?.map((task, idx) => (
                <Draggable key={task?.id} draggableId={task?.id} index={idx}>
                  {(provided) => (
                    <PlayerTaskItemContext.Provider
                      value={{ provided, task, handleDuration }}
                    >
                      <PlayerTaskItem key={task?.id} />
                    </PlayerTaskItemContext.Provider>
                  )}
                </Draggable>
              ))}
          </div>
        )}
      </Droppable>
      {isUserOnboarding && (
        <Joyride
          steps={INTRO_TO_DO_PLAYER_STEPS.THIRD}
          continuous={true}
          showProgress={true}
          scrollToFirstStep={true}
        />
      )}
    </DragDropContext>
  );
};

export default PlayerTasksList;
