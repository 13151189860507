import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const AlarmClock = ({
  styles = 'w-8 sm:w-4 xl:w-5 h-auto',
  fill = COLOR.GRAY_5OO
}: IconProps) => {
  return (
    <svg
      viewBox='0 0 36 36'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-hidden='true'
      preserveAspectRatio='xMidYMid meet'
      fill={fill}
      className={styles}
    >
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <path
          fill='#FFCC4D'
          d='M20 6.042c0 1.112-.903 2.014-2 2.014s-2-.902-2-2.014V2.014C16 .901 16.903 0 18 0s2 .901 2 2.014v4.028z'
        ></path>
        <path
          fill='#FFAC33'
          d='M9.18 36c-.224 0-.452-.052-.666-.159a1.521 1.521 0 0 1-.667-2.027l8.94-18.127c.252-.512.768-.835 1.333-.835s1.081.323 1.333.835l8.941 18.127a1.52 1.52 0 0 1-.666 2.027a1.482 1.482 0 0 1-1.999-.676L18.121 19.74l-7.607 15.425A1.49 1.49 0 0 1 9.18 36z'
        ></path>
        <path
          fill='#58595B'
          d='M18.121 20.392a.985.985 0 0 1-.702-.295L3.512 5.998c-.388-.394-.388-1.031 0-1.424s1.017-.393 1.404 0L18.121 17.96L31.324 4.573a.985.985 0 0 1 1.405 0a1.017 1.017 0 0 1 0 1.424l-13.905 14.1a.992.992 0 0 1-.703.295z'
        ></path>
        <path
          fill='#DD2E44'
          d='M34.015 19.385c0 8.898-7.115 16.111-15.894 16.111c-8.777 0-15.893-7.213-15.893-16.111c0-8.9 7.116-16.113 15.893-16.113c8.778-.001 15.894 7.213 15.894 16.113z'
        ></path>
        <path
          fill='#E6E7E8'
          d='M30.041 19.385c0 6.674-5.335 12.084-11.92 12.084c-6.583 0-11.919-5.41-11.919-12.084C6.202 12.71 11.538 7.3 18.121 7.3c6.585-.001 11.92 5.41 11.92 12.085z'
        ></path>
        <path
          fill='#FFCC4D'
          d='M30.04 1.257a5.899 5.899 0 0 0-4.214 1.77l8.429 8.544A6.064 6.064 0 0 0 36 7.299c0-3.336-2.669-6.042-5.96-6.042zm-24.08 0a5.9 5.9 0 0 1 4.214 1.77l-8.429 8.544A6.064 6.064 0 0 1 0 7.299c0-3.336 2.668-6.042 5.96-6.042z'
        ></path>
        <path
          fill='#414042'
          d='M23 20h-5a1 1 0 0 1-1-1v-9a1 1 0 0 1 2 0v8h4a1 1 0 1 1 0 2z'
        ></path>
      </g>
    </svg>
  );
};

export default AlarmClock;
