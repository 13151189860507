import { useEffect, useCallback, lazy, useState } from 'react';
import {
  COOKIES,
  EMPTY_STRING,
  LANGUAGE,
  LOCAL_STORAGE,
  NUMBERS,
  POST_HOG_EVENT,
  REFERRAL,
  USER_ROLES,
  PROFIT_WELL_USER_ID,
  REDIRECTION_COMPLETED,
  TOAST_AUTO_CLOSE_TIMEOUT_DELAY
} from 'constants/general';
import {
  FIRST_LOGIN_KEY,
  ROUTES,
  TOKEN_ROLES_KEY,
  VALID_REDIRECT_PATHS
} from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getUserAvailablePlans,
  getUserDetails,
  getUserSubscriptionInfo
} from 'store/reducer/user/extra';
import { addAccessTokenToInterceptor } from 'services/axios-config';
import {
  updateIsTokenAddedToInterceptor,
  updateLanguage,
  updateShowPrivacyPolicyModal
} from 'store/reducer/setting/slice';
import {
  setIsUserAdmin,
  updateIsSubscriptionChecking
} from 'store/reducer/user/slice';
import { ToastContainer } from 'react-toastify';
import {
  getItemsLocalStorage,
  getLocalStorage,
  isProductionEnvironment,
  updateLocalStorage
} from 'utils/support';
import Loading from 'components/common/Loading';
import cookies from 'js-cookie';
import AppRoutes from './AppRoutes';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import OverlaySpinner from 'components/OverlaySpinner';
import { postHogCapture } from 'utils/events';

const UserPrivacyModal = lazy(() => import('components/UserPrivacyModal'));

const Navigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    getAccessTokenSilently,
    user: auth0User
  } = useAuth0();
  const {
    setting: { error, showPrivacyPolicyModal },
    user: {
      areUserDetailsLoading,
      details: userInfo,
      isUserLoggingOut,
      subscriptionInfo
    },
    team: { isAcceptingInvitation }
  } = useAppSelector((state) => state);
  const isOnSharePage = window.location.pathname.includes(ROUTES.SHARE_PACK);
  const dispatch = useAppDispatch();
  const { pathname: redirectPath, search } = useLocation();
  const [isCheckingBeforeLogIn, setCheckingBeforeLogIn] = useState(true);

  useEffect(() => {
    initialize();
  }, [isAuthenticated]);

  useEffect(() => {
    if (error.message !== '') {
      navigate(ROUTES.ERROR);
    }
  }, [error]);

  useEffect(() => {
    try {
      if (isProductionEnvironment && subscriptionInfo?.stripeCustomerId) {
        window?.profitwell('start', {
          [PROFIT_WELL_USER_ID]: subscriptionInfo.stripeCustomerId
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    const shouldAuthenticatedAndDetailsFetched =
      isAuthenticated && !areUserDetailsLoading && userInfo?.id;
    if (shouldAuthenticatedAndDetailsFetched) {
      !userInfo.has_consented_to_terms_of_service &&
        dispatch(updateShowPrivacyPolicyModal(true));
    }
  }, [areUserDetailsLoading]);

  const savePostHogEvents = useCallback(() => {
    const [
      referral,
      packToInstall,
      packId,
      focusTemplateToInstall,
      focusTemplateId
    ] = getItemsLocalStorage([
      LOCAL_STORAGE.SIGN_UP_REFERRAL,
      LOCAL_STORAGE.PACK_TO_INSTALL,
      LOCAL_STORAGE.PACK_ID,
      LOCAL_STORAGE.FOCUS_TEMPLATE_TO_INSTALL,
      LOCAL_STORAGE.FOCUS_TEMPLATE_ID
    ]);
    let postHogEvent = POST_HOG_EVENT.SIGN_UP_DIRECT;
    let postHogData = undefined;

    if (referral) {
      const [packName, focusTemplateName] = [
        packToInstall ? JSON.parse(packToInstall)?.pack_name : EMPTY_STRING,
        focusTemplateToInstall
          ? JSON.parse(focusTemplateToInstall)?.name
          : EMPTY_STRING
      ];

      if (referral === REFERRAL.HABIT_PACK) {
        postHogEvent = POST_HOG_EVENT.SIGN_UP_VIA_HABIT_PACK;
        postHogData = {
          packName,
          packId
        };
      } else {
        postHogEvent = POST_HOG_EVENT.SIGN_UP_VIA_FOCUS_TEMPLATE;
        postHogData = {
          focusTemplateName,
          focusTemplateId
        };
      }
    }
    postHogCapture(postHogEvent, userInfo.email, postHogData);
  }, []);

  const initialize = useCallback(async () => {
    const currentLang = cookies.get(COOKIES.LANGUAGE) || LANGUAGE.GB;
    dispatch(updateLanguage(currentLang));

    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      if (token) {
        updateLocalStorage(LOCAL_STORAGE.BEARER_TOKEN, token);
        addAccessTokenToInterceptor();
        dispatch(updateIsTokenAddedToInterceptor(true));
        if (
          auth0User &&
          auth0User[TOKEN_ROLES_KEY].includes(USER_ROLES.ADMIN)
        ) {
          dispatch(setIsUserAdmin(true));
        }
        // if a habit pack is saved in local storage to open in welcome page after signup or login
        // navigate to habit pack welcome page
        const packIdFromLocalStorage = getLocalStorage(LOCAL_STORAGE.PACK_ID);
        // if a focus template is saved in local storage to open in welcome page after signup or login
        // mavigate to focus template welcome page
        const focusTemplateIdFromLocalStorage = getLocalStorage(
          LOCAL_STORAGE.FOCUS_TEMPLATE_ID
        );
        if (packIdFromLocalStorage && !isOnSharePage) {
          navigate(ROUTES.HABIT_PACK_DASHBOARD);
        } else if (focusTemplateIdFromLocalStorage && !isOnSharePage) {
          navigate(ROUTES.FOCUS_TEMPLATE_DASHBOARD);
        } else {
          // redirect new user to settings page for first time
          if (auth0User?.[FIRST_LOGIN_KEY]) {
            isProductionEnvironment && savePostHogEvents();

            navigate(ROUTES.SETTINGS);
          } else {
            // Redirects a user to their original route after they've logged in
            const redirectionCompleted = getLocalStorage(
              LOCAL_STORAGE.REDIRECTION_COMPLETED
            );
            const redirectUrlFromLocalStorage = getLocalStorage(
              LOCAL_STORAGE.REDIRECT_PATH
            );
            if (
              !redirectionCompleted &&
              redirectUrlFromLocalStorage &&
              VALID_REDIRECT_PATHS.some((pathname) =>
                pathname === ROUTES.TODO
                  ? pathname.startsWith(ROUTES.TODO)
                  : pathname === redirectUrlFromLocalStorage
              ) &&
              !isOnSharePage
            ) {
              const invitation_data = getLocalStorage(
                LOCAL_STORAGE.INVITATION_TOKEN
              );
              if (invitation_data) {
                const parsedData = JSON.parse(invitation_data);
                if (parsedData?.email === userInfo.email) {
                  navigate(ROUTES.ACCEPT_INVITE);
                }
              }
              updateLocalStorage(
                LOCAL_STORAGE.REDIRECTION_COMPLETED,
                REDIRECTION_COMPLETED
              );
              navigate(redirectUrlFromLocalStorage);
            }
          }
        }
        await Promise.all([
          dispatch(getUserSubscriptionInfo()),
          dispatch(getUserAvailablePlans()),
          dispatch(getUserDetails())
        ]);
      } else {
        VALID_REDIRECT_PATHS.includes(redirectPath) &&
          updateLocalStorage(
            LOCAL_STORAGE.REDIRECT_PATH,
            redirectPath === ROUTES.TODO
              ? `${redirectPath}${search}`
              : redirectPath
          );
        navigate(ROUTES.LOGIN);
      }
    } else {
      VALID_REDIRECT_PATHS.includes(redirectPath) &&
        updateLocalStorage(
          LOCAL_STORAGE.REDIRECT_PATH,
          redirectPath === ROUTES.TODO
            ? `${redirectPath}${search}`
            : redirectPath
        );
      dispatch(updateIsSubscriptionChecking(false));
    }
    setCheckingBeforeLogIn(false);
  }, [isAuthenticated]);

  return (
    <>
      {isCheckingBeforeLogIn || isAuthLoading || isUserLoggingOut ? (
        <Loading
          title={isUserLoggingOut ? t('logging_out') : t('authenticating')}
        />
      ) : (
        <AppRoutes />
      )}

      {isAuthenticated && showPrivacyPolicyModal && <UserPrivacyModal />}
      {/* @Description: only for after the user logged in */}
      {isAuthenticated && isAcceptingInvitation && (
        <OverlaySpinner title={t('admin_team.processing_invitation_link')} />
      )}
      <ToastContainer
        limit={NUMBERS.ONE}
        draggable={false}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        autoClose={TOAST_AUTO_CLOSE_TIMEOUT_DELAY}
      />
    </>
  );
};

export default Navigation;
