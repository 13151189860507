import React from 'react';
import { EMPTY_STRING } from 'constants/general';
import { ROUTES } from 'constants/routes';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from 'store/hooks';
import { THEME_OPTION } from 'constants/enum';

interface Props {
  children: React.ReactNode;
  page: string;
}

export const DetailsPageWrapperContext = React.createContext({
  page: EMPTY_STRING,
});

const DetailsPageWrapper = ({ page, children }: Props) => {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth0();
  const {
    setting: { themeMode },
    habitPack: { isOnHabitPacksTab },
  } = useAppSelector((state) => state);

  const formatBackground = () => {
    if (isAuthenticated || isOnHabitPacksTab) {
      return themeMode === THEME_OPTION.DARK ? 'bg-gray-600' : 'bg-transparent';
    } else {
      return 'bg-bear bg-no-repeat bg-center bg-fixed';
    }
  };

  return (
    <div
      className={`w-full  flex flex-col ${
        pathname.includes(ROUTES.SETTINGS) ? 'px-0' : 'px-6 md:px-14'
      } animate-fadeIn ${
        isAuthenticated
          ? 'py-0 min-h-[calc(100vh-80px)]'
          : `h-screen py-8 overflow-y-auto scrollbar-thin scrollbar-thumb-orange-300 ${formatBackground()} `
      }`}
    >
      <DetailsPageWrapperContext.Provider value={{ page }}>
        {children}
      </DetailsPageWrapperContext.Provider>
    </div>
  );
};

export default DetailsPageWrapper;
