import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import {
  MAC_APP_DATA_LOCATION,
  PLATFORMS,
  POST_HOG_EVENT,
  ROUTINE_SUGGESTION_WIZARD_STEP
} from 'constants/general';
import { t } from 'i18next';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { routineSuggestionsWizardSelector } from 'store/reducer/setting/selectors';
import { sendDataToPlatform } from 'utils/focusBear_apps';
import RoutineDuration from './RoutineDuration';
import RoutineSelection from './RoutineSelection';
import ExploreHabitPacks from './explore-habit-packs/ExploreHabitPacks';
import RoutineGoals from './routine-goals';
import Suggestions from './suggestions';
import { postHogCapture } from 'utils/events';

export const WizardSectionWrapper = ({
  children
}: PropsWithChildren<object>) => {
  return (
    <div className='min-w-full h-full flex flex-col gap-8 items-center justify-center py-10 px-2 sm:px-4 md:px-10 lg:px-16 relative shadow rounded-3xl overflow-y-auto scrollbar-thin'>
      {children}
    </div>
  );
};

const RoutineSuggestionsWizard = () => {
  const {
    routineSuggestionPayload: { routines, user_goals },
    platform,
    userEmail,
    userId
  } = useAppSelector(routineSuggestionsWizardSelector);
  const [step, setStep] = useState(ROUTINE_SUGGESTION_WIZARD_STEP.GOALS);
  const { isStepOneValid, isStepTwoValid } = {
    isStepOneValid: Boolean(user_goals.length),
    isStepTwoValid: routines.some((routine) => routine.value)
  };

  useEffect(() => {
    // TODO (Bao): Remove the condition disabling this logic on android and iOS until mobile app dev integrate this
    if (platform !== PLATFORMS.ANDROID && platform !== PLATFORMS.IOS) {
      sendDataToPlatform(
        platform,
        { step },
        MAC_APP_DATA_LOCATION.ROUTINE_SUGGESTIONS_STEP
      );
    }
  }, [step]);

  const handleContinue = () => {
    let eventName =
      POST_HOG_EVENT.ROUTINE_SUGGESTIONS_ROUTINE_DURATION_CONTINUE_BUTTON_CLICKED;
    if (step === ROUTINE_SUGGESTION_WIZARD_STEP.GOALS) {
      eventName =
        POST_HOG_EVENT.ROUTINE_SUGGESTIONS_GOALS_SELECTION_CONTINUE_BUTTON_CLICKED;
    } else if (step === ROUTINE_SUGGESTION_WIZARD_STEP.ROUTINES) {
      eventName =
        POST_HOG_EVENT.ROUTINE_SUGGESTIONS_ROUTINE_SELECTION_CONTINUE_BUTTON_CLICKED;
    }
    postHogCapture(eventName, userEmail, { platform, userId });
    setStep((prev) => ++prev);
  };

  return (
    <>
      <div
        style={{ transform: `translateX(-${step * 100}%)` }}
        className={classNames(
          'min-w-full w-full flex transition ease-out duration-300 m-auto',
          {
            'h-[90%]': step < ROUTINE_SUGGESTION_WIZARD_STEP.DURATION,
            'h-full': step >= ROUTINE_SUGGESTION_WIZARD_STEP.DURATION
          }
        )}
      >
        <RoutineGoals />
        <RoutineSelection />
        <RoutineDuration setStep={setStep} />
        <Suggestions setStep={setStep} />
        <ExploreHabitPacks />
      </div>

      {step < ROUTINE_SUGGESTION_WIZARD_STEP.DURATION ? (
        <ButtonFocusBear
          disabled={
            (step === ROUTINE_SUGGESTION_WIZARD_STEP.GOALS &&
              !isStepOneValid) ||
            (step === ROUTINE_SUGGESTION_WIZARD_STEP.ROUTINES &&
              !isStepTwoValid)
          }
          title={t('continue')}
          onClick={handleContinue}
          size={BTN_FB_SIZE.LARGE}
          variant={
            step === ROUTINE_SUGGESTION_WIZARD_STEP.GOALS
              ? BTN_FB_VARIANT.PRIMARY
              : BTN_FB_VARIANT.SECONDARY
          }
        />
      ) : null}
    </>
  );
};

export default RoutineSuggestionsWizard;
