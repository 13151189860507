import { useAppDispatch, useAppSelector } from 'store/hooks';
import SubscriptionInfo from 'components/dashboard/subscription/SubscriptionInfo';
import ManageActiveSubscription from 'components/dashboard/subscription/ManageActiveSubscription';
import { SUBSCRIPTION_INFO_STORE } from 'constants/general';
import DocumentMetaData from 'components/DocumentMetaData';
import { PAGES } from 'constants/routes';
import ContentWrapper from 'components/dashboard/wrappers/ContentWrapper';
import { t } from 'i18next';
import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import { useEffect } from 'react';
import { loadMetaDataForSubscription } from 'utils/focusBear_apps';
import {
  getUserAvailablePlans,
  getUserSubscriptionInfo
} from 'store/reducer/user/extra';
import { isRouteEmbedded } from 'utils/validation';
import AvailablePlans from 'components/dashboard/subscription/AvailablePlans';
import Spinner from 'components/common/Spinner';
import CancelReasonModal from 'components/dashboard/subscription/CancelReasonModal';
import {
  subPageContentSelector,
  subscriptionSelector
} from 'store/reducer/user/selectors';
import classNames from 'classnames';

function SubPageContent() {
  const { subscriptionInfo, showCancelReasonModal } = useAppSelector(
    subPageContentSelector
  );

  return subscriptionInfo?.store === SUBSCRIPTION_INFO_STORE.APP_STORE ||
    subscriptionInfo?.store === SUBSCRIPTION_INFO_STORE.PLAY_STORE ? (
    <ContentWrapper>
      <p>{t('different_platform')}</p>
    </ContentWrapper>
  ) : (
    <>
      <SubscriptionInfo /> <ManageActiveSubscription />
      {showCancelReasonModal && <CancelReasonModal />}
    </>
  );
}

const Subscription = () => {
  const dispatch = useAppDispatch();
  const {
    isSubscriptionChecking,
    isGuestUser,
    isTokenAddedToInterceptor,
    isFetchingAvailableStripPlans
  } = useAppSelector(subscriptionSelector);
  const shouldBeEmbeddedPath = isRouteEmbedded(window.location.pathname);
  const isCheckingSubscription =
    isSubscriptionChecking || isFetchingAvailableStripPlans;

  useEffect(() => {
    loadMetaDataForSubscription();
  }, []);

  useEffect(() => {
    async function initSubscription() {
      await Promise.allSettled([
        dispatch(getUserAvailablePlans()),
        dispatch(getUserSubscriptionInfo())
      ]);
    }
    isTokenAddedToInterceptor && initSubscription();
  }, [isTokenAddedToInterceptor]);

  return isTokenAddedToInterceptor ? (
    <section
      className={classNames('flex flex-col items-center gap-2', {
        'w-screen h-screen justify-center px-8 sm:p-14 md:p-20':
          shouldBeEmbeddedPath,
        'w-full sm:w-10/12 md:w-8/12 lg:w-6/12 justify-start -mt-12 sm:mt-0 py-10 px-4 sm:px-0':
          !shouldBeEmbeddedPath
      })}
    >
      <DocumentMetaData pageName={PAGES.SUBSCRIPTION} />
      {isCheckingSubscription && (
        <Spinner minHeight='min-h-[50vh]' borderColor='border-black' />
      )}
      {!isCheckingSubscription &&
        (isGuestUser ? <AvailablePlans /> : <SubPageContent />)}
    </section>
  ) : (
    <InitEmbeddedServices />
  );
};

export default Subscription;
