import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import NavBar from '../navBar';
import { useWindowSize } from 'usehooks-ts';
import { useEffect, createContext, useRef, useMemo } from 'react';
import { LOCAL_STORAGE, SCREEN } from 'constants/general';
import { useAppSelector } from 'store/hooks';
import { getLocalStorage } from 'utils/support';
import ConfirmActionModal from 'components/common/ConfirmActionModal';
import { ROUTES } from 'constants/routes';
import { Helmet } from 'react-helmet';
import { isTrialOrPromoActive } from 'utils/subscriptions';
import { layoutSelector } from 'store/reducer/user/selectors';

export const LayoutContext = createContext({
  width: SCREEN.WIDTH.LARGE,
  height: SCREEN.HEIGHT.PORTRAIT
});

const Layout = () => {
  const navigate = useNavigate();
  const dummyDiv = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();
  const {
    isTokenAddedToInterceptor,
    userInfo,
    subscriptionInfo,
    isGuestUser,
    showConfirmModal
  } = useAppSelector(layoutSelector);

  useEffect(() => {
    dummyDiv.current && dummyDiv.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const invitation_data = getLocalStorage(LOCAL_STORAGE.INVITATION_TOKEN);
    if (isTokenAddedToInterceptor && invitation_data && userInfo?.email) {
      const parsedData = JSON.parse(invitation_data);
      if (parsedData?.email === userInfo?.email) {
        navigate(ROUTES.ACCEPT_INVITE);
      }
    }
  }, [isTokenAddedToInterceptor, userInfo]);

  const isPaying = useMemo(
    () => isTrialOrPromoActive(subscriptionInfo) || isGuestUser,
    [subscriptionInfo]
  );

  return (
    <LayoutContext.Provider value={{ width, height }}>
      <div className='w-screen h-screen flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-orange-300 bg-main'>
        <div className='float-left clear-both' ref={dummyDiv}></div>
        <NavBar />
        <main className='w-full h-fit pt-14 lg:pt-5 flex flex-col items-center'>
          <Outlet />
        </main>
        <Footer />
      </div>
      {showConfirmModal && <ConfirmActionModal />}

      {isTokenAddedToInterceptor && (
        <Helmet>
          <script>
            {`
            window.$zoho=window.$zoho || {};
            $zoho.salesiq=$zoho.salesiq||{ready:function(){                                  
            $zoho.salesiq.visitor.identifier("${userInfo?.email}");
            $zoho.salesiq.visitor.name("${userInfo?.id}");
            $zoho.salesiq.visitor.customfield("isPaying", ${isPaying} ? "Yes" : "No");
          }}`}
          </script>
          <script
            id='zsiqscript'
            src='https://salesiq.zohopublic.com.au/widget?wc=8b0f55dff01a5ffbcd3e74af14a8a7a9a20a66298fb3622b6963b8abf1f00da1'
            defer
          ></script>
        </Helmet>
      )}
    </LayoutContext.Provider>
  );
};

export default Layout;
