import { createAppSelector } from 'store/hooks';

export const coursePageSelector = createAppSelector(
  [(state) => state.setting.isTokenAddedToInterceptor],
  (isTokenAddedToInterceptor) => isTokenAddedToInterceptor
);

export const courseDetailsSelector = createAppSelector(
  [
    (state) => state.course.showCourseLessonsModal,
    (state) => state.course.isLoadingCourseDetails,
    (state) => state.course.isCreatingLessonCompletion
  ],
  (
    showCourseLessonsModal,
    isLoadingCourseDetails,
    isCreatingLessonCompletion
  ) => ({
    showCourseLessonsModal,
    isLoadingCourseDetails,
    isCreatingLessonCompletion
  })
);

export const enrolledCoursesListSelector = createAppSelector(
  [(state) => state.course.isFetchingEnrolledCourses],
  (isFetchingEnrolledCourses) => isFetchingEnrolledCourses
);

export const coursesPageSelectors = createAppSelector(
  [
    (state) => state.setting.isTokenAddedToInterceptor,
    (state) => state.course.showCourseLessonsModal,
    (state) => state.course.showCourseDetailsModal,
    (state) => state.general.tabLayout.activeTab,
    (state) => state.setting.themeMode
  ],
  (
    isTokenAddedToInterceptor,
    showCourseLessonsModal,
    showCourseDetailsModal,
    activeTab,
    themeMode
  ) => ({
    isTokenAddedToInterceptor,
    showCourseLessonsModal,
    showCourseDetailsModal,
    activeTab,
    themeMode
  })
);

export const courseItemSelector = createAppSelector(
  [
    (state) => state.course.isCreatingCourseEnrolment,
    (state) => state.course.details,
    (state) => state.general.tabLayout.activeTab,
    (state) => state.user.details,
    (state) => state.setting.themeMode
  ],
  (
    isCreatingCourseEnrolment,
    selectedCourse,
    activeTab,
    userInfo,
    themeMode
  ) => ({
    isCreatingCourseEnrolment,
    selectedCourse,
    activeTab,
    userInfo,
    themeMode
  })
);

export const listOfCoursesSelector = createAppSelector(
  [
    (state) => state.general.tabLayout.activeTab,
    (state) => state.course.other_courses?.slice(),
    (state) => state.course.enrolled_courses?.slice()
  ],
  (activeTab, other_courses, enrolled_courses) => ({
    activeTab,

    other_courses,
    enrolled_courses
  })
);

export const courseReviewsSelector = createAppSelector(
  [(state) => state.course.details?.ratings?.slice()],
  (ratings) => ratings
);

export const courseHighlightsSelector = createAppSelector(
  [
    (state) => state.course.details?.name,
    (state) => state.course.details?.description,
    (state) => state.course.details?.lessons?.slice()
  ],
  (name, description, lessons) => ({ name, description, lessons })
);

export const courseLessonsModalSelector = createAppSelector(
  [
    (state) => state.course.details,
    (state) => state.course.isCreatingLessonCompletion,
    (state) => state.course.isUpdatingCourseCompletion,
    (state) => state.setting.themeMode
  ],
  (
    selectedCourse,
    isCreatingLessonCompletion,
    isUpdatingCourseCompletion,
    themeMode
  ) => ({
    selectedCourse,
    isCreatingLessonCompletion,
    isUpdatingCourseCompletion,
    themeMode
  })
);

export const courseModalActionsSelector = createAppSelector(
  [
    (state) => state.course.details,
    (state) => state.course.isLessonCompletionSuccess,
    (state) => state.course.isCreatingLessonCompletion
  ],
  (selectedCourse, isLessonCompletionSuccess, isCreatingLessonCompletion) => ({
    selectedCourse,
    isLessonCompletionSuccess,
    isCreatingLessonCompletion
  })
);

export const courseSummarySelector = createAppSelector(
  [(state) => state.course.details],
  (selectedCourse) => selectedCourse
);
