import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialToDoState } from 'store/initial-states';
import {
  convertBrianDumpToTasks,
  fetchCalendarData,
  getToDos,
  getRecentToDos,
  searchToDos
} from './extra';
import { BrainDumpTask, ToDoTask } from 'interfaces/commonInterface';
import { INTEGRATION_PLATFORM, TO_DO_PLAYER } from 'constants/general';
import { SubTask, ToDoPlayer, ToDoPlayerTask } from 'interfaces';
import { v4 as uuid } from 'uuid';
import { formatToDoPlayerTaskTimeConstraints } from 'utils/support';

const todo = createSlice({
  name: 'to-do',
  initialState: initialToDoState,
  reducers: {
    updateTasks: (state, { payload }) => {
      state.tasks = payload;
    },
    updateIsCreatingToDo: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingToDo = payload;
    },
    updateIsFetchingToDo: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingToDo = payload;
    },
    updateIsUpdatingToDo: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingToDo = payload;
    },
    updateIsDeletingToDo: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingToDo = payload;
    },
    updateIsDeletingMultipleToDo: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingMultipleToDo = payload;
    },
    updateShouldResetNewTaskForm: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.shouldResetNewTaskForm = payload;
    },
    updateEditing: (
      state,
      { payload }: PayloadAction<{ active: boolean; task?: ToDoTask }>
    ) => {
      state.editing = payload;
    },
    updateShouldUpdateTextEditorContent: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.shouldUpdateTextEditorContent = payload;
    },
    updateIsUpdatingStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingStatus = payload;
    },
    updateShowEmbeddedCreateToDoModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showEmbeddedCreateToDoModal = payload;
    },
    updateSelectedTaskIDs: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedTaskIDs = payload;
    },
    updateIsTaskSubmitting: (state, { payload }: PayloadAction<boolean>) => {
      state.isTaskSubmitting = payload;
    },
    updateActiveTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.activeTabIndex = payload;
    },
    updateShowTodoSubTasksModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showTodoSubTasksModal = payload;
    },
    updateNewTask: (state, { payload }: PayloadAction<ToDoTask>) => {
      state.newTask = payload;
    },
    updateNewTodoPlayerTask: (
      state,
      { payload }: PayloadAction<ToDoTask | null>
    ) => {
      state.newTodoPlayerTask = payload;
    },
    updateIncomingTasks: (state, { payload }) => {
      state.incomingTasks = payload;
    },
    initFocusPlayer: (
      state,
      {
        payload: { tasks, intention, total_duration }
      }: PayloadAction<{
        tasks: ToDoPlayerTask[];
        intention: string;
        total_duration?: number;
      }>
    ) => {
      state.player.tasks = tasks;
      state.player.selectedTask = tasks?.length
        ? tasks[TO_DO_PLAYER.FIRST_TASK_INDEX]
        : undefined;
      state.player.intention = intention;
      state.player.total_duration = total_duration;
    },
    updateFocusPlayer: (state, { payload }: PayloadAction<ToDoPlayer>) => {
      state.player = payload;
    },
    updateFocusPlayerTotalDuration: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.player.total_duration = payload;
    },
    updatePlayerTasks: (
      state,
      { payload }: PayloadAction<ToDoPlayerTask[]>
    ) => {
      state.player.tasks = payload;
    },
    updateIsSearchingToDos: (state, { payload }: PayloadAction<boolean>) => {
      state.player.isSearchingToDos = payload;
    },
    updateTasksPlayedIds: (state, { payload }: PayloadAction<string[]>) => {
      state.player.tasksPlayedIds = payload;
    },
    updatePlayerSelectedTask: (
      state,
      { payload }: PayloadAction<ToDoPlayerTask | undefined>
    ) => {
      state.player.selectedTask = payload;
    },
    updateSelectedPlayerSubTasks: (
      state,
      { payload }: PayloadAction<SubTask[]>
    ) => {
      if (state.player.selectedTask) {
        state.player.selectedTask.subtasks = payload;
      }
      state.player.tasks = state.player.tasks.map((task) =>
        task.id === state.player.selectedTask?.id
          ? { ...task, subtasks: payload }
          : task
      );
    },
    updatePlayerTaskCompletion: (
      state,
      {
        payload: {
          isSelectedTaskCompleted,
          completedTask,
          tasks,
          tasksPlayedIds,
          selectedTask
        }
      }: PayloadAction<{
        isSelectedTaskCompleted: boolean;
        completedTask: ToDoPlayerTask | null;
        tasks: ToDoPlayerTask[];
        tasksPlayedIds?: string[];
        selectedTask?: ToDoPlayerTask;
      }>
    ) => {
      state.player.isSelectedTaskCompleted = isSelectedTaskCompleted;
      state.player.completedTask = completedTask;
      state.player.tasks = tasks;
      if (tasksPlayedIds) {
        state.player.tasksPlayedIds = tasksPlayedIds;
      }
      if (selectedTask) {
        state.player.selectedTask = selectedTask;
      }
    },
    updatePlayerIsReadyToSendUpdates: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.player.isReadyToSendUpdates = payload;
    },
    updateIsConvertingBrianDump: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isConvertingBrianDump = payload;
    },
    updateShowBrainDumpTasksModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showBrainDumpTasksModal = payload;
    },
    updateBrainDumpTasks: (
      state,
      { payload }: PayloadAction<BrainDumpTask[]>
    ) => {
      state.brainDumpTasks = payload;
    },
    updateIsAddingConvertedTasksToPlayer: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAddingConvertedTasksToPlayer = payload;
    },
    updatePlayerAddTask: (
      state,
      { payload }: PayloadAction<ToDoPlayerTask>
    ) => {
      state.player.tasks = formatToDoPlayerTaskTimeConstraints([
        ...state.player.tasks,
        payload
      ]);
    },
    updatePlayerSearchResults: (
      state,
      { payload }: PayloadAction<ToDoTask[] | null>
    ) => {
      state.player.searchResults = payload;
    },
    updatePlayerIntention: (state, { payload }: PayloadAction<string>) => {
      state.player.intention = payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getToDos.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          if (payload?.append) {
            state.completedTasks.push(...payload.data);
          } else {
            state.completedTasks = [
              ...state.incomingTasks,
              ...(payload?.data ?? [])
            ];
          }
        } else {
          if (payload?.append) {
            state.tasks.push(...payload.data);
          } else {
            state.tasks = [...state.incomingTasks, ...(payload?.data ?? [])];
          }
        }
        state.isFetchingToDo = false;
      })
      .addCase(fetchCalendarData.fulfilled, (state, { payload }) => {
        if (payload?.platform === INTEGRATION_PLATFORM.GOOGLE) {
          state.googleCalendarData = payload.data ?? {};
        } else {
          state.microsoftCalendarData = payload?.data ?? {};
        }
      })
      .addCase(searchToDos.fulfilled, (state, { payload }) => {
        state.player.searchResults = payload;
        state.player.isSearchingToDos = false;
      })
      .addCase(getRecentToDos.fulfilled, (state, { payload }) => {
        state.player.recentToDos = payload;
      })
      .addCase(convertBrianDumpToTasks.fulfilled, (state, { payload }) => {
        state.brainDumpTasks = payload?.map((task: BrainDumpTask) => ({
          ...task,
          selected: true,
          id: uuid()
        }));
        state.isConvertingBrianDump = false;
      });
  }
});

export const {
  updateTasks,
  updateIsCreatingToDo,
  updateIsFetchingToDo,
  updateIsUpdatingToDo,
  updateIsDeletingToDo,
  updateShouldResetNewTaskForm,
  updateEditing,
  updateShouldUpdateTextEditorContent,
  updateIsUpdatingStatus,
  updateShowEmbeddedCreateToDoModal,
  updateSelectedTaskIDs,
  updateIsTaskSubmitting,
  updateActiveTabIndex,
  updateShowTodoSubTasksModal,
  updateNewTask,
  updateIsDeletingMultipleToDo,
  updateNewTodoPlayerTask,
  updateIncomingTasks,
  initFocusPlayer,
  updateFocusPlayer,
  updateFocusPlayerTotalDuration,
  updatePlayerTasks,
  updateIsSearchingToDos,
  updateTasksPlayedIds,
  updatePlayerSelectedTask,
  updateSelectedPlayerSubTasks,
  updatePlayerTaskCompletion,
  updatePlayerIsReadyToSendUpdates,
  updateIsConvertingBrianDump,
  updateShowBrainDumpTasksModal,
  updateBrainDumpTasks,
  updateIsAddingConvertedTasksToPlayer,
  updatePlayerAddTask,
  updatePlayerSearchResults,
  updatePlayerIntention
} = todo.actions;
export default todo.reducer;
