import CloseCircle from 'assets/icons/CloseCircle';
import Button from 'components/common/buttons/Button';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import COLOR from 'constants/color';
import Tabs from 'components/setting-generator/tabs';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateShowEditSuggestionsModal } from 'store/reducer/setting/slice';
import { isDarkModeActivated } from 'utils/validation';
import { themeSelector } from 'store/reducer/setting/selectors';

const EditSuggestionsModal = () => {
  const dispatch = useAppDispatch();
  return (
    <ModalOverlay styles='w-screen h-screen bg-black/80'>
      <ModalWrapper
        mainStyles={`relative w-[95vw] sm:w-[90vw] min-h-[30vh] h-fit max-h-[90vh] top-1/4 sm:top-1/2 -translate-y-1/4 sm:-translate-y-1/2 mx-auto ${isDarkModeActivated(useAppSelector(themeSelector)) ? 'bg-gray-500 text-white' : 'bg-whiteLace text-black'} px-2.5 md:px-10 py-3 sm:py-6 rounded-md flex flex-col items-center justify-center`}
      >
        <div className='w-full h-fit max-h-[85vh] flex flex-col justify-between gap-8 sm:gap-4 overflow-y-auto scrollbar-thin'>
          <Tabs />
        </div>
        <Button
          className='bg-sunRay rounded-full w-fit absolute top-0.5 right-0.5'
          icon={<CloseCircle fill={COLOR.BLACK} fillDarkMode={COLOR.RED} />}
          onClick={() => {
            dispatch(updateShowEditSuggestionsModal(false));
          }}
        />
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default EditSuggestionsModal;
