import { MinusCircle } from 'assets/icons/MinusCircle';
import PauseFilled from 'assets/icons/PauseFilled';
import PlayFilled from 'assets/icons/PlayFilled';
import PlusCircle from 'assets/icons/PlusCircle';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import COLOR from 'constants/color';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import {
  DEFAULT_TODO_TASK_DURATION,
  TIME_UNIT_CONVERT_FACTOR,
  TO_DO_PLAYER_TASK_BTN_DURATION,
  TO_DO_SUB_TASK_WHERE
} from 'constants/general';
import { INTRO_TO_DO_PLAYER_STEPS } from 'constants/intro';
import { t } from 'i18next';
import moment from 'moment';
import { ToDoPlayerContext } from 'pages/to-do/toDoPlayer';
import { useContext } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateTodoSubTasks } from 'store/reducer/modal/slice';
import { updateIsUserOnboarding } from 'store/reducer/setting/slice';
import { selectedTaskActionsSelector } from 'store/reducer/to-do/selectors';
import {
  updatePlayerSelectedTask,
  updatePlayerTasks,
  updateShowTodoSubTasksModal
} from 'store/reducer/to-do/slice';
import { decrement, increment } from 'utils/support';
import PlayerIntention from '../PlayerIntention';
import PlayerTaskStatus from '../PlayerTaskStatus';

const SelectedTaskActions = () => {
  const dispatch = useAppDispatch();
  const { player, isUserOnboarding } = useAppSelector(
    selectedTaskActionsSelector
  );
  const { playerData, setPlayerData } = useContext(ToDoPlayerContext);
  const shouldDisablePlayAndPauseBtn = !player.selectedTask?.duration;
  const shouldDisableDecrementMinuteBtn =
    (player.selectedTask?.duration ?? DEFAULT_TODO_TASK_DURATION) <=
    DEFAULT_TODO_TASK_DURATION;

  const handleViewSubTasks = () => {
    dispatch(
      updateTodoSubTasks({
        title: player.selectedTask?.title,
        subTasks: player.selectedTask?.subtasks ?? [],
        isNewTask: true,
        taskId: player.selectedTask?.id,
        where: TO_DO_SUB_TASK_WHERE.TO_DO_PLAYER
      })
    );
    dispatch(updateShowTodoSubTasksModal(true));
  };

  const handleDuration = (action?: string) => {
    const selectedTaskId = player.selectedTask?.id;

    let taskInTheListDuration =
      player.tasks.find((playerTask) => playerTask.id === selectedTaskId)
        ?.duration ?? DEFAULT_TODO_TASK_DURATION;
    let playingTaskDuration;

    if (action) {
      const updatedTaskInTheListDuration = decrement(
        taskInTheListDuration,
        TIME_UNIT_CONVERT_FACTOR.ONE_MINUTE_AS_SECONDS
      );
      const updatedPlayingTaskDuration = decrement(
        playerData.counter,
        TIME_UNIT_CONVERT_FACTOR.ONE_MINUTE_AS_SECONDS
      );
      taskInTheListDuration =
        updatedTaskInTheListDuration < DEFAULT_TODO_TASK_DURATION
          ? DEFAULT_TODO_TASK_DURATION
          : updatedTaskInTheListDuration;
      playingTaskDuration =
        updatedPlayingTaskDuration < DEFAULT_TODO_TASK_DURATION
          ? DEFAULT_TODO_TASK_DURATION
          : updatedPlayingTaskDuration;
    } else {
      taskInTheListDuration = increment(
        parseInt(taskInTheListDuration.toString()),
        TIME_UNIT_CONVERT_FACTOR.ONE_MINUTE_AS_SECONDS
      );
      playingTaskDuration = increment(
        playerData.counter,
        TIME_UNIT_CONVERT_FACTOR.ONE_MINUTE_AS_SECONDS
      );
    }
    player.selectedTask &&
      dispatch(
        updatePlayerSelectedTask({
          ...player.selectedTask,
          duration: playingTaskDuration
        })
      );

    dispatch(
      updatePlayerTasks(
        player.tasks.map((playerTask) =>
          playerTask.id === selectedTaskId
            ? {
                ...playerTask,
                duration: taskInTheListDuration
              }
            : playerTask
        )
      )
    );
  };

  return (
    <>
      <Joyride
        steps={INTRO_TO_DO_PLAYER_STEPS.FOURTH}
        continuous={true}
        run={isUserOnboarding}
        showProgress={true}
        scrollToFirstStep={true}
        callback={(data) => {
          data.status.includes(STATUS.FINISHED) &&
            dispatch(updateIsUserOnboarding(false));
        }}
      />

      <div className='w-full flex flex-col gap-3'>
        {player.intention && <PlayerIntention />}
        <div className='w-full flex items-center gap-2'>
          <p className='w-[55%] text-right text-3xl sm:text-4xl md:text-5xl font-digital tracking-widest'>
            {moment()
              .startOf('date')
              .add(playerData.counter, 'seconds')
              .format(
                playerData.counter >=
                  TIME_UNIT_CONVERT_FACTOR.ONE_HOUR_AS_SECONDS
                  ? 'hh:mm:ss'
                  : 'mm:ss'
              )}
          </p>
          <button
            disabled={shouldDisablePlayAndPauseBtn}
            className='outline-none if_you_finish_the_task_early'
            onClick={() =>
              setPlayerData?.((prev) => ({
                ...prev,
                isPlayingStarted: prev.isPlaying ? prev.isPlayingStarted : true,
                isPlaying: !prev.isPlaying,
                counter: prev.isPlayingStarted
                  ? prev.counter
                  : decrement(prev.counter)
              }))
            }
          >
            {playerData.isPlaying ? (
              <PauseFilled
                fill={
                  shouldDisablePlayAndPauseBtn ? COLOR.GRAY_5OO : COLOR.BLACK
                }
              />
            ) : (
              <PlayFilled
                fill={
                  shouldDisablePlayAndPauseBtn ? COLOR.GRAY_5OO : COLOR.BLACK
                }
              />
            )}
          </button>
          <div className='flex flex-col gap-1 items-center animate-fadeIn'>
            <button onClick={() => handleDuration()}>
              <PlusCircle
                styles='w-4 h-auto'
                fill={COLOR.BLACK}
                fillDarkMode={COLOR.BLACK}
              />
            </button>
            <button
              disabled={shouldDisableDecrementMinuteBtn}
              onClick={() =>
                handleDuration(
                  TO_DO_PLAYER_TASK_BTN_DURATION.DECREMENT_A_MINUTE
                )
              }
            >
              <MinusCircle
                fill={
                  shouldDisableDecrementMinuteBtn ? COLOR.GRAY_4OO : COLOR.BLACK
                }
                styles='w-4 h-auto'
              />
            </button>
          </div>
        </div>
        <p className='line-clamp-2 text-center px-2 text-sm xs:text-base md:text-lg'>
          {player?.selectedTask?.title}
        </p>
        <div className='w-fit flex items-center gap-2 self-center'>
          <ButtonFocusBear
            title={t('to_do_procrastinate.view_sub_tasks')}
            onClick={handleViewSubTasks}
            size={BTN_FB_SIZE.SMALL}
            variant={BTN_FB_VARIANT.TERTIARY}
            additionalStyles='min-w-max'
          />
          {player.selectedTask && !playerData.isPlaying && (
            <PlayerTaskStatus task={player.selectedTask} />
          )}
        </div>
      </div>
      <div className='w-full flex items-center justify-between px-4 py-2'>
        <p className='min-w-max bg-gray-600 text-white px-2 py-1 rounded-l-md text-[0.6rem] shadow font-medium'>{`${t('to_do_player.start')}: ${moment(player.selectedTask?.startTime, 'h:mm a').format('h:mm a')}`}</p>
        <progress
          className='w-full h-5 [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:bg-orange-400 [&::-moz-progress-bar]:bg-orange-400'
          value={
            (player.selectedTask?.duration ?? DEFAULT_TODO_TASK_DURATION) -
            playerData.counter
          }
          max={player.selectedTask?.duration ?? DEFAULT_TODO_TASK_DURATION}
        ></progress>
        <p className='min-w-max bg-gray-600 text-white px-2 py-1 rounded-r-md text-[0.6rem] shadow font-medium'>{`${t('to_do_player.end')}: ${moment(player.selectedTask?.endTime, 'h:mm a').format('h:mm a')}`}</p>
      </div>
    </>
  );
};
export default SelectedTaskActions;
