import { Dispatch, SetStateAction, useContext } from 'react';
import CloseCircle from 'assets/icons/CloseCircle';
import Filter from 'assets/icons/Filter';
import PlusCircle from 'assets/icons/PlusCircle';
import COLOR from 'constants/color';
import {
  COMPLETED_TAB,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_TAB_LAYOUT_TAB,
  PAGE
} from 'constants/general';
import { DropDownSelectedValue } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateEditing,
  updateSelectedTaskIDs,
  updateShowEmbeddedCreateToDoModal
} from 'store/reducer/to-do/slice';
import { isEmbeddedTodoList } from 'utils/validation';
import { TasksContext } from '..';
import CheckedFilled from 'assets/icons/CheckedFilled';
import { updateTabLayoutActiveTab } from 'store/reducer/general/slice';
import Tooltip from 'components/common/Tooltip';
import Matrix from 'assets/icons/Matrix';
import { updateEisenhowerMatrixIsEisenhowerMatrixModalOpened } from 'store/reducer/modal/slice';
import TrashFilled from 'assets/icons/TrashFilled';
import { deleteToDo, getToDos } from 'store/reducer/to-do/extra';
import { toast } from 'react-toastify';
import CsvFile from 'assets/icons/CsvFile';
import { downloadToDoTasksCSVFile } from 'utils/downloadFiles';
import { t } from 'i18next';
import { filterActionsSelector } from 'store/reducer/to-do/selectors';
import classNames from 'classnames';
import SearchToDos from './SearchToDos';
import {
  updatePaginationNavigation,
  updatePaginationTotalPages
} from 'store/reducer/pagination/slice';

export interface FilterDataProps {
  taskName: string;
  quadrant: DropDownSelectedValue | null;
  project: DropDownSelectedValue | null;
  focusMode: DropDownSelectedValue | null;
  status: DropDownSelectedValue | null;
}

interface Props {
  icon?: JSX.Element;
  toolTipMessage?: string;
  handleClick: () => void;
  disabled?: boolean;
}

const ActionBtn = ({ icon, toolTipMessage, handleClick, disabled }: Props) => {
  const { shouldActivateDarkMode } = useContext(TasksContext);
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={classNames(
        'btn-primary flex items-center justify-center gap-1 border',
        {
          'bg-gray-600 hover:bg-gray-600': shouldActivateDarkMode,
          'bg-white hover:bg-white': !shouldActivateDarkMode,
          'p-0': toolTipMessage === t('completed_tasks'),
          'p-1': toolTipMessage !== t('completed_tasks')
        },
        'hover:scale-105 ease-in-out duration-200'
      )}
    >
      {icon ? (
        <Tooltip icon={icon} message={toolTipMessage} place='right-start' />
      ) : (
        <div
          className={`w-5 h-5 border-b ${
            shouldActivateDarkMode ? 'border-white' : 'border-gray-700'
          } animate-spin rounded-full mx-1`}
        ></div>
      )}
    </button>
  );
};

const FiltersActions = ({
  showFilters,
  setShowFilters
}: {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const {
    activeTab,
    selectedTaskIDs,
    isDeletingMultipleToDo,
    tasks,
    platform,
    completedTasks,
    isFetchingToDo
  } = useAppSelector(filterActionsSelector);

  const shouldEmbeddedFocusIU = isEmbeddedTodoList(window.location.pathname);
  const shouldDisableAction =
    activeTab === COMPLETED_TAB || isDeletingMultipleToDo;

  const actions = [
    {
      icon: <PlusCircle styles='w-4 h-auto' fill={COLOR.GRAY_600} />,
      toolTipMessage: t('add'),
      disabled: shouldDisableAction,
      handleClick: () => {
        dispatch(updateEditing({ active: false }));
        dispatch(updateShowEmbeddedCreateToDoModal(true));
      }
    },
    {
      icon: <CsvFile fill={COLOR.BLACK} styles='w-4 h-auto' />,
      toolTipMessage: t('to_do_procrastinate.export'),
      disabled: shouldDisableAction,
      handleClick: () => downloadToDoTasksCSVFile(tasks, platform)
    },
    {
      icon: <Matrix styles='w-4 h-auto' fill={COLOR.GRAY_600} />,
      toolTipMessage: t('to_do_procrastinate.view_eisenhower_matrix'),
      disabled: shouldDisableAction,
      handleClick: () =>
        dispatch(updateEisenhowerMatrixIsEisenhowerMatrixModalOpened(true))
    },
    {
      icon: (
        <CheckedFilled
          styles='w-6 h-auto'
          fill={
            shouldDisableAction && !isDeletingMultipleToDo
              ? COLOR.GREEN_500
              : COLOR.GRAY_3OO
          }
        />
      ),
      toolTipMessage: t('completed_tasks'),
      disabled: isDeletingMultipleToDo || isFetchingToDo,
      handleClick: () => {
        if (activeTab === DEFAULT_TAB_LAYOUT_TAB) {
          setShowFilters(false);
          dispatch(
            updatePaginationTotalPages(
              completedTasks.length
                ? Math.ceil(completedTasks.length / DEFAULT_ITEMS_PER_PAGE)
                : PAGE.INIT_TOTAL
            )
          );
        } else {
          dispatch(
            updatePaginationTotalPages(
              tasks.length
                ? Math.ceil(tasks.length / DEFAULT_ITEMS_PER_PAGE)
                : PAGE.INIT_TOTAL
            )
          );
        }
        dispatch(
          updatePaginationNavigation({
            hasPreviousPage: false,
            hasNextPage: true
          })
        );
        dispatch(
          updateTabLayoutActiveTab(
            activeTab === DEFAULT_TAB_LAYOUT_TAB
              ? COMPLETED_TAB
              : DEFAULT_TAB_LAYOUT_TAB
          )
        );
      }
    },
    {
      icon: showFilters ? (
        <CloseCircle styles='w-4 h-auto' fill={COLOR.GRAY_600} />
      ) : (
        <Filter styles='w-4 h-auto' fill={COLOR.GRAY_600} />
      ),
      toolTipMessage: t('to_do_procrastinate.filter'),
      disabled: shouldDisableAction,
      handleClick: () => setShowFilters((prev) => !prev)
    },
    {
      icon: !isDeletingMultipleToDo ? (
        <TrashFilled styles='w-4 h-auto' fill={COLOR.GRAY_600} />
      ) : undefined,
      toolTipMessage: isDeletingMultipleToDo
        ? undefined
        : t('delete_selected_tasks'),
      disabled: shouldDisableAction,
      handleClick: async () => {
        const results = await Promise.allSettled(
          selectedTaskIDs.map((taskIds) =>
            dispatch(deleteToDo({ task_id: taskIds, is_multiple: true }))
          )
        );
        if (results.filter((result) => result.status === 'fulfilled')?.length) {
          dispatch(updateSelectedTaskIDs([]));
          dispatch(getToDos({ page: PAGE.FIRST, append: false }));
        } else {
          toast.error(t('could_not_process_the_request'));
        }
      }
    }
  ];

  return (
    <div className='w-full flex items-center gap-2'>
      <SearchToDos />
      {actions.map(({ icon, toolTipMessage, disabled, handleClick }) => {
        if (
          (toolTipMessage === t('add') && !shouldEmbeddedFocusIU) ||
          (toolTipMessage === t('delete_selected_tasks') &&
            !selectedTaskIDs.length)
        ) {
          return null;
        }
        return (
          <ActionBtn
            key={toolTipMessage}
            handleClick={handleClick}
            icon={icon}
            toolTipMessage={toolTipMessage}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default FiltersActions;
