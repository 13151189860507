import classNames from 'classnames';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import OverlaySpinner from 'components/OverlaySpinner';
import { BTN_FB_SIZE, BTN_FB_VARIANT } from 'constants/enum';
import { t } from 'i18next';
import { ToDoFocusEndContext } from 'pages/to-do/toDoFocusEnd';
import React, { PropsWithChildren, useCallback, useContext } from 'react';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';
import { toDoFocusEndTasksSelector } from 'store/reducer/user/selectors';
import { convertToSeconds } from 'utils/support';
import { isDarkModeActivated } from 'utils/validation';
import TodoFocusEndSaveBtn from './TodoFocusEndSaveBtn';
import TodoFocusEndTask from './TodoFocusEndTask';

const Wrapper = ({ children }: PropsWithChildren<object>) => {
  const shouldActivateDarkMode = isDarkModeActivated(
    useAppSelector(themeSelector)
  );
  return (
    <section
      className={classNames(
        'w-full h-screen flex flex-col items-center rounded shadow-md px-5 pb-3 border-t relative',
        {
          'bg-gray-600 border-gray-500 text-white': shouldActivateDarkMode,
          'border-gray-300 text-black': !shouldActivateDarkMode
        }
      )}
    >
      {children}
    </section>
  );
};

const TasksWrapper = ({ children }: PropsWithChildren<object>) => (
  <div className='w-full h-fit max-h-[95%] grid grid-cols-1 2xl:grid-cols-2 gap-y-5 gap-x-7 px-3 py-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400'>
    {children}
  </div>
);

const TodoFocusEndListOfTasks = () => {
  const { incomingTasks, setIncomingTasks } = useContext(ToDoFocusEndContext);
  const { isUpdatingStatus, themeMode } = useAppSelector(
    toDoFocusEndTasksSelector
  );
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const handleDuration = useCallback(
    (id: string) =>
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setIncomingTasks?.((prev) => {
          return (prev ?? []).map((task) =>
            task.id === id
              ? {
                  ...task,
                  focusedDuration: convertToSeconds(parseFloat(value))
                }
              : task
          );
        });
      },
    []
  );

  const handleNote = useCallback(
    (id: string) =>
      ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIncomingTasks?.((prev) =>
          (prev ?? []).map((task) =>
            task.id === id ? { ...task, note: value } : task
          )
        );
      },
    []
  );

  return (
    <Wrapper>
      <p className='w-3/4 my-4 capitalize text-sm lg:text-base text-center'>
        {t('to_do_procrastinate.mark_the_tasks')}
      </p>
      {incomingTasks?.length ? (
        <TasksWrapper>
          {incomingTasks.map((task) => (
            <TodoFocusEndTask
              key={task.id}
              task={task}
              handleDuration={handleDuration}
              handleNote={handleNote}
            />
          ))}
        </TasksWrapper>
      ) : (
        <div
          className={classNames(
            'w-11/12 sm:w-9/12 md:w-6/12 flex flex-col items-center justify-center text-center gap-3 px-10 py-20 rounded-md shadow-md',
            {
              'bg-sunRayFifth': !shouldActivateDarkMode,
              'bg-gray-700': shouldActivateDarkMode
            }
          )}
        >
          {incomingTasks === null ? (
            <p className='italic'>{t('failed_to_get_tasks')}</p>
          ) : (
            <p className='italic'>{t('no_tasks_were_found')}</p>
          )}
          {incomingTasks == null && (
            <ButtonFocusBear
              title={t('reload_the_page')}
              onClick={() => window.location.reload()}
              variant={BTN_FB_VARIANT.SECONDARY}
              size={BTN_FB_SIZE.SMALL}
            />
          )}
        </div>
      )}
      {incomingTasks?.length ? <TodoFocusEndSaveBtn /> : null}
      {isUpdatingStatus && <OverlaySpinner />}
    </Wrapper>
  );
};

export default TodoFocusEndListOfTasks;
