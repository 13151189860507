import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateSelectedTaskIDs } from 'store/reducer/to-do/slice';
import { COMPLETED_TAB, EISENHOWER_QUADRANT } from 'constants/general';
import { ToDoTask } from 'interfaces/commonInterface';
import CheckBox from 'components/common/CheckBox';
import { taskSelector } from 'store/reducer/to-do/selectors';
import classNames from 'classnames';
import TaskItemHeader from './TaskItemHeader';
import TaskItemBody from './TaskItemBody';
import { deleteToDo } from 'store/reducer/to-do/extra';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';

const TaskItem = ({ task }: { task: ToDoTask }) => {
  const dispatch = useAppDispatch();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { selectedTaskIDs, activeTab } = useAppSelector(taskSelector);

  return (
    <div className='w-full h-fit flex items-center gap-3 pl-1 py-2 relative'>
      {activeTab !== COMPLETED_TAB && (
        <CheckBox
          checked={selectedTaskIDs.includes(task.id)}
          onChange={() => {
            selectedTaskIDs.includes(task.id)
              ? dispatch(
                  updateSelectedTaskIDs(
                    selectedTaskIDs.filter((id) => task.id !== id)
                  )
                )
              : dispatch(updateSelectedTaskIDs([...selectedTaskIDs, task.id]));
          }}
          inputStyles='cursor-pointer outline-none w-3 sm:w-5 h-3 sm:h-5'
        />
      )}
      <div
        className={classNames(
          'w-full flex flex-col rounded-md shadow-sm relative p-1.5',
          {
            'bg-red-200':
              String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DO,
            'bg-orange-200':
              String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DECIDE,
            'bg-green-200':
              String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELEGATE,
            'bg-blue-200':
              String(task.eisenhower_quadrant) === EISENHOWER_QUADRANT.DELETE
          }
        )}
      >
        <TaskItemHeader
          task={task}
          toggleDropdown={toggleDropdown}
          setToggleDropdown={setToggleDropdown}
        />
        <TaskItemBody task={task} toggleDropdown={toggleDropdown} />
      </div>
      <button
        onClick={() => {
          dispatch(deleteToDo({ task_id: task.id }));
        }}
        className='absolute top-0 -right-2 w-fit h-fit'
      >
        <CloseCircle styles='w-5 h-auto' fill={COLOR.RED} />
      </button>
    </div>
  );
};

export default TaskItem;
