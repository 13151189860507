import { DetailedHTMLProps, InputHTMLAttributes, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { isDarkModeActivated } from 'utils/validation';
import Tooltip from '../Tooltip';
import classNames from 'classnames';
import { PlacesType } from 'react-tooltip';

interface InputTimeProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  selectedDate: string;
  setSelectedDate?: (value: string) => void;
  errorMessages?: string[];
  validateTime?: () => boolean;
  wrapperStyles?: string;
  label?: string;
  inputStyles?: string;
  tooltipMessage?: string;
  tooltipIcon?: JSX.Element;
  labelStyles?: string;
  toolTipAlignment?: PlacesType;
}

const InputTime = ({
  selectedDate,
  setSelectedDate,
  errorMessages,
  validateTime,
  wrapperStyles = 'w-fit h-fit flex flex-col gap-1',
  label,
  inputStyles = 'w-fit text-xs sm:text-sm font-medium px-1.5 py-1 rounded-md border outline-none',
  tooltipMessage,
  tooltipIcon,
  labelStyles = 'text-sm sm:text-base',
  toolTipAlignment,
  ...rest
}: InputTimeProps) => {
  const { themeMode } = useAppSelector((state) => state.setting);
  const shouldActivateDarkMode = isDarkModeActivated(themeMode);

  const hasErrorMessage = useMemo(
    () => (validateTime ? validateTime() : !selectedDate),
    [selectedDate, validateTime]
  );

  return (
    <div className={wrapperStyles}>
      {label && (
        <label
          className={`w-fit h-fit whitespace-break-spaces flex items-center gap-2 ${labelStyles}`}
        >
          {label}
          {tooltipMessage && (
            <Tooltip
              message={tooltipMessage}
              iconStyles='w-4 sm:w-5 h-auto relative'
              icon={tooltipIcon}
              place={toolTipAlignment}
            />
          )}
        </label>
      )}
      <input
        type='time'
        value={selectedDate}
        onChange={({ target: { value } }) => setSelectedDate?.(value)}
        className={classNames(`${inputStyles} text-black`, {
          'border-gray-500 bg-gray-400 focus:bg-gray-300':
            shouldActivateDarkMode,
          'border-gray-400 bg-focusBear focus:bg-white': !shouldActivateDarkMode
        })}
        {...rest}
      />
      {errorMessages?.length && hasErrorMessage ? (
        <ul className='relative mt-auto'>
          {errorMessages.map((error) => (
            <li key={error} className='w-fit text-xs text-red-500 py-1'>
              {error}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default InputTime;
