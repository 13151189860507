import { DEFAULT_TAB_LAYOUT_TAB, ROUTINE } from 'constants/general';
import { t } from 'i18next';
import { ActivityType } from 'interfaces/commonInterface';
import moment from 'moment';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { routineActivitiesSelector } from 'store/reducer/setting/selectors';
import { getDurationFormat } from 'utils/support';
import RoutineSuggestion from './RoutineSuggestion';

const RoutineSuggestions = ({ activities }: { activities: ActivityType[] }) => {
  const { activeTab, routines, routineSuggestions } = useAppSelector(
    routineActivitiesSelector
  );

  const routine_duration = useMemo(() => {
    const { morning_duration, evening_duration } = activities.reduce(
      (total, suggestion) =>
        suggestion.activity_type === ROUTINE.MORNING
          ? {
              ...total,
              morning_duration:
                total.morning_duration +
                parseInt(suggestion.duration_seconds?.toString())
            }
          : {
              ...total,
              evening_duration:
                total.evening_duration +
                parseInt(suggestion.duration_seconds?.toString())
            },
      { morning_duration: 0, evening_duration: 0 }
    );

    return routines.some(
      (routine) =>
        routine.value && routine.title.toLowerCase().includes(ROUTINE.MORNING)
    ) && activeTab === DEFAULT_TAB_LAYOUT_TAB
      ? moment()
          .startOf('date')
          .add(morning_duration, 'seconds')
          .format(getDurationFormat(morning_duration))
      : moment()
          .startOf('date')
          .add(evening_duration, 'seconds')
          .format(getDurationFormat(evening_duration));
  }, [activeTab, routineSuggestions]);

  return (
    <div className='w-full flex flex-col'>
      {activities?.map((suggestion) => (
        <RoutineSuggestion key={suggestion.id} suggestion={suggestion} />
      ))}
      <p className='text-xs self-end capitalize'>
        {t('routine_suggestion.total_routine_duration', {
          routine_duration
        })}
      </p>
    </div>
  );
};
export default RoutineSuggestions;
