import { isUserInternalTest } from './support';
import { CaptureOptions } from 'posthog-js';
import { PostHogPayload } from 'interfaces';
import postHog from 'services/posthog';

export const postHogCapture = (
  event: string,
  userEmail: string,
  data?: PostHogPayload,
  options?: CaptureOptions
) => {
  if (!isUserInternalTest(userEmail)) {
    postHog.capture(
      event,
      {
        ...data
      },
      options
    );
  }
};
