import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TASK_PROJECT } from 'constants/general';
import CloseCircle from 'assets/icons/CloseCircle';
import COLOR from 'constants/color';
import { ToDoTask } from 'interfaces/commonInterface';

const TaskProjects = ({ task }: { task: ToDoTask }) => {
  const { t } = useTranslation();
  const [showProjects, setShowProjects] = useState(false);
  const showProjectsList =
    task.tags.length > TASK_PROJECT.MAXIMUM_NUMBER_OF_TASKS ||
    task.tags.some(
      (tag) => tag.text?.length > TASK_PROJECT.MAXIMUM_NUMBER_OF_CHARACTERS
    );

  return (
    <div className='w-fit flex items-center gap-1.5 relative'>
      <p className='min-w-max text-xs'>{t('to_do_procrastinate.projects')}</p>
      <button
        disabled={!showProjectsList}
        onClick={(event) => {
          event.stopPropagation();
          setShowProjects(true);
        }}
        className='w-fit max-w-[6rem] px-2 bg-gray-600 text-white rounded-md leading-5 truncate disabled:cursor-default cursor-pointer'
      >
        {task.tags.map((tag) => tag.text).join()}
      </button>
      {showProjects && (
        <>
          <div className='absolute top-0 right-0 bg-gray-100 z-40 w-36 h-fit max-h-20 rounded-md p-2 flex flex-col gap-1.5 overflow-y-auto scrollbar-thin'>
            {task.tags.map((tag) => (
              <p
                key={tag.id}
                className='w-fit px-2 py-0.5 text-[10px] bg-gray-600 text-white rounded-md leading-4'
              >
                {tag.text}
              </p>
            ))}
          </div>
          <button
            onClick={(event) => {
              event.stopPropagation();
              setShowProjects(false);
            }}
            className='absolute -top-2 -right-1.5 z-50'
          >
            <CloseCircle fill={COLOR.GRAY_700} styles='w-6 h-auto' />
          </button>
        </>
      )}
    </div>
  );
};

export default TaskProjects;
