import { DEFAULT_LESSON_INFO } from 'assets/data';
import classNames from 'classnames';
import ModalHeader from 'components/shared/ModalHeader';
import ModalOverlay from 'components/shared/ModalOverlay';
import ModalWrapper from 'components/shared/ModalWrapper';
import { THEME_OPTION } from 'constants/enum';
import {
  FIRST_LESSON_OFFSET,
  ITEM_INDEX_NOT_FOUND,
  MODAL_TYPES
} from 'constants/general';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAppSelector } from 'store/hooks';
import { courseLessonsModalSelector } from 'store/reducer/course/selectors';
import CourseLessonItem from './CourseLessonItem';
import CourseSpinner from './CourseSpinner';
import CourseSummary from './CourseSummary';
import ListOfLessons from './ListOfLessons';
import CourseModalActions from './CourseModalActions';

interface LessonInfo {
  currentLesson: number;
  lastCompletedLesson: number;
  showContents: boolean;
  isLastLesson: boolean;
}

export const CourseLessonsModalContext = createContext<{
  lessonInfo: LessonInfo;
  setLessonInfo?: Dispatch<SetStateAction<LessonInfo>>;
}>({
  lessonInfo: DEFAULT_LESSON_INFO
});

const CourseLessonsModal = () => {
  const {
    selectedCourse,
    isCreatingLessonCompletion,
    isUpdatingCourseCompletion,
    themeMode
  } = useAppSelector(courseLessonsModalSelector);
  const [lessonInfo, setLessonInfo] = useState<LessonInfo>(DEFAULT_LESSON_INFO);
  const lessons = selectedCourse?.lessons ?? [];

  useEffect(() => {
    if (
      selectedCourse?.lessonCompletions.length !==
      selectedCourse?.lessons.length
    ) {
      const index = lessons?.findIndex((lesson) =>
        selectedCourse?.lessonCompletions?.every(
          (completion) => completion.lesson_id !== lesson.id
        )
      );
      const lessonIndex = !selectedCourse?.lessonCompletions.length
        ? FIRST_LESSON_OFFSET
        : index;
      setLessonInfo?.((prev) => ({
        ...prev,
        currentLesson: lessonIndex,
        lastCompletedLesson: lessonIndex,
        isLastLesson: lessonIndex === ITEM_INDEX_NOT_FOUND
      }));
    } else {
      setLessonInfo?.((prev) => ({
        ...prev,
        currentLesson: ++prev.currentLesson,
        isLastLesson: true
      }));
    }
  }, []);

  return (
    <ModalOverlay>
      <ModalWrapper
        mainStyles={classNames(
          'relative top-1/2 -translate-y-1/2 w-11/12 md:w-10/12 h-[90vh] md:h-[80vh] flex flex-col border-2 border-orange-300 rounded-lg p-3 shadow-[4px_4px_0px_0px_rgba(0,0,0,0.2)] mx-auto',
          {
            'bg-white': themeMode === THEME_OPTION.LIGHT,
            'bg-gray-400': themeMode === THEME_OPTION.DARK
          }
        )}
        additionalStyles='gap-1'
      >
        <ModalHeader
          modalType={MODAL_TYPES.COURSE_LESSONS_MODAL}
          title={selectedCourse?.name}
        />
        <CourseLessonsModalContext.Provider
          value={useMemo(() => ({ lessonInfo, setLessonInfo }), [lessonInfo])}
        >
          {lessonInfo.showContents ? (
            <div className='w-full h-full flex relative overflow-hidden'>
              {lessonInfo.isLastLesson ? (
                <CourseSummary />
              ) : (
                <>
                  {lessons?.map((lesson) => (
                    <CourseLessonItem key={lesson.id} lesson={lesson} />
                  ))}
                  <CourseModalActions />
                </>
              )}
              {(isCreatingLessonCompletion || isUpdatingCourseCompletion) && (
                <CourseSpinner />
              )}
            </div>
          ) : (
            <ListOfLessons lessons={lessons} />
          )}
        </CourseLessonsModalContext.Provider>
      </ModalWrapper>
    </ModalOverlay>
  );
};

export default CourseLessonsModal;
