import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import CheckBox from 'components/common/CheckBox';
import { BTN_FB_SIZE, THEME_OPTION } from 'constants/enum';
import { t } from 'i18next';
import { CourseLesson } from 'interfaces';
import { Fragment, useContext, useEffect, useRef } from 'react';
import { CourseLessonsModalContext } from '.';
import { FIRST_LESSON_OFFSET } from 'constants/general';
import classNames from 'classnames';
import { useAppSelector } from 'store/hooks';
import { themeSelector } from 'store/reducer/setting/selectors';

const ListOfLessons = ({ lessons }: { lessons: CourseLesson[] }) => {
  const themeMode = useAppSelector(themeSelector);
  const divRef = useRef<HTMLDivElement>(null);
  const {
    lessonInfo: { currentLesson },
    setLessonInfo
  } = useContext(CourseLessonsModalContext);
  const btnTitle =
    currentLesson === FIRST_LESSON_OFFSET
      ? t('course.start')
      : t('course.next_lesson');

  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [currentLesson]);

  return (
    <div
      className={classNames(
        'h-[92%] flex flex-col gap-3 justify-between px-4 py-2',
        {
          'bg-transparent': themeMode === THEME_OPTION.LIGHT,
          'bg-gray-600': themeMode === THEME_OPTION.DARK
        }
      )}
    >
      {lessons?.length ? (
        <ul className='w-full h-fit max-h-[91%] overflow-y-auto scrollbar-thin scroll-mb-10'>
          {lessons.map((lesson, idx) => (
            <Fragment key={lesson.id}>
              <li className='flex gap-2 py-2'>
                <CheckBox
                  checked={currentLesson > idx}
                  readOnly
                  inputStyles='w-4 sm:w-5 h-4 sm:h-5 cursor-pointer outline-none accent-sunRayFirst'
                />
                <button
                  onClick={() =>
                    setLessonInfo?.((prev) => ({
                      ...prev,
                      currentLesson: idx,
                      showContents: true
                    }))
                  }
                >
                  <p className='w-full truncate text-base xs:text-lg md:text-xl'>
                    {lesson.title}
                  </p>
                </button>
              </li>
              {currentLesson === idx && (
                <div ref={divRef} className='float-left clear-both'></div>
              )}
            </Fragment>
          ))}
        </ul>
      ) : (
        <p className='text-sm mx-auto py-6'>{t('course.no_lesson_found')}</p>
      )}
      <ButtonFocusBear
        title={btnTitle}
        size={BTN_FB_SIZE.SMALL}
        additionalStyles='self-end'
        onClick={() =>
          setLessonInfo?.((prev) => ({ ...prev, showContents: true }))
        }
      />
    </div>
  );
};

export default ListOfLessons;
