import {
  DEFAULT_STEP_TO_STEP_NUMBER_MAPPING,
  DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_MICRO_BREAKS,
  FOCUS_MODE_STEP_TO_STEP_NUMBER_MAPPING,
  FREE_STYLE_STEP_TO_STEP_NUMBER_MAPPING,
  HABIT_PACK_STEP_TO_STEP_NUMBER_MAPPING,
  ROUTINE_STEP_TO_STEP_NUMBER_MAPPING,
  STANDALONE_STEP_TO_STEP_NUMBER_MAPPING
} from 'components/setting-generator/tabs-body-steps/content-body-types';
import { Mode, SUGGESTED_PACK } from 'constants/enum';
import {
  DEFAULT_HABIT_DURATION,
  EMPTY_STRING,
  PLATFORMS,
  ROUTINE
} from 'constants/general';
import { ATTRIB } from 'constants/test';
import { store } from 'store';
import { getRoutineValue } from './support';
import {
  ActivityType,
  ActivityTypeWithPackTitle,
  HabitPackType
} from 'interfaces/commonInterface';
import moment from 'moment';

export const getSettingPageTabContent = (
  mode: Mode,
  index: number,
  customRoutine: string,
  platform: string
) => {
  let body, testIds;
  switch (mode) {
    case Mode.FREESTYLE:
      body = FREE_STYLE_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      testIds = ATTRIB.TEST.HEADERS.FREESTYLE;
      break;
    case Mode.ROUTINE:
      body = HABIT_PACK_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      testIds = ATTRIB.TEST.HEADERS.ROUTINE;
      break;
    case Mode.STANDALONE:
      body = STANDALONE_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      testIds = ATTRIB.TEST.HEADERS.STANDALONE;
      break;
    case Mode.FOCUS_MODE:
      body = FOCUS_MODE_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      break;
    default: {
      if (customRoutine && Object.values(ROUTINE).includes(customRoutine)) {
        if (customRoutine === ROUTINE.SUGGESTION) {
          const routines = store
            .getState()
            .setting.routineSuggestionPayload.routines.slice();
          const shouldSelectBothRoutines = routines.every(
            (routine) => routine.value
          );
          const routine = getRoutineValue(routines);
          body = shouldSelectBothRoutines
            ? ROUTINE_STEP_TO_STEP_NUMBER_MAPPING[
                index === 0 ? ROUTINE.MORNING : ROUTINE.EVENING
              ]
            : ROUTINE_STEP_TO_STEP_NUMBER_MAPPING[routine];
        } else {
          body = ROUTINE_STEP_TO_STEP_NUMBER_MAPPING[customRoutine];
        }
      } else if (
        [PLATFORMS.ANDROID, PLATFORMS.IOS, PLATFORMS.MOB].includes(platform)
      ) {
        // @Description, temporary disabled for mobile apps
        body =
          DEFAULT_STEP_TO_STEP_NUMBER_MAPPING_WITHOUT_MICRO_BREAKS[
            index.toString()
          ];
      } else {
        body = DEFAULT_STEP_TO_STEP_NUMBER_MAPPING[index.toString()];
      }
      testIds = ATTRIB.TEST.HEADERS.DEFAULT;
    }
  }
  return { body, testIds, platform };
};

export const getHabitPackActivitiesWithPackTitle = (packs: HabitPackType[]) =>
  packs
    .map((pack) => [
      ...addHabitPackTitleToActivity(
        pack.morning_activities ?? [],
        pack.pack_name
      ),
      ...addHabitPackTitleToActivity(
        pack.break_activities ?? [],
        pack.pack_name
      ),
      ...addHabitPackTitleToActivity(
        pack.evening_activities ?? [],
        pack.pack_name
      )
    ])
    .flat();

export const addHabitPackTitleToActivity = (
  activities: ActivityType[],
  pack_title: string
) => activities.map((activity) => ({ ...activity, pack_title }));

export const getMatchedActivities = (
  marketplaceActivities: ActivityTypeWithPackTitle[],
  activityLibrary: ActivityType[],
  search: string,
  userInput: string
) => {
  const updateSuggestions = [
    ...marketplaceActivities
      .filter((activity) =>
        activity.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      .sort(
        (activityA, activityB) =>
          activityA.duration_seconds - activityB.duration_seconds
      )
      .map((activity) => ({
        where: SUGGESTED_PACK.HABIT_PACK,
        name: activity.name,
        duration: activity.duration_seconds,
        pack_title: activity.pack_title ?? EMPTY_STRING
      })),
    ...activityLibrary
      .filter((activity) =>
        activity.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
      .sort(
        (activityA, activityB) =>
          activityA.duration_seconds - activityB.duration_seconds
      )
      .map((activity) => ({
        where: SUGGESTED_PACK.LIBRARY,
        name: activity.name,
        duration: activity.duration_seconds
      }))
  ];
  return updateSuggestions.length
    ? [
        {
          duration: DEFAULT_HABIT_DURATION,
          name: userInput ?? EMPTY_STRING,
          where: SUGGESTED_PACK.CREATE_NEW_HABIT
        },
        ...updateSuggestions
      ]
    : [];
};

export const formatTimeTo12Hour = (time?: string, seconds?: number) =>
  moment(time, 'HH:mm').isValid()
    ? moment(time, 'HH:mm').add(seconds, 'seconds').format('hh:mm a')
    : EMPTY_STRING;

export const getRoutineDurationAsSecond = (activities?: ActivityType[]) =>
  (activities ?? []).reduce(
    (duration, activity) => duration + activity.duration_seconds,
    0
  );
