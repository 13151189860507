import { IconProps } from 'interfaces';
import COLOR from 'constants/color';

const Search = ({ styles = 'w-5 h-5', fill = COLOR.WHITE }: IconProps) => {
  return (
    <svg
      className={styles}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      stroke={fill}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
      ></path>
    </svg>
  );
};

export default Search;
