import Goal from 'assets/icons/Goal';
import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE } from 'constants/enum';
import { t } from 'i18next';
import { FC, useContext } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { listOfSelectedGoalsSelector } from 'store/reducer/setting/selectors';
import { updateRoutineSuggestionPayload } from 'store/reducer/setting/slice';
import { RoutineGoalsContext } from '.';
import SelectedGoal from './SelectedGoal';

const ListOfSelectedGoals: FC = () => {
  const dispatch = useAppDispatch();
  const { routineData, setRoutineData } = useContext(RoutineGoalsContext);
  const { isLogTermGoalUpdating, routineSuggestionPayload } = useAppSelector(
    listOfSelectedGoalsSelector
  );

  function handleDeleted(position: number) {
    const selectedGoals = routineData.selectedGoals.filter(
      (_, index) => index !== position
    );
    setRoutineData?.((prev) => ({
      ...prev,
      selectedGoals
    }));
    dispatch(
      updateRoutineSuggestionPayload({
        ...routineSuggestionPayload,
        user_goals: [...selectedGoals]
      })
    );
  }

  return (
    <div className='min-w-full w-full flex flex-col gap-1'>
      <p className='w-full flex justify-center gap-2 text-base sm:text-lg md:text-xl py-6'>
        <span>
          <Goal />
        </span>
        {t('routine_suggestion.got_other_goals_you_want')}
      </p>
      <div className='w-full min-h-[10vh] h-fit flex items-center justify-center gap-x-10 gap-y-2.5 md:gap-y-5 flex-wrap rounded-lg p-2 sm:px-3.5 md:p-5 shadow-gray-400 shadow-inner overflow-y-auto scrollbar-thin scrollbar-thumb-sunRayFirst'>
        {routineData.selectedGoals.map((goal, index) => (
          <SelectedGoal
            key={goal}
            position={index}
            goal={goal}
            handleDeleted={handleDeleted}
          />
        ))}
        {!routineData.showAvailableGoals && (
          <ButtonFocusBear
            disabled={isLogTermGoalUpdating}
            onClick={() =>
              setRoutineData?.((prev) => ({
                ...prev,
                showAvailableGoals: true
              }))
            }
            title={t('routine_suggestion.add_another_goal')}
            size={BTN_FB_SIZE.SMALL}
            additionalStyles='my-2'
          />
        )}
      </div>
    </div>
  );
};

export default ListOfSelectedGoals;
