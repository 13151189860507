import InitEmbeddedServices from 'components/dashboard/InitEmbeddedServices';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserDetails } from 'store/reducer/user/extra';
import { getSupportSelector } from 'store/reducer/user/selectors';
import { loadMetaDataFoGetSupport } from 'utils/focusBear_apps';
import { isTrialOrPromoActive } from 'utils/subscriptions';

//Description: Ensure the webview has a minimum width of 555px

const GetSupport = () => {
  const dispatch = useAppDispatch();

  const { userInfo, subscriptionInfo, isGuestUser, isTokenAddedToInterceptor } =
    useAppSelector(getSupportSelector);

  useEffect(() => {
    loadMetaDataFoGetSupport();
  }, []);

  useEffect(() => {
    isTokenAddedToInterceptor && dispatch(getUserDetails());
  }, [isTokenAddedToInterceptor]);

  const isPaying = useMemo(
    () => isTrialOrPromoActive(subscriptionInfo) || isGuestUser,
    [subscriptionInfo]
  );

  useEffect(() => {
    if (isTokenAddedToInterceptor && userInfo) {
      const first_script = document.createElement('script');
      first_script.textContent = `
            window.$zoho=window.$zoho || {};
            $zoho.salesiq=$zoho.salesiq||{ready:function(){                                  
            $zoho.salesiq.visitor.identifier("${userInfo?.email}");
            $zoho.salesiq.visitor.name("${userInfo?.id}");
            $zoho.salesiq.visitor.customfield("isPaying", ${isPaying} ? "Yes" : "No");}}`;
      first_script.type = 'text/javascript';
      document.body.append(first_script);
      const second_script = document.createElement('script');
      second_script.src =
        'https://salesiq.zohopublic.com.au/widget?wc=8b0f55dff01a5ffbcd3e74af14a8a7a9a20a66298fb3622b6963b8abf1f00da1';
      second_script.type = 'text/javascript';
      second_script.id = 'zsiqscript';
      second_script.defer = true;
      document.body.append(second_script);

      return () => {
        document.body.removeChild(first_script);
        document.body.removeChild(second_script);
      };
    }
  }, [isTokenAddedToInterceptor]);

  useEffect(() => {
    if (isTokenAddedToInterceptor) {
      const intervalId = setInterval(() => {
        const chatWrapper = document.getElementById('zsiq_chat_wrap');
        if (chatWrapper) {
          chatWrapper.style.width = '100%';
          chatWrapper.style.setProperty('min-height', '100%', 'important');
          chatWrapper.style.setProperty('height', '100%', 'important');
          chatWrapper.style.right = '0';
          chatWrapper.style.bottom = '0';
          const toggleBtn = document.getElementById('zsiq_float');
          toggleBtn?.click();
          toggleBtn?.style.setProperty('display', 'none');
          clearInterval(intervalId);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [isTokenAddedToInterceptor]);

  return <InitEmbeddedServices />;
};

export default GetSupport;
