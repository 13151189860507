import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  updateMoreOptionActivityCutoffTime,
  updateMoreOptionPriority
} from 'store/reducer/modal/slice';
import { EMPTY_STRING, PRIORITY_OPTION } from 'constants/general';
import DropDown from 'components/common/DropDown';
import { SingleValue } from 'react-select';
import { DropDownSelectedValue } from 'interfaces';

const Priority = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { activity } = useAppSelector((state) => state.modal.moreOption);
  const priority = () => {
    if (activity?.priority === PRIORITY_OPTION.HIGH) {
      return { label: t('must_do'), value: PRIORITY_OPTION.HIGH };
    } else if (activity?.priority === PRIORITY_OPTION.STANDARD) {
      return { label: t('optional'), value: PRIORITY_OPTION.STANDARD };
    } else {
      return { label: EMPTY_STRING, value: EMPTY_STRING };
    }
  };
  const options: { label: string; value: string }[] = [
    { label: t('must_do'), value: PRIORITY_OPTION.HIGH },
    { label: t('optional'), value: PRIORITY_OPTION.STANDARD }
  ];

  return (
    <DropDown
      title={t('priority')}
      value={priority()}
      handleChange={(data) => {
        const result = data as SingleValue<DropDownSelectedValue>;
        result && dispatch(updateMoreOptionPriority(result.value));
        result?.value === PRIORITY_OPTION.STANDARD &&
          dispatch(updateMoreOptionActivityCutoffTime(undefined));
      }}
      options={options}
      containerStyles='min-w-[150px] w-full sm:w-fit'
    />
  );
};

export default React.memo(Priority);
