import ButtonFocusBear from 'components/common/buttons/ButtonFocusBear';
import { BTN_FB_SIZE, FLAG } from 'constants/enum';
import {
  MAC_APP_DATA_LOCATION,
  MAC_APP_DATA_MESSAGE,
  MOBILE_APP_EVENT,
  ROUTINE_INTRODUCTION_YOUTUBE_VIDEO
} from 'constants/general';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { enableEditHabitsSelector } from 'store/reducer/setting/selectors';
import { updateFlags } from 'store/reducer/setting/slice';
import { useWindowSize } from 'usehooks-ts';
import { getYoutubeEmbeddedURL, getYouTubePlayerHeight } from 'utils/support';
import { sendDataToPlatform } from 'utils/focusBear_apps';

const EnableEditHabits = () => {
  const dispatch = useAppDispatch();
  const { flags, platform } = useAppSelector(enableEditHabitsSelector);
  const { width } = useWindowSize();
  const height = useMemo(() => getYouTubePlayerHeight(width), [width]);

  return (
    <div className='h-full flex flex-col gap-4 items-center justify-center px-8'>
      <p className='text-3xl'>{t('get_your_brain_and_body_ready_for_focus')}</p>
      <p className='text-lg text-center px-6'>
        {t('research_indicates_that_habits_like_meditation')}
      </p>
      <iframe
        width='100%'
        height={height}
        src={getYoutubeEmbeddedURL(ROUTINE_INTRODUCTION_YOUTUBE_VIDEO)}
        title={t('how_routines_work_in_Focus_Bear')}
      />
      <ButtonFocusBear
        title={t('enable_habits')}
        size={BTN_FB_SIZE.LARGE}
        onClick={() => {
          dispatch(
            updateFlags(
              flags.filter((flag) => flag !== FLAG.DISABLE_EDIT_HABITS)
            )
          );
          sendDataToPlatform(
            platform,
            MAC_APP_DATA_MESSAGE.EDIT_HABITS_ENABLED,
            MAC_APP_DATA_LOCATION.SEND_SELECTED_HABIT_PACK_ID,
            MOBILE_APP_EVENT.EDIT_HABITS_ENABLED
          );
        }}
        additionalStyles='mt-4'
      />
    </div>
  );
};

export default EnableEditHabits;
