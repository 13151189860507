import { t } from 'i18next';
import { useAppSelector } from 'store/hooks';
import Member from './member';
import DropdownIcon from 'assets/icons/Dropdown';
import COLOR from 'constants/color';
import { useEffect, useState } from 'react';
import { SORT_OPTION } from 'constants/enum';
import { getSortedMembers, teamMembersColumns } from 'utils/support';

const MembersHeader = ({
  handleSort
}: {
  handleSort: (arg: { column: string; sortType: SORT_OPTION }) => void;
}) => {
  const [activeColumn, setActiveColumn] = useState({
    column: '',
    sortType: SORT_OPTION.DESC
  });

  useEffect(() => {
    activeColumn && handleSort(activeColumn);
  }, [activeColumn]);

  return (
    <div className='min-w-max w-full flex items-center bg-gray-500 text-white uppercase gap-2 rounded-t px-4 py-1.5 text-center text-xs sm:text-sm'>
      {teamMembersColumns.map((column, idx) =>
        idx ? (
          <button
            key={column.title}
            disabled={!column.shouldSortAllowed}
            className={`${column.styles} flex items-center justify-center gap-2 disabled:cursor-default cursor-pointer`}
            onClick={() => {
              setActiveColumn((prev) => ({
                column: column.title,
                sortType:
                  prev.sortType === SORT_OPTION.DESC
                    ? SORT_OPTION.ASC
                    : SORT_OPTION.DESC
              }));
            }}
          >
            <p>{column.title}</p>
            {column.shouldSortAllowed &&
              activeColumn.column === column.title && (
                <DropdownIcon
                  fill={COLOR.WHITE}
                  styles={`w-5 h-auto transform ${activeColumn.sortType === SORT_OPTION.ASC ? 'rotate-0' : 'rotate-180'} duration-200 animate-fadeIn`}
                />
              )}
          </button>
        ) : (
          <div key={column.title} className={column.styles}></div>
        )
      )}
    </div>
  );
};

const ListOfMembers = () => {
  const {
    team: {
      teamMembers: { members },
      areMembersLoading,
      searchMember
    }
  } = useAppSelector((state) => state);
  const [sortedMembers, setSortedMembers] = useState(members);

  useEffect(() => {
    !areMembersLoading && setSortedMembers(getSortedMembers(members ?? []));
  }, [areMembersLoading]);

  const handleSort = ({
    column,
    sortType
  }: {
    column: string;
    sortType: SORT_OPTION;
  }) => {
    setSortedMembers(getSortedMembers([...(members ?? [])], column, sortType));
  };

  return sortedMembers?.length ? (
    <div className='w-full h-fit max-h-[70vh] pb-2 flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300'>
      <MembersHeader handleSort={handleSort} />
      {sortedMembers
        ?.filter(
          (member) =>
            member.email.includes(searchMember) ||
            member.first_name.includes(searchMember) ||
            member.last_name.includes(searchMember)
        )
        ?.map((member) => <Member key={member.id} member={member} />)}
    </div>
  ) : (
    <p className='w-full text-center py-10'>
      {t('admin_team.no_members_found')}
    </p>
  );
};

export default ListOfMembers;
